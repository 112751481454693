<app-spinner [isWorking]="isEditDocumentInProgress() || isUploadInProgress"></app-spinner>
<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Edit Document' | transloco }}</h4>
        <modus-button size="25" button-style="borderless" color="primary" icon-only="close" aria-label="close"
            (buttonClick)="dialogClose()"></modus-button>
    </div>
    <div class="modal-body" cdk-scrollable>
        <form [formGroup]="formGroup">
            <div class="modal-form">
                <app-modus-select id="select-document-type" [options]="documentTypesList" size="medium"
                    [required]="true" placeholder="Document Type" [optionsDisplayProp]="'name'"
                    label="{{'Document Type'| transloco}}" [formControl]="$any(formGroup).controls['documentType']"
                    ngDefaultControl>
                </app-modus-select>

                <modus-text-input [required]="true" label="{{'Document Name'| transloco}}" size="medium"
                    placeholder="Document Name" [formControl]="$any(formGroup).controls['documentName']"
                    ngDefaultControl>
                </modus-text-input>

                <modus-text-input label="{{'Current File'| transloco}}" size="medium" [disabled]="true"
                    [formControl]="$any(formGroup).controls['currentFile']" ngDefaultControl>
                </modus-text-input>

                <input type="file" (change)="onFileInput($event)" class="file-input" #fileUpload>


                <div class="upload-file-input">
                    <div class="label">
                        <div> {{'Replacement'| transloco}} </div>
                    </div>
                    <div class="text-input">
                        <div class="inner">
                            <ccx-button width="4.87rem" size="medium" color="secondary" (click)="fileUpload.click()">{{
                                'Browse...'| transloco }}
                            </ccx-button>
                            <div class="input-text"> {{uploadedFileName || "No file uploaded yet."}} </div>
                        </div>

                    </div>
                </div>

            </div>
        </form>
        <div *ngIf="(validationErrors$ | async) as errors1">
            <ng-container *ngFor="let error of errors1">
                <modus-alert message="{{error.message}}" type="warning"></modus-alert>
            </ng-container>
        </div>
    </div>

</div>

<div class="modal-footer">
    <ccx-button size="medium" buttonStyle="outline" color="secondary" (buttonClick)="dialogClose()">{{ 'Cancel'|
        transloco }}
    </ccx-button>

    <ccx-button width="7.3125rem" size="medium" color="primary"
        [disabled]="!(formGroup.valid && formGroup.dirty && formGroup.touched) && !uploadedFileName"
        (buttonClick)="EditDocument()">
        {{ 'OK'| transloco }}
    </ccx-button>
</div>
