import {Inject, Injectable} from '@angular/core';
import {EMPTY, filter, Observable, of} from 'rxjs';
import {TIdTokenService} from './tId.token.service';
import {ActivatedRoute} from '@angular/router';
import {catchError, concatMap, map, switchMap} from 'rxjs/operators';
import {WINDOW_REF} from 'src/app/@core/shared.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {CcxTokenResponse, TIdTokenResult} from './tIdTokenResult';

@Injectable()
export class TIdService {

    constructor(private tokenService: TIdTokenService, private route: ActivatedRoute,
                @Inject(WINDOW_REF) protected window: any, private httpClient: HttpClient,
                @Inject('CONFIG') private appConfig: any) {
    }

    authenticate(params: { code: string, identityProvider: string } | null): Observable<TIdTokenResult> {
        if (params !== null) {
            return this.getAuthTokenResult(params.code)
                .pipe(
                    switchMap(tokenResponse => this.tokenService.storeToken(tokenResponse)),
                    catchError(err => {
                        return of(new TIdTokenResult(false, null, [err.message]));
                    })
                );
        } else {
            this.window.location.href = this.buildAuthorizeUrl();
            return EMPTY;
        }
    }

    buildAuthorizeUrl() {
        const queryParams: any = {
            scope: this.appConfig.tidScope,
            response_type: this.appConfig.tidResponseType,
            client_id: this.appConfig.tidClientId,
            redirect_uri: this.appConfig.tidRedirectUrl
        }

        const urlEncodedParams =
            Object.keys(queryParams)
                .map(e => `${encodeURIComponent(e)}=${encodeURIComponent(queryParams[e])}`)
                .join('&');

        return `${this.appConfig.tidAuthorizeUrl}?${urlEncodedParams}`;
    }

    logOut() {
        const tokenJson = this.tokenService.getCcxToken()?.tokens?.tid_TokenId ?? '';
        this.tokenService.removeToken();
        this.window.location.href = this.buildLogOutUrl(tokenJson);
    }

    removeToken() {
        this.tokenService.removeToken();
    }

    buildLogOutUrl(tokenJson: string) {
        const queryParams: any = {
            id_token_hint: tokenJson,
            post_logout_redirect_uri: this.appConfig.tidLogoutRedirectUrl,
        }

        const urlEncodedParams =
            Object.keys(queryParams)
                .map(e => `${encodeURIComponent(e)}=${encodeURIComponent(queryParams[e])}`)
                .join('&');

        return `${this.appConfig.tidLogoutUrl}?${urlEncodedParams}`;
    }

    getAuthTokenResult(code: string): Observable<CcxTokenResponse> {
        const headers = new HttpHeaders({'content-type': 'application/json'});
        return this.httpClient.post<CcxTokenResponse>(this.appConfig.ccxAuthUrl, {code}, {headers});
    }

    tokenStream(): Observable<TIdTokenResult | null> {
        return this.tokenService.token$
            .pipe(filter(e => !!e));
    }


    /**
     * Checks if the current user has any of the roles specified, or if it has any role
     * @param {string[]} roleNames an array of role names to check, if the array is empty, will only check if user has any role,
     * this is a scenario where user is new
     * @return {Observable<boolean>} a boolean observable
     */
    hasRole(roleNames: string[]): Observable<boolean> {
        return this.tokenService.token$.pipe(
            concatMap(t => {
                if (t !== null && t.isValid() && t.isActive()) {
                    const rolesAreArray = Array.isArray(t?.roles);
                    if (roleNames.length === 0) {
                        return of(rolesAreArray ? t?.roles.length > 0 : t?.roles != undefined);
                    }

                    if (rolesAreArray) {
                        if (t?.roles?.some(item => roleNames.includes(item))) {
                            return of(true);
                        }
                    } else {
                        if (roleNames.includes(t?.roles)) {
                            return of(true);
                        }
                    }
                    return of(false);
                } else {
                    return of(false);
                }
            })
        )
    }

    isActive(roleNames: string[]): Observable<boolean> {
        return this.tokenService.token$.pipe(
            concatMap(t => {
                if (t !== null && t.isValid() && t.isActive()) {
                    return of(true);
                } else {
                    return of(false);
                }
            })
        )
    }

    refreshToken(): Observable<TIdTokenResult> {
        var tokenJson = this.tokenService.getCcxToken()?.tokens?.refresh_Token ?? '';
        return this.getRefreshTokenResult(tokenJson)
            .pipe(
                switchMap(tokenResponse => this.tokenService.storeToken(tokenResponse)),
                catchError(err => {
                    return of(new TIdTokenResult(false, null, [err.message]));
                })
            );
    }

    getRefreshTokenResult(refresh_token: string): Observable<CcxTokenResponse> {
        const headers = new HttpHeaders({'content-type': 'application/json'});
        return this.httpClient.post<CcxTokenResponse>(this.appConfig.ccxAuthUrl, {refresh_token}, {headers});
    }

    isAuthenticated(): Observable<boolean> {
        return this.tokenService.token$.pipe(
            switchMap(t => {
                if (t === null) {
                    return of(false);
                } else {
                    if (!t.isValid()) {
                        return this.refreshToken().pipe(
                            map(t => true),
                            catchError(e => of(false))
                        )
                    }
                    return of(true);
                }
            })
        )
    }
}


