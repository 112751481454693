import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
    selector: 'ccx-grid-kebab-menu-renderer',
    template: `
        <div *ngIf="!params.data.hideMenu" class="kebab__container">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                    d="M7.05992 7.06008C6.53992 7.58008 6.53992 8.42675 7.05992 8.94675C7.57992 9.46675 8.42659 9.46675 8.94659 8.94675C9.46659 8.42675 9.46659 7.58008 8.94659 7.06008C8.42659 6.54008 7.57992 6.54008 7.05992 7.06008ZM7.05992 2.39342C6.53992 2.91342 6.53992 3.76008 7.05992 4.28008C7.57992 4.80008 8.42659 4.80008 8.94659 4.28008C9.46659 3.76008 9.46659 2.91342 8.94659 2.39342C8.42659 1.87342 7.57992 1.87342 7.05992 2.39342ZM7.05992 11.7268C6.53992 12.2468 6.53992 13.0934 7.05992 13.6134C7.57992 14.1334 8.42659 14.1334 8.94659 13.6134C9.46659 13.0934 9.46659 12.2468 8.94659 11.7268C8.42659 11.2068 7.57992 11.2068 7.05992 11.7268Z"
                    fill="#252A2E"/>
            </svg>
        </div>
    `,
    styles: [`
        .kebab__container {
            cursor: pointer;
            width: 100%;
        }
    `]
})
export class KebabMenuCellRendererComponent implements ICellRendererAngularComp {
    params!: any;

    agInit(params: ICellRendererParams<any, any, any>): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams<any, any, any>): boolean {
        return false;
    }

}
