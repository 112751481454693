import { Component, EventEmitter, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Store } from '@ngrx/store';
import { coefficientActions, coefficientEditorSelectors, State } from './+state';
import { takeUntil } from 'rxjs';
import { AbstractControl, FormArray, FormControl, Validators } from '@angular/forms';
import { CellEditRequestParameter } from '../../../@core/components/ccx-table/ccx-table.component';

import {
    CcxTableButtonCellRendererComponent
} from '../../../@core/components/ccx-table/ccx-table-button-cell-renderer.component';

@Component({
    selector: 'ccx-coefficients-editor',
    templateUrl: './coefficients-editor-shell.component.html',
    styleUrls: ['./coefficients-editor-shell.component.scss']
})
export class CoefficientsEditorShellComponent implements OnInit, OnDestroy {
    private onDestroy$ = new EventEmitter<void>;
    private readonly langSvc = inject(TranslocoService);
    private readonly dialogRef = inject(DialogRef<void>);
    private store = inject(Store<State>);
    coefficientsFormArray = new FormArray<any>([], [this.validateCoefficientsForm]);
    isWorking = this.store.selectSignal(coefficientEditorSelectors.selectIsWorking);
    factorKeyColumns: { name: string, control: FormControl }[] = [
        {
            name: 'Year', control: new FormControl<number | null>(null,
                [Validators.required, Validators.pattern('^[0-9]*$')])
        }
    ]
    coefficientFormGroupDef = this.store.selectSignal(
        coefficientEditorSelectors.selectCoefficientFactorForm(this.factorKeyColumns));
    colDefs = this.store.selectSignal(
        coefficientEditorSelectors.selectCoefficientColumnDefs(this.factorKeyColumns, [
            {
                headerName: '',
                cellRenderer: CcxTableButtonCellRendererComponent,
                width: 80,
                pinned: 'right',
                cellRendererParams: {
                    onClick: this.onUserDeletedButtonClick.bind(this),
                    icon: 'trash',
                    disabled: false,
                }
            }
        ]));
    coefficients = this.store.selectSignal(coefficientEditorSelectors.selectCoefficients);
    noRowsOverlayComponentParams = {
        message: this.langSvc.translate('No coefficients defined'),
    };

    constructor(@Inject(DIALOG_DATA) private dialogParameters: { protocolId: string, organizationId: string }) {
        this.dialogRef.disableClose = true;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    ngOnInit(): void {
        this.store.select(coefficientEditorSelectors.selectIsDialogOpen).pipe(takeUntil(this.onDestroy$))
            .subscribe(isOpen => {
                if (isOpen) {
                    this.store.dispatch(coefficientActions.participantOrganizationCoefficientsLoad({
                        protocolId: this.dialogParameters.protocolId,
                        organizationId: this.dialogParameters.organizationId,
                        keys: this.factorKeyColumns
                    }));
                } else {
                    this.store.dispatch(coefficientActions.clearValues());
                    this.dialogRef.close();
                }
            });
    }

    dialogClose() {
        this.store.dispatch(coefficientActions.coefficientEditorDialogToggle({
            value: false,
            organizationId: null,
            protocolId: null
        }));
    }

    saveCoefficients() {
        this.store.dispatch(coefficientActions.saveCoefficientsLoad());
    }

    addCoefficient() {
        this.store.dispatch(coefficientActions.putCoefficient({ coefficient: this.coefficientFormGroupDef()?.value }));
    }

    cellEditRequest($event: CellEditRequestParameter<any>) {
        const colId = $event.cellEditRequestEvent.column.getColId();
        this.store.dispatch(coefficientActions.putCoefficient({ coefficient: $event.cellParentForm?.value }))
    }

    validateCoefficientsForm(control: AbstractControl) {
        const formArray = control as FormArray;
        const ids = formArray.value.map((x: any) => x.Year);
        const uniqueIds = [...new Set(ids)];
        const uniqueValues = uniqueIds.length;


        if (uniqueValues !== ids.length) {
            return { duplicateIds: true };
        }
        return null;
    }

    private onUserDeletedButtonClick(event: MouseEvent, params: any): void {
        this.store.dispatch(coefficientActions.deleteCoefficient({ id: params.data.id }))
    }
}
