import {
    AfterViewInit,
    Component,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface CustomCellRendererParams extends ICellRendererParams {
    rowActions: any[];
    disableIf: (value: string, id: string) => boolean;

}


@Component({
    selector: 'editor-cell',
    template: `
    <ng-container *ngIf="!params.data.hideMenu">
    <modus-list *ngFor="let row of params.rowActions" class="dots-items">

            <modus-list-item *ngIf="params.disableIf ; else nodisabled"
            [disabled]="params.disableIf(params.data.status, row._id)" 
            size="condensed"
            (itemClick)="rowAction($event, row._id)">{{ row.display.text }}
            </modus-list-item>

            <ng-template #nodisabled>
                <modus-list-item size="condensed" 
                (itemClick)="rowAction($event, row._id)">{{ row.display.text }}
                </modus-list-item>
            </ng-template>
        </modus-list>
    </ng-container>
    `,
    styles: [
        `

        `,
    ],
})
export class dotsMenuCellEditor implements ICellEditorAngularComp, AfterViewInit {


    @ViewChild('container', { read: ViewContainerRef })
    public container!: ViewContainerRef;
    public params!: CustomCellRendererParams;


    ngAfterViewInit() {

    }

    agInit(params: any): void {
        this.params = params;
    }

    getValue(): any {
    }

    isPopup(): boolean {
        return true;
    }

    onClick(happy: boolean) {
        this.params.api.stopEditing();
    }

    rowAction(event: any, action: string): void {

        this.params.context.componentParent.rowActionClick(
            this.params.node, action);

        this.params.api.stopEditing();
    }
}
