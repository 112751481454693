import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-modus-input',
    template: `
        <modus-text-input
            [label]="label"
            [placeholder]="placeholder"
            [formControlName]="formControlName"
            [formControl]="formControl"></modus-text-input>`,
    styles: [``]
})
export class ModusInputComponent {
    // @Input() disabled!: boolean;
    @Input() label!: string;
    @Input() size: 'small' | 'medium' | 'large' = 'medium';
    @Input() placeholder!: string;
    @Input() formControlName!: string;
    @Input() formControl!: FormControl
}
