import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    @Output() onCloseFilter = new EventEmitter();
    @Input() showFilter = false;
    @Output() onResetFilter = new EventEmitter<void>();
    @Output() onApplyFilter = new EventEmitter<void>();

    filterData() {
        this.onApplyFilter.emit();
    }

    resetFilter() {
        this.onResetFilter.emit();
    }


    changeFilter() {

        this.onCloseFilter.emit();
    }

}
