import {createFeatureSelector, createSelector} from "@ngrx/store";
import {accessRequestState} from "./accessRequest.reducer";

export const selectAccessRequestState = createFeatureSelector<accessRequestState>('accessRequest');


export const selectOrgList = createSelector(
    selectAccessRequestState,
    (accessRequest) => accessRequest.orgListCB
);


export const selecAccessRequestList = createSelector(
    selectAccessRequestState,
    (accessRequest) => accessRequest.accessRequestList
);


export const selectIsworkinig = createSelector(
    selectAccessRequestState,
    (accessRequest) => accessRequest.isWorking
);

