import {Component, OnInit, Input} from '@angular/core';
import {dummyCounter} from 'src/app/modules/dashboard/dummyCounter';
import {Router} from '@angular/router';

@Component({
    selector: 'app-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    @Input() counterInfo!: dummyCounter;

    onClickCounter(option: dummyCounter): void {

        const data = {
            key: option.moduleKey
        }
        this.router.navigate([option.route], {queryParams: data});
    }
}
