import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModusAngularComponentsModule } from '@trimble-oss/modus-angular-components';
import { SharedModule } from '../../@core/shared.module';

@Component({
  selector: 'ccx-grid-filter-panel',
  styles: [`
      .ccx-grid-filter-button {
        display: flex;
        justify-content: space-between;
        margin-top: 0.88em;
      }
      .button-svg{
        margin-right: 11px;
      }
    `],
  template: `
        <div class="ccx-grid-filter-button">
            <modus-text-input #searchTextInput
                              [placeholder]="placeholder | transloco"
                              include-search-icon (valueChange)="gridSearchBy($any($event))"></modus-text-input>
            <ccx-button
            *ngIf="!isFilterHidden"
            width="6.25rem"
            height="2rem"
                    size="medium"
                    color="secondary"
                    buttonStyle="outline"
                    [disabled]="isDisabled"
                    (click)="toggleFilter()">
                <svg-icon [class]="'button-svg-icon'" name="filter"></svg-icon>
                <span >{{'Filter' | transloco}}</span>
                <span *ngIf="counter>0" >{{ ' ('+counter+')'}}</span>
            </ccx-button>
        </div>`
})
export class CcxGridFilterPanelComponent {
  @Output() onGridSearchBy = new EventEmitter<CustomEvent<string>>;
  @Output() onToggleFilter = new EventEmitter;
  @Input() placeholder: string = 'Search';
  @Input() isDisabled: boolean = false;
  @Input() isFilterHidden: boolean = false;
  @Input() counter: number = 0;

  toggleFilter() {
    if (this.isDisabled) {
      return;
    }
    this.onToggleFilter.emit();
  }

  gridSearchBy($event: CustomEvent<string>) {
    this.onGridSearchBy.emit($event);
  }
}
