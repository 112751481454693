<app-side-panel [show]="showFilter">
    <div class="filter-header">
        <p>{{ 'Filter'| transloco }}</p>
        <svg-icon class="svg-div" svgClass="svg-x" name="small-x" (buttonClick)="changeFilter()"></svg-icon>

    </div>
    <div class="filter-body">
        <ng-content></ng-content>
    </div>
    <div class="filter-footer">
        <ccx-button buttonStyle="outline" color="secondary" (buttonClick)="filterData()">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'Apply Filters'| transloco }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </ccx-button>
        <ccx-button buttonStyle="outline" color="secondary" (buttonClick)="resetFilter()">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'Reset Filters'| transloco }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </ccx-button>
    </div>
</app-side-panel>
