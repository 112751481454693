import {Component} from '@angular/core';

@Component({
    selector: 'app-no-content-available',
    templateUrl: './no-content-available.component.html',
    styleUrls: ['./no-content-available.component.scss']
})
export class NoContentAvailableComponent {
    constructor() {
        console.log('NoContentAvailableComponent');
    }
}
