import {Component, Injectable, Type} from '@angular/core';
import {Overlay} from '@angular/cdk/overlay';
import {CdkDialogContainer, Dialog, DialogRef} from '@angular/cdk/dialog';
import {Observable} from 'rxjs';
import {ComponentPortal} from '@angular/cdk/portal';

@Injectable()
export class DialogService {
    constructor(protected overlay: Overlay, protected dialog: Dialog) {
    }

    create<TComponent, TParam, TData>(content: Type<TComponent>, data: TParam): DialogRef<TData> {
        return this.dialog.open<TData>(content, {
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true,
            backdropClass: 'ccx-dialog-overlay__backdrop',
            panelClass: 'ccx-dialog-overlay__panel',
            container: CcxDialogContainer,
            data
        });
    }
}


@Component({
    selector: 'ccx-dialog-container',
    styles: [`
    `],
    template: `
        <ng-template cdkPortalOutlet></ng-template>
    `
})
export class CcxDialogContainer extends CdkDialogContainer {
}
