
import * as d from './components';

export const DIRECTIVES = [
  d.ModusAccordion,
  d.ModusAccordionItem,
  d.ModusActionBar,
  d.ModusAlert,
  d.ModusAutocomplete,
  d.ModusBadge,
  d.ModusBreadcrumb,
  d.ModusButton,
  d.ModusCard,
  d.ModusCheckbox,
  d.ModusChip,
  d.ModusDataTable,
  d.ModusDateInput,
  d.ModusDatePicker,
  d.ModusDropdown,
  d.ModusFileDropzone,
  d.ModusIcon,
  d.ModusList,
  d.ModusListItem,
  d.ModusMessage,
  d.ModusModal,
  d.ModusNavbar,
  d.ModusNavbarAppsMenu,
  d.ModusNavbarButtonMenu,
  d.ModusNavbarMainMenu,
  d.ModusNavbarNotificationsMenu,
  d.ModusNavbarProfileMenu,
  d.ModusNavbarSearchOverlay,
  d.ModusNumberInput,
  d.ModusPagination,
  d.ModusProgressBar,
  d.ModusRadioGroup,
  d.ModusSelect,
  d.ModusSentimentScale,
  d.ModusSideNavigation,
  d.ModusSideNavigationItem,
  d.ModusSlider,
  d.ModusSpinner,
  d.ModusSwitch,
  d.ModusTable,
  d.ModusTableCellEditor,
  d.ModusTableCellMain,
  d.ModusTableColumnsVisibility,
  d.ModusTableDropdownMenu,
  d.ModusTableFillerColumn,
  d.ModusTableRowActions,
  d.ModusTableRowActionsCell,
  d.ModusTableRowActionsMenu,
  d.ModusTableToolbar,
  d.ModusTabs,
  d.ModusTextInput,
  d.ModusTimePicker,
  d.ModusToast,
  d.ModusTooltip,
  d.ModusTreeView,
  d.ModusTreeViewItem
];
