import {Component, ElementRef, EventEmitter, Host, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {
    CellEditingStartedEvent,
    CellEditingStoppedEvent,
    CellEditRequestEvent, CellKeyDownEvent, CellPosition,
    ColDef, FullWidthCellKeyDownEvent,
    GetRowIdFunc,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ModelUpdatedEvent,
    RowDataUpdatedEvent
} from 'ag-grid-community';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {CcxTableNoRowsOverlayComponent} from './ccx-table-cell-renderer.component';
import {TranslocoService} from '@jsverse/transloco';
import {formFunctions} from '../../functions';
import {CdkScrollable} from '@angular/cdk/overlay';

@Component({
    selector: 'ccx-table',
    template: `
        <div class="grid__container">
            <ag-grid-angular
                [headerHeight]="headerHeight"
                class="ag-theme-alpine"
                domLayout="autoHeight"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [suppressColumnVirtualisation]="true"
                [suppressRowVirtualisation]="true"
                (gridReady)="onGridReady($event)"
                [getRowId]="getRowId"
                [gridOptions]="gridOptions"
                (cellEditRequest)="onCellEditRequest($event)"
                (cellEditingStarted)="onCellEditingStarted($event)"
                (rowDataUpdated)="onRowDataUpdated($event)"
                (modelUpdated)="onModelUpdated($event)"
                (cellEditingStopped)="cellEditingStopped($event)"
                [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"

                >
            </ag-grid-angular>
        </div>
    `,
    styles: [`
    `]
})
export class CcxTableComponent<
    TFormGroup extends { [K in keyof TFormGroup]: AbstractControl<any, any> },
    TData = any,
    TColDef extends ColDef<TData> = ColDef<any>> implements OnInit {

    @Input() formArray: FormArray = new FormArray<any>([]);

    @Input() noRowsOverlayComponentParams: { message: string; } | undefined;
    @Input() columnDefs: ColDef<TData>[] | null | undefined;
    @Input() rowData: TData[] | null | undefined;
    @Input() headerHeight: number | undefined;
    @Input() defaultColDef: ColDef<TData> | undefined;
    @Input() getRowId: GetRowIdFunc<TData> | undefined;
    @Output() onCellEditRequestEvent = new EventEmitter<CellEditRequestParameter<TData>>();
    private gridApi!: GridApi<TData>;
    @Input() formGroupDef!: FormGroup;
    private lastEditedCell: CellPosition | null = null;
    private suppressReEnterCellEdit: boolean = false;
    gridOptions: GridOptions<TData> | undefined;

    constructor(private langSvc: TranslocoService,
                @Optional() @Host() scrollContainer: CdkScrollable) {

        this.gridOptions = {
            singleClickEdit: true,
            readOnlyEdit: true,
            animateRows: false,
            stopEditingWhenCellsLoseFocus: false,
            context: {
                componentParent: this,
                parentElementRef: scrollContainer
            },
            noRowsOverlayComponent: CcxTableNoRowsOverlayComponent,
        };
    }

    onGridReady($event: GridReadyEvent<TData>) {
        this.gridApi = $event.api;
        this.populateFormArray(this.gridApi);
        this.gridApi.sizeColumnsToFit();
    }

    populateFormArray(gridApi: GridApi<TData>) {
        const hasAnyEditableColumn = (gridApi.getColumnDefs()
            ?.filter(x => (<ColDef>x).editable) || []).length > 0;
        if (!hasAnyEditableColumn) {
            return;
        }
        const formArray = this.formArray;
        formArray.clear();
        gridApi.forEachNode((node, index) => {
            if (node.data) {
                const clonedControl = formFunctions.cloneAbstractControl(this.formGroupDef);
                clonedControl.patchValue(node.data);
                formArray.setControl(index, clonedControl);
            }
        });
    }

    onCellEditRequest($event: CellEditRequestEvent<TData>) {
        const control = getFormArrayItemFromCellNode(this.formArray, $event.node.rowIndex || 0,
            $event.column.getColId());
        control.setValue($event.newValue);
        this.onCellEditRequestEvent.emit({cellEditRequestEvent: $event, cellParentForm: control.parent});
    }

    onRowDataUpdated($event: RowDataUpdatedEvent<TData>) {
        this.regainFocusAfterRowDataUpdated($event);
    }

    regainFocusAfterRowDataUpdated($event: RowDataUpdatedEvent<TData>) {
        let focusedCell = $event.api.getFocusedCell();
        const focusedCellEqualsToLastFocusedCell = focusedCell === this.lastEditedCell;
        if (focusedCellEqualsToLastFocusedCell) {
            return;
        }

        if (focusedCell?.rowIndex !== undefined && focusedCell?.column.getColId() !== undefined
            && !this.suppressReEnterCellEdit) {
            $event.api.setFocusedCell(focusedCell?.rowIndex, focusedCell?.column.getColId(), focusedCell?.rowPinned);
            $event.api.startEditingCell({
                rowIndex: focusedCell?.rowIndex,
                colKey: focusedCell?.column.getColId(),
            });
        }
        this.suppressReEnterCellEdit = false;
    }

    onModelUpdated($event: ModelUpdatedEvent<TData>) {
        this.populateFormArray($event.api);
    }

    cellEditingStopped($event: CellEditingStoppedEvent<TData>) {
        const isCellEditCancelled = $event.newValue === undefined;
        if (isCellEditCancelled) {
            const control = getFormArrayItemFromCellNode(this.formArray,
                $event.node.rowIndex || 0, $event.column.getColId());
            control.setValue($event.oldValue);
        }
        this.formArray.markAsPristine();
    }

    ngOnInit(): void {
        if (!this.noRowsOverlayComponentParams) {
            this.noRowsOverlayComponentParams = {
                message: this.langSvc.translate('No data to display'),
            }
        }
    }

    onCellEditingStarted($event: CellEditingStartedEvent<TData>) {
        this.lastEditedCell = $event.api.getFocusedCell();
    }
}


export function getFormArrayItemFromCellNode(
    formArray: FormArray, rowIndex: number, colId: string): FormControl<any> {
    const formGroup = formArray.controls[rowIndex] as FormGroup;
    if (!formGroup || formGroup.controls[colId] === undefined) {
        console.error(`Error: formGroup.controls[${colId}] is undefined`);
    }
    return formGroup.controls[colId] as FormControl;
}

export type CellEditRequestParameter<TData> = {
    cellEditRequestEvent: CellEditRequestEvent<TData>,
    cellParentForm: FormArray<any> | FormGroup<any> | null
};
