<modus-navbar id="navbar" [logoOptions]="productLogoOptions" [profileMenuOptions]="profileMenuOptions"
    [showNotifications]="false" [apps]="(cmsUrl$ | async)" [showAppsMenu]="(canShowCms() | async)" reversed
    show-help show-main-menu show-notifications (mainMenuClick)="openMenu()" (profileMenuSignOutClick)="singOut()">
    >


    <div slot="profileMenu">
        <label for="languageSelection">{{ 'Language' | transloco }}:</label>
        <select name="languageSelection" id="languageSelection" [(ngModel)]="currentLanguage" #languageSelection>
            <option *ngFor="let l of languages">
                {{ l.display }}
            </option>
        </select>
    </div>

    <div slot="notifications" class="notifications-size">
        <app-notifications [AllUsersNotification]="AllUsersNotification"></app-notifications>

    </div>
    <img src="assets/umbraco_logo_blue.svg" alt="">

</modus-navbar>
<div id="sideNav" target-content="#defaultTemplate #panelcontent">
</div>
