import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
    selector: 'button-cell',
    template: `
        <ccx-button size="small" buttonStyle="borderless"
                    (buttonClick)="invokeRemoveButtonParent()">
            <svg-icon svgClass="" name="trash"></svg-icon>
        </ccx-button>
    `,
    styles: [
        `
            .btn {
                line-height: 0.5;
            }
        `,
    ],
})
export class deleteButtonCellEditor implements ICellRendererAngularComp {
    public params!: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    public invokeRemoveButtonParent() {
        this.params.context.componentParent.removeButtonParent(
            this.params.node.rowIndex);
    }

    refresh(): boolean {
        return false;
    }
}
