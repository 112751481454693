import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'app-html-content',
    styleUrls: ['html-content.component.scss'],
    templateUrl: 'html-content.component.html'
})
export class HtmlContentComponent implements OnInit {
    @Input() content!: string;
    safeHtml: SafeHtml | undefined;

    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.content);
    }
}
