import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: '[clickedInsideElement]'
})
export class ClickedInsideElementDirective {
    constructor(private _elementRef: ElementRef) {
    }

    @Input() clickableElement!: HTMLElement;
    @Output()
    public clickedInsideElement = new EventEmitter<MouseEvent>();

    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement || !this.clickableElement) {
            return;
        }
        const clickedOnClickableElement = this.clickableElement.contains(targetElement);
        const clickedInsideComponent = this._elementRef.nativeElement.contains(targetElement);
        if (clickedOnClickableElement && !clickedInsideComponent) {
            this.clickedInsideElement.emit(event);
        }
    }
}
