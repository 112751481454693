import { Component, OnInit, Input } from '@angular/core';
import { dummyCounter } from 'src/app/modules/dashboard/dummyCounter';
import { Router } from '@angular/router';

@Component({
    selector: 'app-counter-total',
    templateUrl: './counter-total.component.html',
    styleUrls: ['./counter-total.component.scss']
})
export class CounterTotalComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    @Input() counterInfo!: counterData;
    @Input() counterSizeClass: string = 'bdr-5';

    onClickCounter(option: any): void {

        const data = {
            key: option.moduleKey
        }
        this.router.navigate([option.route], { queryParams: data });
    }
}

export interface counterData {
    id: number;
    count: number;
    name: string;
    disableFormat?: boolean
}