import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {count} from 'rxjs';
import {columns, data, displayOptions, pagination, rowActions, selectionOptions, sortOptions} from './DataTableOptions';
import {TranslocoService} from '@jsverse/transloco';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {


    search = "";
    auxData!: data[];
    rowId = 0;
    searchValue = "";
    maxPage = 0;
    minPage = "1";
    activePage = 1;
    searchedData!: data[];

    showModal: boolean = false;

    selectedAction!: rowActions;

    // modaloptions = {
    //   showModal: false,
    //   action: "",
    //   secondbuttontext: "Cancel",
    //   primarybuttontext: "Save Changes",
    //   ModalHeaderText: "",
    //   ModalBodyText: ""
    // }


    @ViewChild('myModal') modal!: ElementRef<HTMLModusModalElement>;
    @Input() hasSearchTest!: boolean;
    @Input() hasFilter!: boolean;
    @Input() rowActionsEnabled!: boolean | null;
    @Input() rowActions!: rowActions[];
    @Input() hascheckboxSelection!: boolean;
    @Input() pagination!: pagination;
    @Input() numberPages!: boolean;
    @Input() columns!: columns[];
    @Input() data!: data[];
    @Input() displayOptions!: displayOptions;
    @Input() selectionOptions !: selectionOptions;
    @Input() sortOptions!: sortOptions;
    @Input() moduleName!: string;
    @Input() filterBy!: string;


    constructor(private trlocoServ: TranslocoService) {
    }

    ngOnInit(): void {
        this.auxData = this.data;
        this.data = this.data;
        this.searchValue = "";

        if (this.pagination == null) {

            this.pagination = {
                hasPagination: false,
                size: 'medium',
                minPage: 1,
                sizeTable: 10,
                currentPage: 1
            }
        }


        if (!this.rowActionsEnabled)
            this.rowActions = [];

        //#region Translation
        this.columns.map(c => c.display = this.trlocoServ.translate(c.display));
        this.data.map(d => d.Action ? d.Action.display = this.trlocoServ.translate(d.Action.display) : {});
        this.rowActions?.map(r => r.display.text = this.trlocoServ.translate(r.display.text))
        //#endregion


        this.performePagination(1);
    }

    /* Events: MODUS: Data Table   */

    cellClick(e: Event): void {
        let rowId = (e as CustomEvent).detail._id;
        // this.modaloptions.action = "Accept ";
        this.searchNameByRow(rowId);
        this.onCellClick.emit((e as CustomEvent));
    }

    selectedCheckbox(e: Event): void {
    }

    rowActionClick(e: Event): void {
        this.rowId = (e as CustomEvent).detail.rowId;


        this.selectedAction = this.rowActions.find(a => a._id == (e as CustomEvent).detail.actionId)
            ?? {
                _id: "",
                t_display: "",
                display: {text: "",},
                actionInfo: ""
            }
        // switch ((e as CustomEvent).detail.actionId) {
        //   case "delete":
        //     this.modaloptions.action = "Delete";
        //     break;
        //   case "edit":
        //     this.modaloptions.action = "Edit";
        //     break;
        //   default:
        //     this.modaloptions.action = "Other Option";
        //     break;
        // }

        this.searchNameByRow(this.rowId);
        this.showModal = true;
    }


    actionSelected(option: string): void {


        if (option == "secondbuttontext") {
            console.log("canceled Action");

        }
        if (option == "primarybuttontext") {
            console.log("was accept");
        }
        this.showModal = false;
    }


    getColumnNames(): string[] {
        let columnNames: string[];
        columnNames = this.columns.map(element => {
            return element.display;
        });
        return columnNames;
    }

    // search by row: "_id", data elements always needs to have the " _id " property

    searchNameByRow(rowId?: number): void {
        const id = "_id";
        let userInformation = "";
        let columns = this.getColumnNames();
        this.data.filter((p) => {
            if (p[id] === rowId) {
                columns.map(element => {
                    if (typeof p[element] === 'string') {
                        userInformation = userInformation + " " + element + " " + p[element] + ", ";
                        return
                    }
                });
            }
        });

        // this.modaloptions.ModalHeaderText = this.modaloptions.action + " in " + this.moduleName + " Module";
        // this.modaloptions.ModalBodyText = "Did you want to " + this.modaloptions.action + " " + userInformation;
        // this.modaloptions.showModal = true;
    }

    // search by any column if property is a string
    searchField(value: string) {
        let newData: data[] = [];
        let columns = this.getColumnNames();

        this.auxData.filter((p) => {
            let obj = {
                _id: p._id,
                _selected: p._selected
            };
            columns.map(element => {
                if (typeof p[element] === 'string') {
                    if (p[element].toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
                        obj = p;
                    } else {
                        return
                    }
                }
                return obj;
            });
            if (Object.keys(obj).length !== 0)
                newData.push(obj);
        });

        this.data = newData.length > 0 ? newData : this.auxData;
        this.searchedData = newData;
        this.searchValue = value;
        this.performePagination(1, true);
    }

    //  Events: data-table-component
    onKeydown(event: any) {
        this.searchField(event.target.value);
    }

    onBlur(value: string) {
        this.searchField(value);
    }

    ChangePagination(e: Event): void {
        let page = (e as CustomEvent).detail;
        console.log(page);
        this.performePagination(page)
    }

    performePagination(page: number, isFromSearchInput: boolean = false): void {

        let offset = (page - 1) * this.pagination.sizeTable;
        this.pagination.currentPage = page;
        this.activePage = page;
        this.data = this.searchValue !== "" ? this.searchedData.slice(offset).slice(0, this.pagination.sizeTable) : this.auxData.slice(offset).slice(0, this.pagination.sizeTable);
        let maxPage = this.searchValue !== "" ? Math.ceil(this.searchedData.length / this.pagination.sizeTable) : Math.ceil(this.auxData.length / this.pagination.sizeTable);

        this.maxPage = maxPage;
        this.minPage = "1";
        this.pagination.hasPagination = this.maxPage > 1 ? true : false;

    }


    @Output() onClickFilter = new EventEmitter();
    @Output() onCellClick = new EventEmitter<any>;

    showFilter() {

        console.log("Emit")
        this.onClickFilter.emit();
    }
}
