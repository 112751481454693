import {
    AfterViewInit,
    Component,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
    selector: 'alphanumeric-cell',
    styleUrls: ['../cell-styles.scss'],
    template: `
        <form>

            <input type="text" id="inputvalue" name="inputvalue"
                   #input
                   [class]="class"
                   (keydown)="onKeyDown($event)"
                   (input)="onInput($event)"
                   [(ngModel)]="value"
            />
            <label *ngIf="required" for="inputvalue">This Is A Required Field </label>

        </form>
    `

    ,
})
export class AlphanumericCellEditor
    implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value!: string;
    private cancelBeforeStart = false;
    public class: string = 'simple-input-editor';
    public required: boolean = false;

    @ViewChild('input', {read: ViewContainerRef})
    public input!: ViewContainerRef;

    agInit(params: ICellEditorParams): void {
        this.params = params;
        this.setInitialState(this.params);

        // only start edit if key pressed is a number, not a letter
        // this.cancelBeforeStart = !!(
        //   params.eventKey?.length === 1 && '1234567890'.indexOf(params.eventKey) < 0
        // );
    }

    setInitialState(params: ICellEditorParams) {
        let startValue;

        const eventKey = params.eventKey;

        if (eventKey === KEY_BACKSPACE) {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (eventKey && eventKey.length === 1) {
            // if a letter was pressed, we start with the letter
            startValue = eventKey;
        } else {
            // otherwise we start with the current value
            startValue = params.value;
        }

        this.value = startValue;
    }

    getValue(): any {
        return this.value;
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.


    onKeyDown(event: any): void {
        if (event.key === 'Escape') {
            return;
        }
        if (this.isLeftOrRight(event) || this.isBackspace(event)) {
            event.stopPropagation();
            return;
        }


    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        window.setTimeout(() => {
            this.input.element.nativeElement.focus();
        });
    }


    private isBackspace(event: any) {
        return event.key === KEY_BACKSPACE;
    }

    private isLeftOrRight(event: any) {
        return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
    }


    onInput(event: any): void {
        if (this.value.toString() === "") {
            this.required = true;
            this.class = "simple-input-editor-error";
        } else {
            this.required = false;
            this.class = "simple-input-editor";
        }
    }
}
