import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {ColumnsConfiguration, DataSourceEdit} from './model/data-source-editing';
import {map, takeUntil} from 'rxjs';


@Component({
    selector: 'ccx-grid-editing',
    styleUrls: ['./ccx-grid-editing.component.scss'],
    templateUrl: './ccx-grid-editing.component.html',
})


export class CcxGridEditingComponent implements OnInit, OnDestroy {
    constructor(
        private fb: FormBuilder,
    ) {
    }

    private onDestroy$ = new Subject<void>();
    //variables
    tableform!: FormGroup;
    option = new Subject<string>();
    input = new Subject<string>();
    isEdit!: boolean;
    isEmpty!: boolean;
    group: any = {};
    gridDefaultColumns: ColumnsConfiguration[] = [
        ({
            display: '', id: 'status', width: '205', align: 'left',
            columnSourceId: 'userName', type: 'text-select', visible: false
        })
    ]

    private onDataRequest = new Subject<any>();
    public onDataRequest$ = this.onDataRequest.asObservable();

    //input values
    @Input() datasoureEdit!: DataSourceEdit<any>
    @Input() options!: { display: string }[] | [];
    @Input() emptyText!: string | "";
    @Input() hasActions!: boolean | false;
    @Input() newRowButton: boolean = true;
    @Input() propertyOption!: string | "";
    @Input() initValueGridEdit!: object | any;
    @Input() newButtonText!: string | "";


    //Output values
    @Output() inputChange = new EventEmitter<any>();
    @Output() selectChange = new EventEmitter<any>();
    @Output() saveAction = new EventEmitter<FormArray<any>>();
    @Output() deleteAction = new EventEmitter<FormArray<any>>();


    ngOnChanges(changes: SimpleChanges): void {
        const dataSourceChanges = changes['datasoureEdit'];
        if (dataSourceChanges && dataSourceChanges.currentValue?.columns) {
            const columnChanges = dataSourceChanges.currentValue.columns;
            if (dataSourceChanges?.firstChange) {
                const columns: ColumnsConfiguration[] = columnChanges;

                this.tableform = this.fb.group({
                    formValues: this.fb.array(
                        [Validators.required]
                    )
                });
            }
        }
    }

    ngOnInit(): void {
        this.isEdit = false;
        this.isEmpty = false;

        let values = this.datasoureEdit;

        this.datasoureEdit.dataResponse$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(e => {

                if (!e) {
                    return;
                }

                this.formValues.clear();
                if (this.hasActions) {
                    this.isEmpty = e.data == undefined || e.data.length == 0;
                }


                if (e.data != undefined) {
                    e.data.forEach(r => {

                        let gridColumns = this.datasoureEdit.columns.concat(this.gridDefaultColumns);
                        gridColumns.forEach(c => {
                            this.group[c.id] = r[c.id]; // assign value with columns
                        });
                        this.addRow()
                    });
                } else {
                    //if don't have values, initialize with the input initValueGridEdit
                    let g: any = {};

                    this.datasoureEdit.columns?.forEach(c => {
                        if (this.options != undefined && this.propertyOption == c.id) {
                            this.group[this.propertyOption] = this.options[0].display
                        } else {
                            g[c.id] = this.datasoureEdit.initValueGridEdit != undefined ? this.datasoureEdit.initValueGridEdit[c.id] : '';//assign value with columns
                        }
                    });

                    this.group = g;
                }
                this.autoSave();

            }
        );

    }


    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    onEdit(e: any) {
        this.changeValue(e, 'enable');
    }

    addRow(): void {
        this.formValues.push(this.initRow());
        this.isEmpty = this.formValues.length === 0 ? true : false;
    }

    newRow(): void {
        let gridColumns = this.datasoureEdit.columns.concat(this.gridDefaultColumns);

        gridColumns.forEach(c => {

            if (this.options != undefined && this.propertyOption == c.id) {
                this.group[this.propertyOption] = this.options[0].display
            } else {
                this.group[c.id] = this.datasoureEdit.initValueGridEdit != undefined ? this.datasoureEdit.initValueGridEdit[c.id] : '';//assign value with columns
            }
        });
        this.addRow();
    }

    initRow(): FormGroup {
        let groupValidations = this.group;

        // Object.keys(this.group).forEach((key) => {
        //   if (key !== 'status')
        //     groupValidations[key] = [null, [Validators.required, this.isNumberValidator()]]
        // }
        // )

        return this.fb.group(groupValidations);
    }

    isNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const value = control.value;
            const validValue = value?.toString().match(/^[0-9]+(\.?[0-9]+)?$/)

            return !validValue ? {isNumber: true} : null;
        }
    }

    get formValues(): FormArray {
        return this.tableform.get("formValues") as FormArray
    }

    onSave(e: any) {
        this.changeValue(e, 'disabled');
        this.autoSave();
    }

    onClose(e: any) {
        this.changeValue(e, 'disabled');
    }

    onDelete(row: any): void {
        this.deleteAction.emit(this.formValues);

        const tableArray = <FormArray>this.tableform.controls['formValues'];
        tableArray.removeAt(row);

        this.autoSave();
    }

    changeValue(row: any, value: string) {
        const tableArray = <FormArray>this.tableform.controls['formValues'];
        tableArray.at(row).patchValue({status: value})
    }

    AddValue() {
    }

    autoSave() {
        this.saveAction.emit(this.formValues);
        if (this.hasActions) {
            this.isEmpty = this.formValues.length === 0 ? true : false;
        }

    }

}

export type GridEditDataModel<TData> = {
    data: TData[],
}

export interface emptyRow {
    [key: string]: string
}
