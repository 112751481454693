import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({name: 'safe'})
export class SafeHtmlPipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) {
    }

    transform(value: string | null | undefined): SafeHtml {
        if (!value) return '';
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }

}
