import { AfterViewInit, Component } from '@angular/core';
import { ICellRendererAngularComp, INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { FormArray, FormControl } from '@angular/forms';
import { ColDef, GridApi, ICellEditorParams, ICellRendererParams, INoRowsOverlayParams } from 'ag-grid-community';
import { getFormArrayItemFromCellNode } from './ccx-table.component';

export type CcxSelectCellEditorParams = ICellEditorParams &
{ listData: { id: string, text: string }[] };

@Component({
    selector: 'ccx-table-cell-renderer',
    template: `
        <div class="ccx-table-cell__wrapper" [class.invalid]="!!formControl?.invalid">
            <span>{{ value }}</span>
        </div>
    `,
    styles: [`
        .ccx-table-cell__wrapper {
            box-sizing: border-box;
            width: 100%;
            height: 100%;

            &.invalid {
                border: 2px solid var(--modus-alert-danger-border-color, #da212c);
            }
        }
    `]
})
export class CcxTableCellRendererComponent implements ICellRendererAngularComp, AfterViewInit {
    formControl: FormControl | null = null;
    value!: any;

    constructor() {
    }

    agInit(params: ICellRendererParams): void {
        this.value = params.value;
        const formArray = params.context.componentParent.formArray as FormArray;
        const formArrayHasValueWithinCellRowIndex =
            formArray.length > (params.node.rowIndex || 0);
        if (formArrayHasValueWithinCellRowIndex) {
            this.formControl = getFormArrayItemFromCellNode(params.context.componentParent.formArray,
                params.node.rowIndex || 0, params.column?.getColId() || '');
        }


    }

    ngAfterViewInit(): void {

    }

    refresh(params: ICellRendererParams<any>): boolean {
        return true;
    }

}

@Component({
    selector: 'ccx-table-no-rows-overlay',
    template: `
        <div style="margin-top: {{marginTop}}" class="ccx-table-no-rows-overlay__wrapper">
            <div class="ccx-table-no-rows-overlay__content">
                <svg-icon svgClass="" name="alert-outlined"></svg-icon>
                <span class="ccx-table-no-rows-overlay__text">{{message}}</span>
            </div>
        </div>
    `,
    styles: [`
      .ccx-table-no-rows-overlay__wrapper{
    background-color: #F1F1F6;
    border-radius: 0.125rem;
    padding:8px;
    
}

    .ccx-table-no-rows-overlay__content{
        display: flex;
        gap: 0.5rem;
        justify-content: flex-start;
        align-items: center;
    }
  `]
})
export class CcxTableNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
    message!: string;
    marginTop!: string;


    agInit(params: INoRowsOverlayParams & { message: string, icon: string, margin:string }): void {
        this.marginTop = params.margin;
        this.message = params.message;

    }
}
