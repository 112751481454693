<div class="grid-editing" *ngIf="(datasoureEdit.dataResponse$ | async) as data">
    <div id="table-editing" *ngIf="!isEmpty">

        <form [formGroup]="tableform" (ngSubmit)="AddValue()">

            <table>
                <thead>
                <tr class="column-names">
                    <th *ngFor="let column of  datasoureEdit.columns"
                        [ngStyle]="{ 'min-width': column.width + 'px' , 'display': column.visible ? 'table-cell':'none'}">
                        {{ column.display }}
                    </th>
                    <th *ngIf="hasActions"></th>
                </tr>
                </thead>
                <tbody class="row-values" formArrayName="formValues">

                <tr *ngFor="let row of formValues.controls; let i=index;" [formGroupName]="i">

                    <td *ngFor="let column of  datasoureEdit.columns"
                        [ngStyle]="{'display': column.visible ? 'table-cell':'none'}">

                        <div class="row" *ngIf="hasActions ; else noActions">
                            <div *ngIf="row.value.status=='disabled' ">{{ row.value[column.id] }}</div>

                            <ng-container *ngIf=" ($any(row).controls[column.id])  as f">
                                <modus-text-input *ngIf="row.value.status=='enable'  && column.type == 'text-input' "
                                                  class="form-control" type="text" [formControl]="f"
                                                  [formControlName]="column.id" [errorText]="(f.invalid && (f.dirty || f.touched)) ?f.errors?.['required']?'This is a required field':
                    'Numbers only': ''"></modus-text-input>
                            </ng-container>

                            <select *ngIf="row.value.status=='enable' && column.type == 'text-select' "
                                    [formControlName]="column.id">
                                <option *ngFor="let c of options" [ngValue]="c.display">{{ c.display }}</option>
                            </select>
                        </div>
                        <ng-template #noActions>
                            <div>{{ row.value[column.id] }}</div>
                        </ng-template>
                    </td>

                    <td *ngIf="hasActions" [ngStyle]="{ 'min-width': '80px' , 'display': 'table-cell'}">
                        <ccx-button [disabled]="!formValues.valid" *ngIf="row.value.status=='enable' " size="small"
                                    buttonStyle="borderless" (buttonClick)="onSave(i)">
                            <svg-icon svgClass="" name="check"></svg-icon>
                        </ccx-button>

                        <ccx-button *ngIf="row.value.status=='enable' " size="small" buttonStyle="borderless"
                                    (buttonClick)="onClose(i)">
                            <svg-icon svgClass="" name="close-circle"></svg-icon>
                        </ccx-button>

                        <ccx-button *ngIf="row.value.status=='disabled' " size="small" buttonStyle="borderless"
                                    (buttonClick)="onEdit(i)">
                            <svg-icon svgClass="" name="edit"></svg-icon>
                        </ccx-button>

                        <ccx-button *ngIf="row.value.status=='disabled' " size="small" buttonStyle="borderless"
                                    (buttonClick)="onDelete(i)">
                            <svg-icon svgClass="" name="trash"></svg-icon>
                        </ccx-button>

                    </td>


                </tbody>
            </table>

        </form>
    </div>
    <div class="empty-grid-editing" *ngIf="isEmpty">
        <svg-icon svgClass="ge-icon-warning" name="warning"></svg-icon>
        <p>{{ emptyText }}</p>
    </div>


    <div class="pt-1" *ngIf="newRowButton || isEmpty">
        <ccx-button size="medium" buttonStyle="borderless" (click)="newRow()">{{ newButtonText| transloco }}
        </ccx-button>
    </div>
    <!--
      {{formValues.value | json}}
      {{formValues.valid | json}} -->
</div>
