import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';


export interface confirmationModalProps {
    size?: 'small' | 'medium' | 'large'
    headerText: string;
    subHeaderText?: string;
    primaryButtonText: string;
    secondaryButtonText: string;
    submitDisabled?: boolean
    description?: string;
    onCloseModal?: any;
    onClickPrimaryButton: (input: any) => any
    onClickSecondaryButton: (input: any) => any
}

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

    @Input() size!: 'small' | 'medium' | 'large'
    @Input() isDelete: false | true = false
    @Input() headerText!: string;
    @Input() subHeaderText!: string;
    @Input() primaryButtonText!: string;
    @Input() secondaryButtonText!: string;
    @Input() submitDisabled!: boolean

    @Output() onCloseModal = new EventEmitter<boolean>();

    @Output() onClickPrimaryButton = new EventEmitter<void>();
    @Output() onClickSecondaryButton = new EventEmitter<void>();

    ngOnInit(): void {
    }

    chageModalStatus() {
        this.onCloseModal.emit();
    }

    handlePrimaryAction(): void {
        this.onClickPrimaryButton.emit();
        this.chageModalStatus();
    }

    handleSecondaryButton(): void {
        this.onClickSecondaryButton.emit();
        this.chageModalStatus();
    }
}

