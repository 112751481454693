import {Component, Input, OnInit} from '@angular/core';
import {TemplateResponse, dummyNotifications} from '../../models/notifications/TemplateResponse'
import {NotificationDt} from 'src/app/models/users/usersNotification.model';


@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {


    @Input() AllUsersNotification!: NotificationDt[];


    constructor() {
    }


    ngOnInit(): void {

    }

    dummyNotifications: TemplateResponse[] = dummyNotifications;
    dateSort = false;
    openSortMenu = false;

    //Menu Options

    onClickSort() {
        this.openSortMenu = !this.openSortMenu;
    }

    config() {
        // window.alert('config');
    }

    close() {
        // window.alert('close');
    }

    //Sort Menu

    sortByDate() {
        this.dateSort = true;
        this.onCloseSortMenu();
    }

    sortByUnread() {
        this.dateSort = false;
        this.onCloseSortMenu();
    }

    onCloseSortMenu() {
        this.openSortMenu = false;
    }


    //Methods

    getInitials(firstName: string, lastName: string) {
        return firstName[0].toUpperCase() + lastName[0].toUpperCase();
    }

}
