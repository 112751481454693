<div id="data-table">

    <div id="search">
        <input #box id="searchtext" placeholder="Placeholder text" type="text" name="search-text"
               (keydown)="onKeydown($event)" (blur)="onBlur(box.value)" [value]="searchValue"/>
    </div>
    <div id="filter" *ngIf="hasFilter">

        <ccx-button size="medium" color="secondary" buttonStyle="outline" (buttonClick)="showFilter()">
            <svg-icon svgClass="svg-icon" name="filter"></svg-icon>
             &#32;{{ 'Filter'| transloco }}
        </ccx-button>

    </div>
    <div id="data-info">

        <modus-data-table [columns]="columns" [data]="data" [displayOptions]="displayOptions"
                          [selectionOptions]="selectionOptions" [sortOptions]="sortOptions" [rowActions]="rowActions"
                          (cellLinkClick)="cellClick($event)" (selection)="selectedCheckbox($event)"
                          (rowActionClick)=" rowActionClick($event) ">
        </modus-data-table>
    </div>

    <div id="pagination" *ngIf="pagination.hasPagination">
        <modus-pagination max-page="10" min-page="1" active-page="1" [minPage]="minPage" [maxPage]="maxPage"
                          [size]="(pagination.size)" (pageChange)="ChangePagination($event)"></modus-pagination>
    </div>


    <!-- <app-confirmation-modal [(showModal)]="showModal"
      (onClickPrimaryButton)=selectedAction.actionInfo.primaryButton.onActionClick()
      (onClickSecondaryButton)=selectedAction.actionInfo.secondaryButton.onActionClick()
      [headerText]="selectedAction.actionInfo.headerText" [description]="selectedAction.actionInfo.description"
      [primaryButtonText]="selectedAction.actionInfo.primaryButton.text | transloco"
      [secondaryButtonText]="selectedAction.actionInfo.secondaryButton.text | transloco">
    </app-confirmation-modal> -->

</div>
