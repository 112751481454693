import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    CellClickedEvent,
    CellEditingStoppedEvent,
    ColDef,
    FirstDataRenderedEvent,
    GridApi, GridOptions,
    GridReadyEvent,
    IRowNode,
    ModelUpdatedEvent,
    PostSortRowsParams,
    RowClassParams,
    RowClassRules,
    RowStyle,
    SelectionChangedEvent,
    SortChangedEvent
} from 'ag-grid-community';
import { Subject } from 'rxjs';
import { TRow } from '@trimble-oss/modus-web-components/loader';
import { KebabMenuAction } from '../ccx-ag-datagrid/cellEditor/kebab-menu-cell-editor.component';


@Component({
    selector: 'ccx-ag-grid',
    styleUrls: ['./ccx-ag-grid.component.scss'],
    templateUrl: './ccx-ag-grid.component.html',
})
export class CcxAgGridComponent implements OnInit, OnChanges, OnDestroy {

    private onDestroy$ = new Subject<void>();
    private gridApi!: GridApi;
    selectedAction!: KebabMenuAction;
    SelectedRow!: TRow;

    public context: any;
    @Input() validGrid: boolean = false;
    @Input() addingRows: boolean = false;
    @Input() rowData: any[] = [];
    @Input() columnDefs!: ColDef[] | undefined;
    @Input() rowSelection: 'single' | 'multiple' = 'single';
    @Input() defaultColDef!: ColDef<any, any>;
    @Input() onlyOneRow: boolean = false;
    @Input() pinnedTopRowData: any[] | undefined;
    @Input() getRowStyle: ((params: RowClassParams<any>) => RowStyle | undefined) | undefined;
    @Input() rowClassRules: RowClassRules<any> | undefined;
    @Input() suppressMovableColumns: boolean = false;
    @Input() suppressCellFocus: boolean = false;
    @Input() headerHeight: number | undefined;
    @Input() overlayLoadingTemplate!: string;
    @Input() overlayNoRowsTemplate!: string;
    @Input() gridOptions?: GridOptions;



    @Output() isGridValid = new EventEmitter<any>();
    @Output() updatedData = new EventEmitter<any>();
    @Output() onCellLinkClick = new EventEmitter<IRowNode<any>>();
    @Output() sort = new EventEmitter<SortChangedEvent>();
    @Output() selectionChanged = new EventEmitter<SelectionChangedEvent<any>>();
    @Output() onGridReady = new EventEmitter<GridReadyEvent>();
    @Output() modelUpdated = new EventEmitter<ModelUpdatedEvent<any>>();
    @Output() rowValueChanged = new EventEmitter<any>();
    @Output() firstDataRendered = new EventEmitter<FirstDataRenderedEvent<any>>();
    rowTest: any[] = [
        {
            "name": "Test",
            "value": 1,
            "Methane":1,
        }
    ];
    constructor() {
        this.context = { componentParent: this };
    }


    onRowValueChanged(event: any) {
        this.rowValueChanged.emit(event);
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    gridReady(params: GridReadyEvent) {
        this.gridApi = params.api;

        this.gridApi.sizeColumnsToFit({
            defaultMinWidth: 100
        });
        this.onGridReady.emit(params);
    }

    addRow() {
        if (this.onlyOneRow) {
            if (this.gridApi.getDisplayedRowCount() == 1)
                return
        }
        // add a new row on ag grid
        let newItems: { [x: string]: string; } = {};

        this.columnDefs?.forEach((columnDef) => {
            if (columnDef.field !== 'remove')
                newItems[columnDef.field || ''] = "";
        });

        this.gridApi.applyTransaction({
            add: [newItems],
        });
        // clear and update rowData
        this.rowData = [];
        this.gridApi.forEachNode((node: any) => {
            this.rowData.push(node.data);
        });

        if (this.validGrid) {
            this.isAgGridValid();
        }

    }

    removeRow(rowIndex: any) {

        let remove = [];
        remove.push(this.gridApi.getRowNode(rowIndex)?.data);

        this.gridApi.applyTransaction({
            remove: remove
        });

        // clear and update rowData
        this.rowData = [];
        this.gridApi.forEachNode((node: any) => {
            this.rowData.push(node.data);
        });

        if (this.validGrid) {
            this.isAgGridValid();
        }
        this.onUpdatedData();
    }


    isAgGridValid(): void {
        this.isGridValid.emit(this.rowData.every(i => Object.values(i).every(v => {
            if (v === null || v === undefined || v === '') {
                return false;
            }
            return true;
        })));
    }

    onCellEditingStopped(event: CellEditingStoppedEvent) {
        this.isAgGridValid()
        this.onUpdatedData();
    }

    onUpdatedData(): void {
        this.updatedData.emit(this.rowData);
    }

    //from cellEditors
    removeButtonParent(rowIndex: any) {
        this.removeRow(rowIndex);
    }

    linkMethodParent(e: IRowNode<any>): void {
        this.onCellLinkClick.emit(e);
    }

    onSortChanged(e: SortChangedEvent) {
        this.sort.emit(e);
    }

    rowActionClick(e: IRowNode<any>, action: string): void {
        this.SelectedRow = e.data;
    }

    oncellClicked(e: CellClickedEvent) {


        const cell = e.column.getColDef() as ColDef & { cellSelected: string };
        if (cell.cellSelected) {
            e.node.setSelected(true);
        }
    }


}
