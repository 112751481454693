<div class="parent">
    <div class="top-icons">
        <p class="title-notifications"><b>{{ 'Notifications'| transloco }}</b></p>
        <div>
            <svg-icon (click)="onClickSort()" svgClass="svg-sortBars" name="sortBars"
                      [viewBox]="'0 0 30 30'"></svg-icon>
            <svg-icon (click)="config()" svgClass="svg-gear" name="gear" [viewBox]="'0 0 30 30'"></svg-icon>
            <svg-icon (click)="close()" svgClass="svg-x" name="x" [viewBox]="'0 0 30 30'"></svg-icon>
        </div>
    </div>
    <div *ngIf="AllUsersNotification">
        <div *ngFor="let dummyNotification of AllUsersNotification">
            <div class="userinfonotification">
                <div class="usernotif">
                    <svg class="bx mw" *ngIf="dummyNotification && dummyNotification.status"
                         xmlns="http://www.w3.org/2000/svg" width="7"
                         height="7" viewBox="0 0 7 7" fill="none">
                        <circle cx="3.5" cy="3.5" r="3.5" fill="#0063A3"/>
                    </svg>
                    <div class="bx">
                        <svg class="mw" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"
                             fill="none">
                            <circle cx="13" cy="13" r="13" fill="#90939F"/>
                            <g>
                                <text class="circle" x="50%" y="50%" text-anchor="middle" stroke="#FFFFFF" dy=".3em">
                                    {{ dummyNotification.userName }}
                                </text>
                            </g>
                        </svg>
                        <span class="text-action" *ngIf="dummyNotification?.template">
            {{ dummyNotification.template | translateTemplate }}</span>
                    </div>
                </div>
                <div>
                    <span class="column-time"> {{ dummyNotification.time }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="sort-menu" *ngIf="openSortMenu">
        <modus-list>
            <div class="sort-options " (click)="sortByDate()">
                <div class="sort-icon">
                    <svg *ngIf="dateSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                         fill="none">
                        <path
                            d="M7.99984 0.666504C3.95184 0.666504 0.666504 3.95184 0.666504 7.99984C0.666504 12.0478 3.95184 15.3332 7.99984 15.3332C12.0478 15.3332 15.3332 12.0478 15.3332 7.99984C15.3332 3.95184 12.0478 0.666504 7.99984 0.666504ZM6.53317 11.6665L2.8665 7.99984L3.9005 6.96584L6.53317 9.59117L12.0992 4.02517L13.1332 5.0665L6.53317 11.6665Z"
                            fill="#252A2E"/>
                    </svg>
                </div>
                <p>
                    Sort by date
                </p>

            </div>
            <div class="sort-options " (click)="sortByUnread()">
                <div class="sort-icon">
                    <svg *ngIf="!dateSort" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                         fill="none">
                        <path
                            d="M7.99984 0.666504C3.95184 0.666504 0.666504 3.95184 0.666504 7.99984C0.666504 12.0478 3.95184 15.3332 7.99984 15.3332C12.0478 15.3332 15.3332 12.0478 15.3332 7.99984C15.3332 3.95184 12.0478 0.666504 7.99984 0.666504ZM6.53317 11.6665L2.8665 7.99984L3.9005 6.96584L6.53317 9.59117L12.0992 4.02517L13.1332 5.0665L6.53317 11.6665Z"
                            fill="#252A2E"/>
                    </svg>
                </div>
                <p>
                    Sort by unread
                </p>
            </div>
        </modus-list>
    </div>
</div>
