export interface TemplateResponse {
    templateId: string;
    parameters: ResponseParams | null | undefined | ResponseParams[];
}


export interface TemplateResponseExt {
    accessRequestEmail: string;
    extenssions: ResponseParams | null | undefined;
}

export interface ResponseParams {
    [key: string]: string
}

export const dummyHttpErrorResponse: TemplateResponse = {
    "templateId": "ERROR_NEW_PROJECT_DUPLICATE",
    "parameters":
        {
            "ProjectName": "DuplicatedName",
        }

}

export const dummyHttpSuccessResponse: TemplateResponse = {
    "templateId": "SUCESS_PROJECT_CREATION",
    "parameters":
        {
            "ProjectName": "X"
        }

}


export const dummyNotifications: TemplateResponse[] = [
    {
        "templateId": "CREATE_NEW_PROJECT",
        "parameters":
            {
                "UserName": "Ana",
                "ProjectName": "A"
            }
    },
    {
        "templateId": "CREATE_NEW_PROJECT",
        "parameters":
            {
                "UserName": "Manuel",
                "ProjectName": "B"
            }
    },
    {
        "templateId": "CREATE_NEW_PROJECT",
        "parameters":
            {
                "UserName": "Rubi",
                "ProjectName": "C"
            }
    },
];
