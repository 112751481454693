<div class="modal-content">
    <app-spinner [isWorking]="isWorking()"></app-spinner>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'Upload Documents' | transloco }}</h5>
        <modus-button size="25" button-style="borderless" color="primary" icon-only="close" aria-label="close"
            [disabled]="!uploadFilesGroup.valid" (buttonClick)="dialogClose()"></modus-button>
    </div>
    <div class="modal-body" cdk-scrollable>
        <div class="file-upload">
            <div class="upload-file">
                <modus-file-dropzone #fileUpload aria-Label="dropzone" description="" dropzoneHeight="calc(150px)"
                    dropzoneWidth="90%" label="" [multiple]="true" (files)="onFileSelected($event)">
                </modus-file-dropzone>
            </div>
            <form [formGroup]="uploadFilesGroup">
                <ng-container *ngFor="let errorName of Object.keys(uploadFilesGroup.errors ?? {})">
                    <modus-alert message="{{uploadFilesGroup.errors?.[errorName] | transloco}}"
                                 type="warning"></modus-alert>
                </ng-container>
                <div formArrayName="files">

                    <div class="card" *ngFor="let file of uploadFilesGroup.controls.files.controls; let i=index" [formGroupName]="i">
                        <div>
                            <label class="font-medium file-name"> {{file.controls.nameFile.value }}</label>
                        </div>
                        <div class="card-items">
                            <svg-icon class="main-view-add-icon-svg" [name]="file.controls.iconType.value ?? ''"></svg-icon>

                            <div *ngIf="file.value['statusbar'] === 100" class="w-50">
                                <app-modus-select id="select" [options]="documentTypesList() ?? []" size="medium"
                                                [formControl]="$any(file).controls['category']" placeholder="category"
                                                  [optionsDisplayProp]="'name'" [required]="true" ngDefaultControl
                                                  (valueChange)="validateInput('category', file)"
                                                  [required]="$any(file).controls['category'].value.required"
                                                  >
                                </app-modus-select>
                            </div>

                            <div *ngIf="file.value['statusbar'] === 100" >

                                <modus-text-input size="medium" placeholder="" formControlName="name" ngDefaultControl
                                    [required]="true"
                                    [errorText]="(file.controls.category.invalid && (file.controls.category.dirty || file.controls.category.touched)) ?file.controls.name.errors?.['required']?'This is a required field': '': ''">
                                </modus-text-input>
                            </div>
                            <div class="progress-bar" *ngIf="file.controls.statusbar.value !== 100">
                                <modus-progress-bar [value]="file.controls.statusbar.value" max-value="100" min-value="0"
                                                    size="small">
                                </modus-progress-bar>
                                <label class="font-xs pl-1">{{file.value['statusbar'] + '% of ' + file.value['size'] +
                                    ' KB uploaded'}}</label>
                            </div>
                            <modus-button  button-style="borderless" color="tertiary" aria-label="Notifications"
                                           (buttonClick)="removeFile(file.controls.nameFile.value ?? '')" [disabled]="(file.controls.statusbar.value ?? 0) < 100">
                                <svg-icon class="main-view-add-icon-svg" name="trash"></svg-icon>
                            </modus-button>
                        </div>
                        <ng-container *ngFor="let errorName of Object.keys(file.errors ?? {})">
                            <modus-alert message="{{file.errors?.[errorName] | transloco}}"
                                         type="warning"></modus-alert>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div *ngIf="showMessage" class="width-alert">
        <div class="alert-m">
            <modus-alert dismissible
                message="{{ alertMessage | transloco}}"
                (dismissClick)="onCloseMessage($event)" (actionClick)="onCloseMessage($event)"
                type="warning"></modus-alert>
        </div>

    </div>
    <ccx-button size="medium" buttonStyle="outline" [disabled]="!uploadFilesGroup.valid" color="secondary"
        (buttonClick)="dialogClose()">{{ 'Cancel'|
        transloco }}
    </ccx-button>
    <ccx-button size="medium" color="primary" [disabled]="!(uploadFilesGroup.valid && uploadFilesGroup.controls.files.length > 0)" (buttonClick)="SubmitFiles()">{{
        '&nbsp; &nbsp; &nbsp; OK &nbsp; &nbsp; &nbsp; '| transloco }}
    </ccx-button>
</div>
