import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataSource } from '../data-source';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GridColumnConfiguration, GridColumnConfigurationAg } from '../ccx-grid/ccx-grid.component';
import { BinaryFilter, BinaryFilterOperation } from '../../generated/operations-core-graphql';
import { IDropdownSettings } from '../multi-select-dropdown-ext';
import { TranslocoService } from '@jsverse/transloco';
import { ListItem } from '../multi-select-dropdown-ext/multiselect.model';
import moment from 'moment';

@Component({
    selector: 'ccx-filter-datasource',
    styleUrls: ['./ccx-filter-data-source.component.scss'],
    templateUrl: './ccx-filter-data-source.component.html'
})
export class CcxFilterDataSourceComponent implements OnInit, OnChanges {
    @Input() dataSource!: DataSource<any>;
    filtersFormGroup!: FormGroup;

    @Output() onFilterApplied = new EventEmitter<BinaryFilter>();
    @Output() onFilterCancelled = new EventEmitter();

    @Input() selectedItems: Map<string, any[]> | undefined;

    @Input() counter: any;
    @Output() counterChange = new EventEmitter<any>();

    constructor(readonly fb: FormBuilder, private langSvc: TranslocoService) {
    }

    dropdownSettings: IDropdownSettings = {
        labelSize: 'large',
        disabledField: '',
        singleSelection: false,
        idField: 'id',
        textField: 'text',
        noDataAvailablePlaceholderText: this.langSvc.translate('No Data Available'),
        itemsShowLimit: 10,
        allowSearchFilter: false,
        enableCheckAll: false,
        showSelectedItemsAtTop: false
    };

    getDropDownSettingsFromColumnConfiguration = (columnConfiguration: GridColumnConfigurationAg): IDropdownSettings => {
        return {
            ...this.dropdownSettings,
            searchPlaceholderText: this.langSvc.translate(columnConfiguration.placeHolder || ''),
        };
    }

    ngOnInit(): void {
    }

    applyFilter() {
        if (this.filtersFormGroup.valid) {
            const binaryFilter: BinaryFilter = {
                exprGrp: []
            };
            const fg = this.filtersFormGroup;
            let isNumber = false;
            let filtersCounter = 0;
            this.dataSource.gridColumnConfigurations.forEach(c => {
                const fcValue = fg.value[c.columnSourceId];
                if (fcValue) {
                    switch (c.filterType) {
                        case 'none':
                            break;
                        case 'text':
                            binaryFilter.exprGrp?.push({
                                grpOp: BinaryFilterOperation.And,
                                expr: {
                                    column: c.columnSourceId,
                                    operator: 'contains',
                                    value: fcValue
                                }
                            });
                            filtersCounter++;
                            break;
                        case 'Guid':
                            binaryFilter.exprGrp?.push({
                                grpOp: BinaryFilterOperation.And,
                                expr: {
                                    column: c.columnSourceId,
                                    operator: '=',
                                    value: fcValue
                                }
                            });
                            filtersCounter++
                            break;
                        case 'select':
                            const selectedList = fcValue as ListItem[];
                            if (selectedList.length > 0) {
                                const filters: BinaryFilter[] = [];
                                selectedList.forEach(s => {
                                    filters.push({
                                        grpOp: BinaryFilterOperation.Or,
                                        expr: {
                                            column: c.columnSourceId,
                                            operator: '=',
                                            value: s.id.toString()
                                        }
                                    })
                                });
                                binaryFilter.exprGrp?.push({
                                    grpOp: BinaryFilterOperation.And,
                                    exprGrp: filters
                                });
                            }
                            filtersCounter++
                            break;
                        case 'check':
                            break;
                        case 'number':
                            isNumber = !Number.isNaN(Number(fcValue))
                            const isInteger = Number.isInteger(Number(fcValue))

                            if (isNumber && isInteger) {
                                binaryFilter.exprGrp?.push({
                                    grpOp: BinaryFilterOperation.And,
                                    expr: {
                                        column: c.columnSourceId,
                                        operator: '=',
                                        value: fcValue.toString()
                                    }
                                });
                            }
                            filtersCounter++
                            break;
                        case 'decimal':
                            isNumber = !Number.isNaN(Number(fcValue))
                            if (isNumber) {
                                binaryFilter.exprGrp?.push({
                                    grpOp: BinaryFilterOperation.And,
                                    expr: {
                                        column: c.columnSourceId,
                                        operator: '=',
                                        value: fcValue.toString()
                                    }
                                });
                            }
                            filtersCounter++
                            break;
                        case 'dateTime':
                            const startDate = moment(fcValue) . format('yyyy-MM-DD HH:mm:ss Z')
                            const endDate = moment(fcValue).endOf('day').format('yyyy-MM-DD HH:mm:ss Z')

                            binaryFilter.exprGrp?.push({
                                grpOp: BinaryFilterOperation.And,
                                expr: {
                                    column: c.columnSourceId,
                                    operator: '>=',
                                    value: startDate
                                }
                            });
                            binaryFilter.exprGrp?.push({
                                grpOp: BinaryFilterOperation.And,
                                expr: {
                                    column: c.columnSourceId,
                                    operator: '<=',
                                    value: endDate
                                }
                            });
                            filtersCounter++
                            break;
                    }
                }
            })
            this.dataSource.onApplyFilter(binaryFilter);
            this.onFilterApplied.emit(binaryFilter);
            this.counterChange.emit(filtersCounter);
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
        const dataSourceChanges = changes['dataSource'];
        if (dataSourceChanges && dataSourceChanges.currentValue?.gridColumnConfigurations) {
            const columnChanges = dataSourceChanges.currentValue.gridColumnConfigurations;
            if (dataSourceChanges?.firstChange) {
                const columns: GridColumnConfiguration[] = columnChanges;
                const group: any = {};
                columns.forEach(c => {

                    var validators: ValidatorFn[] = [];

                    if (c.filterType === 'Guid') {
                        validators.push(Validators.pattern(new RegExp('^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$', 'i')));
                    }
                    else if (c.filterType === 'number') {
                        validators.push(Validators.pattern('^[0-9]*$'));
                    }
                    else if (c.filterType === 'decimal') {
                        validators.push(Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$'));
                    }


                    const fc = new FormControl(c.defaultValue, validators);
                    group[c.columnSourceId] = fc;

                });
                this.filtersFormGroup = this.fb.group(group);
            }
        }
    }

    resetFilter() {
        if (this.filtersFormGroup.touched) {
            Object.keys(this.filtersFormGroup.controls).forEach(key => {
                this.dataSource.gridColumnConfigurations.forEach(c => {
                    if (c.filterType === 'select') {
                        this.filtersFormGroup.get(key)?.reset([]);
                    } else {
                        this.filtersFormGroup.get(key)?.reset();
                    }
                });
            });
            this.dataSource.onFiltersReset();
        }
        this.onFilterCancelled.emit();
        this.counterChange.emit(0);
    }

    handleSelectionChanged(columnSourceId: string, e: any) {
        this.filtersFormGroup.get(columnSourceId)?.setValue(e.selectedItems);
        this.applyFilter();
    }
}
