<div class="example-header">
    <div class="calendar-header">
        <button type="button" aria-label="Previous Month" (click)="previousClicked('month')"><svg class="icon-chevron-left-bold" height="16" width="16"
                viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.06 15.54 11.53 12l3.53-3.53c.58-.58.58-1.53 0-2.12-.58-.58-1.53-.58-2.12 0l-4.59 4.59a1.5 1.5 0 0 0 0 2.12l4.59 4.59c.29.29.68.44 1.06.44s.77-.15 1.06-.44a1.5 1.5 0 0 0 0-2.12Z">
                </path>
            </svg>
        </button>
        <div class="title">
            <div class="calendar-title">{{periodLabel}}</div>
            <div class="year-icons">
                <button type="button" tabindex="0" aria-label="Next Year" class="year-up" (click)="nextClicked('year')" ><svg class="icon-caret-up"
                        height="16" width="16" viewBox="0 0 24 24" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m11.4 9.26-4.22 4.58c-.43.46-.06 1.16.6 1.16h8.43c.66 0 1.03-.7.6-1.16l-4.22-4.58a.833.833 0 0 0-1.2 0Z">
                        </path>
                    </svg></button>
                <button type="button" tabindex="0" aria-label="Previous Year"(click)="previousClicked('year')" class="year-down"><svg
                        class="icon-caret-down" height="16" width="16" viewBox="0 0 24 24" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m12.6 14.74 4.22-4.58c.43-.46.06-1.16-.6-1.16H7.78c-.66 0-1.03.7-.6 1.16l4.22 4.58c.31.34.89.34 1.2 0">
                        </path>
                    </svg>
                </button>
            </div>
        </div>
        <button type="button" (click)="nextClicked('month')" tabindex="0" aria-label="Next Month" ><svg class="icon-chevron-right-bold" height="16"
                width="16" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m15.64 10.94-4.59-4.59c-.58-.58-1.53-.58-2.12 0a1.5 1.5 0 0 0 0 2.12L12.46 12l-3.53 3.53a1.5 1.5 0 0 0 0 2.12c.28.28.68.44 1.06.44s.77-.15 1.06-.44l4.59-4.59c.58-.58.58-1.53 0-2.12">
                </path>
            </svg>
        </button>
    </div>

</div>
