import {createAction, props} from '@ngrx/store';
import {
    BeefPage,
    ModuleValidation,
    PageNotFound,
} from '../../@core/generated/operations-cms-graphql';
import {SiteMapBase} from './sitenav.reducer';

export const SiteMapLoad = createAction(
    '[Sitemap] Load',
    props<{ contentRootKey: string; lang: string, protocolId: string | null }>()
);

export const SwitchMapRootLoad = createAction(
    '[Sitemap] Clean Load',
    props<{ newRootKey: string; lang: string, protocolId: string | null }>()
);

export const SiteMapSuccess = createAction(
    '[Sitemap] Load Success',
    props<{ contentRootKeys: Array<SiteMapBase | null> | null }>()
);

export const ModuleValidationLoad = createAction(
    '[ModuleValidation] Load',
    props<{ moduleKey: string; lang: string }>()
);

export const ModuleValidationSuccess = createAction(
    '[ModuleValidation] Load Success',
    props<{ moduleValidation: Array<ModuleValidation | null> | null }>()
);

export const PageErrorInfoLoad = createAction(
    '[PageErrorInfo] Load',
    props<{ pageKey: Array<string | null> | null; lang: string }>()
);

export const PageErrorInfoSuccess = createAction(
    '[PageErrorInfo] Load Success',
    props<{ pageErrorInfo: Array<PageNotFound | null> | null }>()
);

export const PageErrorInfoStatus = createAction(
    '[PageErrorInfo] Load Error',
    props<{ moduleKey: string | null, name: string | null }>()
);

export const ProtocolMenuSelected = createAction(
    '[Protocol] Protocol Menu  Selected',
    props<{ ProtocolMenuActive: string }>()
);

export const ProtocolActive = createAction(
    '[Protocol] Protocol Menu  Active',
    props<{ ProtocolActive: string , ProtocolNameActive: string}>()
);
