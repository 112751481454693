import {inject, Inject, Injectable, InjectionToken, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {EMPTY, Observable, catchError, of, tap} from 'rxjs';
import {TIdService} from './services/tId.service';
import {Router} from '@angular/router';
import {AuthenticationOperation, IsAuthenticatedOperator} from '../@core/functions/operator-functions';

export const NoHttpInterceptorHeader = 'X-Ng-NoInterceptor';

function removeHeaders(req: HttpRequest<any>): HttpRequest<any> {
    const h = req.headers.delete(NoHttpInterceptorHeader);
    return req.clone({headers: h});
}

function filterRequestForAuthInterceptor(req: HttpRequest<any>) {
    if (req.headers.has(NoHttpInterceptorHeader)) {
        return true;
    }
    return ['/api/auth/token']
        .some(url => req.url.includes(url));
}

export function AuthInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const tidService = inject(TIdService);
    if (!filterRequestForAuthInterceptor(req)) {
        return IsAuthenticatedOperator(token => {
                const JWT = `Bearer ${token?.tokens?.token}`;
                req = req.clone({
                    setHeaders: {
                        Authorization: JWT,
                    },
                });
                req = removeHeaders(req);
                return next(req)
                    .pipe(
                        catchError(err => {
                            if  (err?.error && err.error.errors && err.error.errors[0] == 'Token Updated') {
                                return tidService.authenticate(null);
                            }
                            throw err;
                        })
                    )
            }
            ,
            tidService, AuthenticationOperation.AuthenticateIfAnnonymous);
    } else {
        req = removeHeaders(req);
        return next(req);
    }
}
