<ng-container *ngFor="let t of directives">
    <ng-container>
        <section class="utility-panel" [ngClass]="{'d-none': t.sectionName !== selectedSection}">
            <div class="utility-panel__header">
                <div class="utility-panel__header__text">
                    {{ t.title }}
                </div>
                <div class="utility-panel__header__close">
                    <svg-icon svgClass="utility-panel__svg" (click)="onClose()" name="close"></svg-icon>
                </div>
            </div>
            <div class="utility-panel__body">
                <ng-container [ngTemplateOutlet]="t.templateRef"></ng-container>
            </div>
        </section>
    </ng-container>
</ng-container>