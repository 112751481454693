import {createAction, props} from '@ngrx/store';
import {GetOrganizationProtocolParticipantQuery} from '../../../../@core/generated/operations-core-graphql';
import {KeyedInput} from '../../../../@core/functions/key-functions';
import {FormControl} from '@angular/forms';

export const coefficientEditorDialogToggle = createAction(
    '[Coefficient Editor] Dialog Toggle',
    props<{ value: boolean, protocolId: string | null, organizationId: string | null }>());

export const participantOrganizationCoefficientsLoad = createAction(
    '[Coefficient Editor] Participant Organization Coefficients Load',
    props<{ protocolId: string, organizationId: string, keys: { name: string, control: FormControl }[] }>());

export const participantOrganizationCoefficientsSuccess = createAction(
    '[Coefficient Editor] Participant Organization Coefficients Success',
    props<{ data: GetOrganizationProtocolParticipantQuery, keys: { name: string, control: FormControl }[] }>());

export const participantOrganizationCoefficientsFail = createAction(
    '[Coefficient Editor] Participant Organization Coefficients Fail'
    , props<{ error: any }>());

export const putCoefficient = createAction('[Coefficient Editor] Add Coefficient',
    props<{ coefficient: KeyedInput & Record<string, string> }>());
export const clearValues = createAction('[Coefficient Editor] Clear Values');

export const deleteCoefficient = createAction('[Coefficient Editor] Delete Coefficient',
    props<{ id: string }>());

export const saveCoefficientsLoad = createAction('[Coefficient Editor] Save Coefficients Load');
export const saveCoefficientSuccess = createAction('[Coefficient Editor] Save Coefficient Success');
export const saveCoefficientFail = createAction('[Coefficient Editor] Save Coefficient Fail',
    props<{ error: any }>());
