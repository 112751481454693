import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Byte: any;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  Decimal: any;
  Long: any;
};

export type AcceptUserInput = {
  email_requested: Array<Scalars['String']>;
};

export type AcceptUsers = {
  __typename?: 'AcceptUsers';
  email: Array<Scalars['String']>;
};

export type AccessRequestByDate = {
  __typename?: 'AccessRequestByDate';
  data: Array<DataAccessRequestByDate>;
  title: Scalars['DateTimeOffset'];
};

export type AddOrganizationtoUser = {
  __typename?: 'AddOrganizationtoUser';
  organizationId: Scalars['ID'];
};

export type AddOrganizationtoUserInput = {
  email_requested: Array<Scalars['String']>;
  organizationId: Scalars['ID'];
};

export type AddProjectInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  periodEnd: Scalars['String'];
  periodStart: Scalars['String'];
  projectAttributes?: InputMaybe<ProjectAttributesInput>;
  projectFactorValues: Array<ProjectFactorValueInput>;
  protocolId: Scalars['ID'];
  status: Scalars['String'];
  tC02Price: Scalars['Decimal'];
};

export type AddProtocolInput = {
  cMSContentRootKey?: InputMaybe<Scalars['ID']>;
  definition?: InputMaybe<DocumentDefinitionInput>;
  factorValues: Array<ProtocolFactorValueInput>;
  name: Scalars['String'];
  organizationId?: InputMaybe<Scalars['ID']>;
  ownerUserIds: Array<Scalars['ID']>;
  protocolTemplateId: Scalars['ID'];
};

export type AddRoleAccessPolicy = {
  __typename?: 'AddRoleAccessPolicy';
  actionName: Scalars['String'];
  subjectKey?: Maybe<Scalars['String']>;
  subjectName: Scalars['String'];
  useMFA: Scalars['Boolean'];
};

export type AddRoleAccessPolicyInput = {
  actionName: Scalars['String'];
  subjectKey?: InputMaybe<Scalars['String']>;
  subjectName: Scalars['String'];
  useMFA: Scalars['Boolean'];
};

export type AddRoleInput = {
  accessPolicies?: InputMaybe<Array<AddRoleAccessPolicyInput>>;
  name: Scalars['String'];
};

export type AddUsersToProtocolInput = {
  protocolId: Scalars['ID'];
  userRoles: Array<UserEmailRoleInput>;
};

export type Advisor = {
  __typename?: 'Advisor';
  advisorCountry: AdvisorCountryDto;
  city?: Maybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  createdBy: Scalars['ID'];
  createdOn: Scalars['DateTimeOffset'];
  deleted: Scalars['Boolean'];
  deletedBy?: Maybe<Scalars['ID']>;
  deletedOn?: Maybe<Scalars['DateTimeOffset']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalZipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTimeOffset']>;
};

export type AdvisorAdded = {
  __typename?: 'AdvisorAdded';
  advisorCountry: AdvisorCountryDto;
  city?: Maybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalZipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  userId: Scalars['ID'];
};

export type AdvisorCountryDto = {
  __typename?: 'AdvisorCountryDto';
  isoCode: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export type AdvisorCountryInput = {
  isoCode: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export type AdvisorInput = {
  advisorCountryInput: AdvisorCountryInput;
  city?: InputMaybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postalZipCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
};

export type AdvisorOutput = {
  __typename?: 'AdvisorOutput';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AdvisorUpdated = {
  __typename?: 'AdvisorUpdated';
  advisorCountry: AdvisorCountryDto;
  city?: Maybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalZipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  userId: Scalars['ID'];
};

export type AllowedFileExtension = {
  __typename?: 'AllowedFileExtension';
  extension: Scalars['String'];
};

export type AllowedFileExtensionInput = {
  extension: Scalars['String'];
};

export type ApiConnection = {
  __typename?: 'ApiConnection';
  applicationName: Scalars['String'];
  clientId: Scalars['ID'];
  clientName: Scalars['String'];
  id: Scalars['ID'];
  lastEditedBy?: Maybe<Scalars['String']>;
  lastEditedOn?: Maybe<Scalars['DateTimeOffset']>;
  organizations_count?: Maybe<Scalars['Int']>;
  protocolId: Scalars['ID'];
};

export type ApiConnectionsInput = {
  applicationName: Scalars['String'];
  clientId: Scalars['ID'];
  clientName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  organizationApiConnectionsInput?: InputMaybe<Array<OrganizationApiConnectionsInput>>;
  protocolId: Scalars['ID'];
};

export type ApiConnectionsOutput = {
  __typename?: 'ApiConnectionsOutput';
  applicationName: Scalars['String'];
  clientId: Scalars['ID'];
  clientName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  organizationApiConnections?: Maybe<Array<OrganizationApiConnectionsOutput>>;
  protocolId: Scalars['ID'];
};

export type ApiConnectionsResponse = {
  __typename?: 'ApiConnectionsResponse';
  apiConnections: Array<ApiConnection>;
  apiConnections__count: Scalars['Int'];
};

export type BeefProjectInput = {
  __typename?: 'BeefProjectInput';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BinaryFilter = {
  expr?: InputMaybe<CustomDataFilter>;
  exprGrp?: InputMaybe<Array<BinaryFilter>>;
  grpOp?: InputMaybe<BinaryFilterOperation>;
};

export enum BinaryFilterOperation {
  And = 'AND',
  AndNot = 'AND_NOT',
  Or = 'OR',
  OrNot = 'OR_NOT'
}

export type CommandResult = {
  __typename?: 'CommandResult';
  correlationId: Scalars['ID'];
};

export type CoreMutation = {
  __typename?: 'CoreMutation';
  acceptUser: AcceptUsers;
  accessRequestEmail: EmailCallback;
  addNewProject: Project;
  addNewProtocol: Protocol;
  addNewProtocolTemplate: ProtocolTemplate;
  addOrganizationToUser: AddOrganizationtoUser;
  addRole: CommandResult;
  addUsersToProtocol: CommandResult;
  deleteAdvisor: Scalars['Boolean'];
  deleteOrganizationFromProtocol: Scalars['Boolean'];
  deleteProtocolUser: CommandResult;
  disableUser: DisableUserResponse;
  editDocument?: Maybe<OrganizationDocumentEditedResponse>;
  editProtocol: Protocol;
  editProtocolTemplate: ProtocolTemplate;
  linkDocumentToOrganizationProjects: Scalars['ID'];
  putAdvisor?: Maybe<Advisor>;
  putApiConnection?: Maybe<ApiConnection>;
  putOrganization?: Maybe<Organization>;
  putOrganizationAdvisor: Scalars['String'];
  putOrganizationAttributes: CommandResult;
  rejectAccesRequest: EmailCallback;
  sendUserInvitationEmail: UserInvitationCallback;
  setProtocolDocumentDefinition: Scalars['ID'];
  submitDocument?: Maybe<DocumentSubmittedToOrganizationResponse>;
  updateOrganizationProtocolParticipantSetFactorValues: OrganizationProtocolParticipantDto;
  updateProject: Project;
  updateProjectStatus: ProjectStatusOutput;
  updateProtocolUser: CommandResult;
};


export type CoreMutationAcceptUserArgs = {
  input: AcceptUserInput;
};


export type CoreMutationAccessRequestEmailArgs = {
  input: RequestUserUi;
};


export type CoreMutationAddNewProjectArgs = {
  input: AddProjectInput;
};


export type CoreMutationAddNewProtocolArgs = {
  input: AddProtocolInput;
};


export type CoreMutationAddNewProtocolTemplateArgs = {
  input: ProtocolTemplateInput;
};


export type CoreMutationAddOrganizationToUserArgs = {
  input: AddOrganizationtoUserInput;
};


export type CoreMutationAddRoleArgs = {
  input: AddRoleInput;
};


export type CoreMutationAddUsersToProtocolArgs = {
  input: AddUsersToProtocolInput;
};


export type CoreMutationDeleteAdvisorArgs = {
  input: DeleteAdvisorsInput;
};


export type CoreMutationDeleteOrganizationFromProtocolArgs = {
  input: DeleteOrganizationsFromProtocolInput;
};


export type CoreMutationDeleteProtocolUserArgs = {
  input: DeleteProtocolUserInput;
};


export type CoreMutationDisableUserArgs = {
  input: DisableUser;
};


export type CoreMutationEditDocumentArgs = {
  input: EditDocumentInput;
};


export type CoreMutationEditProtocolArgs = {
  input: EditProtocolInput;
};


export type CoreMutationEditProtocolTemplateArgs = {
  input: ProtocolTemplateInput;
};


export type CoreMutationLinkDocumentToOrganizationProjectsArgs = {
  input: LinkDocumentToOrganizationProjectsInput;
};


export type CoreMutationPutAdvisorArgs = {
  input: AdvisorInput;
};


export type CoreMutationPutApiConnectionArgs = {
  input: ApiConnectionsInput;
};


export type CoreMutationPutOrganizationArgs = {
  input: OrganizationInput;
};


export type CoreMutationPutOrganizationAdvisorArgs = {
  input: PutOrganizationAdvisorInput;
};


export type CoreMutationPutOrganizationAttributesArgs = {
  input: PutOrganizationAttributesInput;
};


export type CoreMutationRejectAccesRequestArgs = {
  input: DeniedUser;
};


export type CoreMutationSendUserInvitationEmailArgs = {
  input: InviteUserUi;
};


export type CoreMutationSetProtocolDocumentDefinitionArgs = {
  input: SetProtocolDocumentDefinitionInput;
};


export type CoreMutationSubmitDocumentArgs = {
  input: SubmitDocumentInput;
};


export type CoreMutationUpdateOrganizationProtocolParticipantSetFactorValuesArgs = {
  input: OrganizationProtocolParticipantInput;
};


export type CoreMutationUpdateProjectArgs = {
  input: AddProjectInput;
};


export type CoreMutationUpdateProjectStatusArgs = {
  input: UpdateProjectStatusInput;
};


export type CoreMutationUpdateProtocolUserArgs = {
  input: UpdateProtocolUserInput;
};

export type CoreQuery = {
  __typename?: 'CoreQuery';
  advisorDetail: Advisor;
  advisors: Array<Advisor>;
  advisorsCount: Scalars['Int'];
  advisorsFromOrganization: Array<OrganizationAdvisorOutput>;
  apiConnectionsByProtocol: ApiConnectionsResponse;
  apiConnectionsDetailByProtocol?: Maybe<ApiConnectionsOutput>;
  createDocumentUploadLink: Scalars['String'];
  document: DocumentDto;
  documentChangeLogs__count: Scalars['Int'];
  getConfiguration?: Maybe<Scalars['String']>;
  getDocumentChangeLogs: Array<DocumentChangeLog>;
  getOrganizationAttributes?: Maybe<OrganizationAttributes>;
  getOrganizationFileList: DocumentResponse;
  getOrganizationProtocolParticipant: Array<OrganizationProtocolParticipantDto>;
  getPowerBIEmbedToken: PowerBiEmbedToken;
  getProjectDocumentsValidation: ValidDocumentsDto;
  mapConfigOptions: MapConfigOptions;
  me: Me;
  organizationDetail: OrganizationDetail;
  organizations: Array<OrganizationDetail>;
  organizationsByProtocol: Array<ProtocolOrganization>;
  organizationsByProtocol__count: Scalars['Int'];
  organizationsByType: Array<ProtocolOrganization>;
  organizations__count: Scalars['Int'];
  projectDefinition: ProjectProtocolFactorDefinition;
  projectDetail: Project;
  projectDocuments: Array<ProjectDocument>;
  projectDocuments__count: Scalars['Int'];
  projects: Array<Project>;
  projectsByProtocol: Array<Project>;
  projects__count: Scalars['Int'];
  protocolDetailSummary: ProtocolSummary;
  protocols: ProtocolReponse;
  protocolsDistinctBy: Array<Scalars['String']>;
  roles: Array<Role>;
  templateDetail: Array<ProtocolTemplate>;
  templates: Array<ProtocolTemplate>;
  templatesDistinctBy: Array<Maybe<Scalars['String']>>;
  templates__count: Scalars['Int'];
  users: Array<Users>;
  usersByProtocol: Array<Users>;
  usersByProtocol__Count: Scalars['Int'];
  usersByRole: Array<UserDto>;
  usersRequestingByDate: Array<AccessRequestByDate>;
  users__count: Scalars['Int'];
};


export type CoreQueryAdvisorDetailArgs = {
  advisorName: Scalars['String'];
};


export type CoreQueryAdvisorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryAdvisorsCountArgs = {
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryApiConnectionsByProtocolArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  protocolId: Scalars['ID'];
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryApiConnectionsDetailByProtocolArgs = {
  apiConnectionId: Scalars['ID'];
  protocolId: Scalars['ID'];
};


export type CoreQueryCreateDocumentUploadLinkArgs = {
  blobIdentifier: Scalars['String'];
};


export type CoreQueryDocumentArgs = {
  documentId: Scalars['ID'];
};


export type CoreQueryDocumentChangeLogs__CountArgs = {
  documentId: Scalars['ID'];
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryGetConfigurationArgs = {
  key: Scalars['String'];
};


export type CoreQueryGetDocumentChangeLogsArgs = {
  documentId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryGetOrganizationAttributesArgs = {
  organizationId: Scalars['ID'];
  propertyName: Scalars['String'];
};


export type CoreQueryGetOrganizationFileListArgs = {
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
};


export type CoreQueryGetOrganizationProtocolParticipantArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  protocolId: Scalars['String'];
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryGetPowerBiEmbedTokenArgs = {
  input: PowerBiEmbedTokenInput;
};


export type CoreQueryGetProjectDocumentsValidationArgs = {
  input: ProjectDocumentInput;
};


export type CoreQueryOrganizationDetailArgs = {
  orgName: Scalars['String'];
  protocolId: Scalars['String'];
};


export type CoreQueryOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  protocolId: Scalars['String'];
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryOrganizationsByProtocolArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  protocolId: Scalars['String'];
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryOrganizationsByProtocol__CountArgs = {
  protocolId: Scalars['String'];
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryOrganizationsByTypeArgs = {
  protocolId: Scalars['String'];
  type: Scalars['String'];
};


export type CoreQueryOrganizations__CountArgs = {
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryProjectDefinitionArgs = {
  protocolId: Scalars['ID'];
};


export type CoreQueryProjectDetailArgs = {
  projectId: Scalars['ID'];
  protocolId: Scalars['ID'];
};


export type CoreQueryProjectDocumentsArgs = {
  input: ProjectDocumentInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryProjectDocuments__CountArgs = {
  input: ProjectDocumentInput;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryProjectsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryProjectsByProtocolArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  protocolId: Scalars['ID'];
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryProjects__CountArgs = {
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryProtocolDetailSummaryArgs = {
  protocolId: Scalars['ID'];
};


export type CoreQueryProtocolsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryProtocolsDistinctByArgs = {
  propertyName: Scalars['String'];
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryRolesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryTemplateDetailArgs = {
  name: Scalars['String'];
};


export type CoreQueryTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryTemplatesDistinctByArgs = {
  propertyName: Scalars['String'];
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryTemplates__CountArgs = {
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryUsersByProtocolArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  protocolId: Scalars['ID'];
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryUsersByProtocol__CountArgs = {
  protocolId: Scalars['ID'];
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryUsersByRoleArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  roleNames: Array<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
};


export type CoreQueryUsers__CountArgs = {
  where?: InputMaybe<BinaryFilter>;
};

export type CoreSubscription = {
  __typename?: 'CoreSubscription';
  advisorAdded: AdvisorAdded;
  advisorUpdated: AdvisorUpdated;
  errors: EventError;
  organizationAdvisorPut: OrganizationAdvisorPut;
  organizationAttributesUpdated: OrganizationAttributesPut;
  organizationDeleted: OrganizationDeleted;
  organizationDeletedFromProtocol: OrganizationDeletedFromProtocol;
  organizationPut: OrganizationPut;
  projectAttributesPut: ProjectAttributesPut;
  projectDeleted: ProjectDeleted;
  protocolCMSKeyUpdated: ProtocolCmsKeyUpdated;
  protocolCMSNameUpdated: ProtocolCmsNameUpdated;
  protocolTemplateAdded: ProtocolTemplateAdded;
  protocolUserDeleted: ProtocolUserDeleted;
  protocolUserUpdated: ProtocolUserUpdated;
  roleAdded: RoleAdded;
  userAdded: UserAddedEvent;
  userAddedToProtocol: UsersAddedToProtocol;
};


export type CoreSubscriptionErrorsArgs = {
  correlationId: Scalars['ID'];
};


export type CoreSubscriptionProjectDeletedArgs = {
  correlationId: Scalars['ID'];
};


export type CoreSubscriptionProtocolUserDeletedArgs = {
  correlationId: Scalars['ID'];
};


export type CoreSubscriptionProtocolUserUpdatedArgs = {
  correlationId: Scalars['ID'];
};


export type CoreSubscriptionUserAddedToProtocolArgs = {
  correlationId: Scalars['ID'];
};

export type CountryDto = {
  __typename?: 'CountryDto';
  isoCode: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export type CountryInput = {
  isoCode: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export type CustomDataFilter = {
  column: Scalars['String'];
  operator: Scalars['String'];
  value: Scalars['String'];
};

export type DataAccessRequestByDate = {
  __typename?: 'DataAccessRequestByDate';
  active: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  propertyKey: Scalars['String'];
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

export type DeleteAdvisorInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DeleteAdvisorsInput = {
  advisors: Array<DeleteAdvisorInput>;
  delete: Scalars['Boolean'];
};

export type DeleteOrganizationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DeleteOrganizationsFromProtocolInput = {
  delete: Scalars['Boolean'];
  organizations: Array<DeleteOrganizationInput>;
  protocolId: Scalars['ID'];
};

export type DeleteProtocolUserInput = {
  protocolId: Scalars['ID'];
  usersToDelete: Array<DeleteProtocolUsersInput>;
};

export type DeleteProtocolUsersInput = {
  id: Scalars['ID'];
  userEmail: Scalars['String'];
};

export type DeniedUser = {
  request_email: Scalars['String'];
};

export type DisableUser = {
  user_email: Scalars['String'];
};

export type DisableUserResponse = {
  __typename?: 'DisableUserResponse';
  user_email: Scalars['String'];
};

export type DocumenFile = {
  __typename?: 'DocumenFile';
  date: Scalars['DateTime'];
  name: Scalars['String'];
  path: Scalars['String'];
  size?: Maybe<Scalars['Long']>;
  url: Scalars['String'];
};

export type DocumentChangeLog = {
  __typename?: 'DocumentChangeLog';
  blobIdentifier?: Maybe<Scalars['String']>;
  change: Scalars['String'];
  documentId: Scalars['ID'];
  id: Scalars['Int'];
  time: Scalars['DateTimeOffset'];
  user: Scalars['String'];
};

export type DocumentDefinition = {
  __typename?: 'DocumentDefinition';
  documentGroups: Array<DocumentGroup>;
  documentTypes: Array<DocumentTypeItem>;
  projectKey?: Maybe<Scalars['ID']>;
  protocolKey: Scalars['ID'];
};

export type DocumentDefinitionInput = {
  documentGroups: Array<DocumentGroupInput>;
  documentTypes: Array<DocumentTypeInput>;
  projectKey?: InputMaybe<Scalars['ID']>;
  protocolKey?: InputMaybe<Scalars['ID']>;
};

export type DocumentDto = {
  __typename?: 'DocumentDto';
  blobIdentifier: Scalars['String'];
  blobInfo?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  projectDocuments?: Maybe<Array<ProjectDocument>>;
  size: Scalars['Long'];
  type: DocumentTypeItem;
};

export type DocumentEdited = {
  __typename?: 'DocumentEdited';
  blobIdentifier: Scalars['String'];
  blobInfo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  oldBlobIdentifier?: Maybe<Scalars['String']>;
  size: Scalars['Long'];
  type: Scalars['ID'];
  typeName: Scalars['String'];
};

export type DocumentGroup = {
  __typename?: 'DocumentGroup';
  maxOptionalFileCount: Scalars['Int'];
  minOptionalFileCount: Scalars['Int'];
  name: Scalars['String'];
  typeReferences?: Maybe<Array<DocumentTypeReference>>;
};

export type DocumentGroupInput = {
  maxOptionalFileCount: Scalars['Int'];
  minOptionalFileCount: Scalars['Int'];
  name: Scalars['String'];
  typeReferences?: InputMaybe<Array<DocumentTypeReferenceInput>>;
};

export type DocumentResponse = {
  __typename?: 'DocumentResponse';
  files: Array<DocumenFile>;
};

export type DocumentSubmittedResponse = {
  __typename?: 'DocumentSubmittedResponse';
  blobIdentifier: Scalars['String'];
  blobInfo?: Maybe<Scalars['String']>;
  documentId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Long'];
  type: Scalars['ID'];
};

export type DocumentSubmittedToOrganizationResponse = {
  __typename?: 'DocumentSubmittedToOrganizationResponse';
  correlationId: Scalars['ID'];
  created: Array<DocumentSubmittedResponse>;
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  organizationId: Scalars['ID'];
  projectId: Scalars['ID'];
  protocolId: Scalars['ID'];
  updated: Array<DocumentSubmittedResponse>;
  userId: Scalars['ID'];
};

export type DocumentTypeInput = {
  allowedFileExtensions?: InputMaybe<Array<AllowedFileExtensionInput>>;
  key: Scalars['ID'];
  maxFileCount: Scalars['Int'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
};

export type DocumentTypeItem = {
  __typename?: 'DocumentTypeItem';
  allowedFileExtensions?: Maybe<Array<AllowedFileExtension>>;
  key: Scalars['ID'];
  maxFileCount: Scalars['Int'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
};

export type DocumentTypeReference = {
  __typename?: 'DocumentTypeReference';
  typeKey: Scalars['ID'];
};

export type DocumentTypeReferenceInput = {
  typeKey: Scalars['ID'];
};

export type DocumentValidationGroup = {
  __typename?: 'DocumentValidationGroup';
  documentValidations: Array<ValidDocumentDto>;
  isValid: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  minOptionalFileCount: Scalars['Int'];
  name: Scalars['String'];
};

export type DocumentsInput = {
  blobIdentifier: Scalars['String'];
  blobInfo: Scalars['String'];
  name: Scalars['String'];
  oldBlobIdentifier?: InputMaybe<Scalars['String']>;
  size: Scalars['Long'];
  type: Scalars['ID'];
};

export type EditDocumentInput = {
  document: DocumentsInput;
  organizationId: Scalars['ID'];
  projectId: Scalars['ID'];
  protocolId: Scalars['ID'];
  updatedDocumentId: Scalars['ID'];
};

export type EditProtocolInput = {
  definition?: InputMaybe<DocumentDefinitionInput>;
  factorValues: Array<ProtocolFactorValueInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EmailCallback = {
  __typename?: 'EmailCallback';
  message_id: Scalars['String'];
};

export type EventError = {
  __typename?: 'EventError';
  correlationId: Scalars['ID'];
  exceptions?: Maybe<Array<EventExceptionInfo>>;
  message: Scalars['String'];
};

export type EventExceptionData = {
  __typename?: 'EventExceptionData';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type EventExceptionInfo = {
  __typename?: 'EventExceptionInfo';
  data?: Maybe<Array<EventExceptionData>>;
  innerException?: Maybe<EventExceptionInfo>;
  message: Scalars['String'];
  stackTrace: Scalars['String'];
};

export type FactorDto = {
  __typename?: 'FactorDto';
  id: Scalars['Int'];
  name: Scalars['String'];
  scope: Scalars['Byte'];
  type: Scalars['String'];
};

export type FactorValue = {
  __typename?: 'FactorValue';
  factorName: Scalars['String'];
  factorSetValueId: Scalars['Int'];
  id: Scalars['Int'];
  value: Scalars['Decimal'];
};

export type FactorValueInput = {
  factorName: Scalars['String'];
  factorSetValueId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  value: Scalars['Decimal'];
};

export type FactorsInput = {
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  scope: Scalars['Byte'];
  type: Scalars['String'];
};

export type FactorsOutput = {
  __typename?: 'FactorsOutput';
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  scope: Scalars['Byte'];
  type: Scalars['String'];
};

export type InviteUserUi = {
  email: Scalars['String'];
  locale: Scalars['String'];
  product_name: Scalars['String'];
  role: Scalars['String'];
  userLogin: Scalars['String'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValueClass = {
  __typename?: 'KeyValueClass';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type LinkDocumentToOrganizationProjectsInput = {
  documentId: Scalars['ID'];
  organizationId: Scalars['ID'];
  projectIds: Array<Scalars['ID']>;
  protocolId: Scalars['ID'];
};

export type MapConfigOptions = {
  __typename?: 'MapConfigOptions';
  layers: Array<MapLayer>;
};

export type MapLayer = {
  __typename?: 'MapLayer';
  maxZoom: Scalars['Int'];
  minZoom: Scalars['Int'];
  name: Scalars['String'];
  templateUrl: Scalars['String'];
  type: Scalars['String'];
};

export type Me = {
  __typename?: 'Me';
  globalRoles?: Maybe<Array<Scalars['String']>>;
  status: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  city?: Maybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  createdBy: Scalars['ID'];
  createdOn: Scalars['DateTimeOffset'];
  deleted: Scalars['Boolean'];
  deletedBy?: Maybe<Scalars['ID']>;
  deletedOn?: Maybe<Scalars['DateTimeOffset']>;
  email: Scalars['String'];
  externalSourceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationAdvisor?: Maybe<Array<OrganizationAdvisor>>;
  organizationCountry: OrganizationCountry;
  organizationParticipantFactorSetValues?: Maybe<Array<OrganizationParticipantFactorSet>>;
  phone: Scalars['String'];
  postalZipCode?: Maybe<Scalars['String']>;
  protocolId: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  type: Scalars['String'];
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTimeOffset']>;
};

export type OrganizationAdvisor = {
  __typename?: 'OrganizationAdvisor';
  advisorId: Scalars['ID'];
  id: Scalars['Int'];
  organizationId: Scalars['ID'];
};

export type OrganizationAdvisorOutput = {
  __typename?: 'OrganizationAdvisorOutput';
  advisorOutput?: Maybe<Array<AdvisorOutput>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OrganizationAdvisorPut = {
  __typename?: 'OrganizationAdvisorPut';
  advisorId: Scalars['ID'];
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type OrganizationApiConnectionsInput = {
  apiConnectionId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
};

export type OrganizationApiConnectionsOutput = {
  __typename?: 'OrganizationApiConnectionsOutput';
  apiConnectionId: Scalars['ID'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  internalId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type OrganizationAttributes = {
  __typename?: 'OrganizationAttributes';
  id: Scalars['Int'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  value: Scalars['String'];
};

export type OrganizationAttributesPut = {
  __typename?: 'OrganizationAttributesPut';
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
  value: Scalars['String'];
};

export type OrganizationCountry = {
  __typename?: 'OrganizationCountry';
  isoCode: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export type OrganizationDeleted = {
  __typename?: 'OrganizationDeleted';
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  delete: Scalars['Boolean'];
  id: Scalars['Int'];
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type OrganizationDeletedFromProtocol = {
  __typename?: 'OrganizationDeletedFromProtocol';
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  delete: Scalars['Boolean'];
  organizations: Array<Scalars['ID']>;
  protocolId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type OrganizationDetail = {
  __typename?: 'OrganizationDetail';
  address: Scalars['String'];
  advisorNames?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  email: Scalars['String'];
  externalSourceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationCountry: OrganizationCountry;
  phone: Scalars['String'];
  postalZipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  type: Scalars['String'];
};

export type OrganizationDocumentEditedResponse = {
  __typename?: 'OrganizationDocumentEditedResponse';
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  document: DocumentEdited;
  organizationId: Scalars['ID'];
  projectId: Scalars['ID'];
  protocolId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type OrganizationInput = {
  city?: InputMaybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  countryInput: CountryInput;
  email: Scalars['String'];
  externalSourceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  postalZipCode?: InputMaybe<Scalars['String']>;
  protocolId: Scalars['ID'];
  state?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
  type: Scalars['String'];
};

export type OrganizationParticipantFactorSet = {
  __typename?: 'OrganizationParticipantFactorSet';
  factorValues: Array<FactorValue>;
  id: Scalars['Int'];
  key1: Scalars['String'];
  key2?: Maybe<Scalars['String']>;
  key3?: Maybe<Scalars['String']>;
  key4?: Maybe<Scalars['String']>;
  key5?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
};

export type OrganizationParticipantFactorSetValueInput = {
  factorValues: Array<FactorValueInput>;
  id?: InputMaybe<Scalars['Int']>;
  key1: Scalars['String'];
  key2?: InputMaybe<Scalars['String']>;
  key3?: InputMaybe<Scalars['String']>;
  key4?: InputMaybe<Scalars['String']>;
  key5?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
};

export type OrganizationProtocolParticipantDto = {
  __typename?: 'OrganizationProtocolParticipantDto';
  factors?: Maybe<Array<FactorDto>>;
  organizationId: Scalars['ID'];
  organizationParticipantFactorSetValues: Array<OrganizationParticipantFactorSet>;
  protocolId: Scalars['ID'];
};

export type OrganizationProtocolParticipantInput = {
  organizationId: Scalars['ID'];
  organizationParticipantFactorSetValues: Array<OrganizationParticipantFactorSetValueInput>;
  protocolId: Scalars['ID'];
};

export type OrganizationPut = {
  __typename?: 'OrganizationPut';
  city?: Maybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  correlationId: Scalars['ID'];
  country: CountryDto;
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  email: Scalars['String'];
  externalSourceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalZipCode?: Maybe<Scalars['String']>;
  protocolId: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  type: Scalars['String'];
  userId: Scalars['ID'];
};

export type PowerBiEmbedToken = {
  __typename?: 'PowerBiEmbedToken';
  embedToken: Scalars['String'];
  embedUrl: Scalars['String'];
  expiration: Scalars['DateTime'];
};

export type PowerBiEmbedTokenInput = {
  protocolId: Scalars['ID'];
  reportId: Scalars['ID'];
  withIdentity: Scalars['Boolean'];
  workspaceId: Scalars['ID'];
};

export type Project = {
  __typename?: 'Project';
  createdBy: Scalars['ID'];
  createdOn: Scalars['DateTimeOffset'];
  deleted: Scalars['Boolean'];
  deletedBy?: Maybe<Scalars['ID']>;
  deletedOn?: Maybe<Scalars['DateTimeOffset']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  periodEnd: Scalars['DateTimeOffset'];
  periodStart: Scalars['DateTimeOffset'];
  projectAttributes?: Maybe<Array<ProjectAttributesOutput>>;
  projectFactorValue?: Maybe<Array<ProjectFactorValueOutput>>;
  protocolId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  tC02Price: Scalars['Decimal'];
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTimeOffset']>;
};

export type ProjectAttributesInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ProjectAttributesOutput = {
  __typename?: 'ProjectAttributesOutput';
  id: Scalars['Int'];
  name: Scalars['String'];
  projectId: Scalars['ID'];
  value: Scalars['String'];
};

export type ProjectAttributesPut = {
  __typename?: 'ProjectAttributesPut';
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  name: Scalars['String'];
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
  value: Scalars['String'];
};

export type ProjectDeleted = {
  __typename?: 'ProjectDeleted';
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  name: Scalars['String'];
  projectIds: Array<BeefProjectInput>;
  userId: Scalars['ID'];
};

export type ProjectDocument = {
  __typename?: 'ProjectDocument';
  blobIdentifier: Scalars['String'];
  documentId: Scalars['ID'];
  fileName: Scalars['String'];
  id: Scalars['Int'];
  lastUpdated: Scalars['DateTimeOffset'];
  name: Scalars['String'];
  projectId: Scalars['ID'];
  projectsCount?: Maybe<Scalars['Int']>;
  size: Scalars['Long'];
  status: ProjectDocumentStatus;
  type: Scalars['String'];
};

export type ProjectDocumentInput = {
  organizationId: Scalars['ID'];
  projectId: Scalars['ID'];
  protocolId: Scalars['ID'];
};

export enum ProjectDocumentStatus {
  Uploaded = 'UPLOADED',
  Verified = 'VERIFIED'
}

export type ProjectFactorValueInput = {
  factorName: Scalars['String'];
  projectId?: InputMaybe<Scalars['ID']>;
  value: Scalars['Decimal'];
};

export type ProjectFactorValueOutput = {
  __typename?: 'ProjectFactorValueOutput';
  factorName: Scalars['String'];
  projectId: Scalars['ID'];
  value: Scalars['Decimal'];
};

export type ProjectProtocolFactorDefinition = {
  __typename?: 'ProjectProtocolFactorDefinition';
  factorDefinitions?: Maybe<Array<FactorsOutput>>;
  protocolName: Scalars['String'];
};

export type ProjectStatusOutput = {
  __typename?: 'ProjectStatusOutput';
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type Protocol = {
  __typename?: 'Protocol';
  cMSContentRootKey?: Maybe<Scalars['ID']>;
  createdBy: Scalars['ID'];
  createdOn: Scalars['DateTimeOffset'];
  deleted: Scalars['Boolean'];
  deletedBy?: Maybe<Scalars['ID']>;
  deletedOn?: Maybe<Scalars['DateTimeOffset']>;
  documentDefinition?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  owners: Array<Scalars['String']>;
  project?: Maybe<Array<Project>>;
  protocolFactorValue?: Maybe<Array<ProtocolFactorValue>>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTimeOffset']>;
};

export type ProtocolAdminUser = {
  __typename?: 'ProtocolAdminUser';
  email: Scalars['String'];
  id: Scalars['ID'];
};

export type ProtocolCmsKeyUpdated = {
  __typename?: 'ProtocolCMSKeyUpdated';
  cMSContentRootKey: Scalars['ID'];
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  userId: Scalars['ID'];
};

export type ProtocolCmsNameUpdated = {
  __typename?: 'ProtocolCMSNameUpdated';
  cMSContentRootKey: Scalars['ID'];
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  userId: Scalars['ID'];
};

export type ProtocolDto = {
  __typename?: 'ProtocolDTO';
  admins?: Maybe<Array<ProtocolAdminUser>>;
  cMSContentRootKey?: Maybe<Scalars['ID']>;
  createdBy: Scalars['ID'];
  createdOn: Scalars['DateTimeOffset'];
  deleted: Scalars['Boolean'];
  deletedBy?: Maybe<Scalars['ID']>;
  deletedOn?: Maybe<Scalars['DateTimeOffset']>;
  documentDefinition?: Maybe<DocumentDefinition>;
  id: Scalars['ID'];
  name: Scalars['String'];
  projects?: Maybe<Array<Project>>;
  projects_count?: Maybe<Scalars['Int']>;
  protocolFactorValue?: Maybe<Array<ProtocolFactorValue>>;
  protocolTemplateId: Scalars['ID'];
  templateName?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTimeOffset']>;
};

export type ProtocolFactorDefinition = {
  __typename?: 'ProtocolFactorDefinition';
  name: Scalars['String'];
  scope: Scalars['Byte'];
  type: Scalars['String'];
};

export type ProtocolFactorValue = {
  __typename?: 'ProtocolFactorValue';
  name: Scalars['String'];
  value: Scalars['Decimal'];
};

export type ProtocolFactorValueInput = {
  name: Scalars['String'];
  value: Scalars['Decimal'];
};

export type ProtocolOrganization = {
  __typename?: 'ProtocolOrganization';
  city?: Maybe<Scalars['String']>;
  contactPerson: Scalars['String'];
  createdBy: Scalars['ID'];
  createdOn: Scalars['DateTimeOffset'];
  deleted: Scalars['Boolean'];
  deletedBy?: Maybe<Scalars['ID']>;
  deletedOn?: Maybe<Scalars['DateTimeOffset']>;
  email: Scalars['String'];
  externalSourceId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationAdvisor?: Maybe<Array<OrganizationAdvisor>>;
  organizationAttributes: Array<OrganizationAttributes>;
  organizationCountry: OrganizationCountry;
  organizationParticipantFactorSetValues?: Maybe<Array<OrganizationParticipantFactorSet>>;
  organizationUsers?: Maybe<Array<UserDto>>;
  phone: Scalars['String'];
  postalZipCode?: Maybe<Scalars['String']>;
  protocolId: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  type: Scalars['String'];
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTimeOffset']>;
};

export type ProtocolReponse = {
  __typename?: 'ProtocolReponse';
  protocols: Array<ProtocolDto>;
  protocols__count: Scalars['Int'];
};

export type ProtocolSummary = {
  __typename?: 'ProtocolSummary';
  admins: Array<Scalars['String']>;
  factors: Array<ProtocolFactorDefinition>;
  name: Scalars['String'];
};

export type ProtocolTemplate = {
  __typename?: 'ProtocolTemplate';
  createdBy: Scalars['String'];
  createdOn: Scalars['DateTimeOffset'];
  factors?: Maybe<Array<FactorsOutput>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedBy?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['DateTimeOffset']>;
};

export type ProtocolTemplateAdded = {
  __typename?: 'ProtocolTemplateAdded';
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  protocolTemplateAttribute: Array<ProtocolTemplateAttribute>;
  protocolTemplateFactor: Array<ProtocolTemplateFactor>;
  userId: Scalars['ID'];
};

export type ProtocolTemplateAttribute = {
  __typename?: 'ProtocolTemplateAttribute';
  id: Scalars['Int'];
  name: Scalars['String'];
  templateId: Scalars['ID'];
  value: Scalars['String'];
};

export type ProtocolTemplateFactor = {
  __typename?: 'ProtocolTemplateFactor';
  id: Scalars['Int'];
  name: Scalars['String'];
  scope: Scalars['Byte'];
  type: Scalars['String'];
};

export type ProtocolTemplateInput = {
  factors: Array<FactorsInput>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type ProtocolUserDeleted = {
  __typename?: 'ProtocolUserDeleted';
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  protocolId: Scalars['ID'];
  userId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export type ProtocolUserUpdated = {
  __typename?: 'ProtocolUserUpdated';
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  protocolId: Scalars['ID'];
  protocolUserId: Scalars['ID'];
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type PutOrganizationAdvisorInput = {
  advisorId: Scalars['ID'];
  organizationId: Scalars['ID'];
  organizationName: Scalars['String'];
  protocolId: Scalars['ID'];
};

export type PutOrganizationAttributesInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  protocolId: Scalars['ID'];
  value: Scalars['String'];
};

export type RequestUserUi = {
  organization?: InputMaybe<Scalars['String']>;
  request_email: Scalars['String'];
  sentTo: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  createdBy: Scalars['ID'];
  createdOn: Scalars['DateTimeOffset'];
  deleted: Scalars['Boolean'];
  deletedBy?: Maybe<Scalars['ID']>;
  deletedOn?: Maybe<Scalars['DateTimeOffset']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  roleAccessPolicies?: Maybe<Array<RoleAccessPolicies>>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTimeOffset']>;
  useMfa: Scalars['Boolean'];
};

export type RoleAccessPolicies = {
  __typename?: 'RoleAccessPolicies';
  actionName: Scalars['String'];
  id: Scalars['Int'];
  roleId: Scalars['ID'];
  subjectKey?: Maybe<Scalars['String']>;
  subjectName: Scalars['String'];
};

export type RoleAdded = {
  __typename?: 'RoleAdded';
  accessPolicies: Array<AddRoleAccessPolicy>;
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  name: Scalars['String'];
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type SetProtocolDocumentDefinitionInput = {
  definition: DocumentDefinitionInput;
};

export type SubmitDocumentInput = {
  documents: Array<DocumentsInput>;
  organizationId: Scalars['ID'];
  projectId: Scalars['ID'];
  protocolId: Scalars['ID'];
};

export type TemplatedMessage = {
  __typename?: 'TemplatedMessage';
  parameters?: Maybe<Array<Maybe<KeyValue>>>;
  templateId: Scalars['String'];
};

export type UpdateProjectStatusInput = {
  name: Scalars['String'];
  projectId: Scalars['ID'];
  protocolId: Scalars['ID'];
  status: Scalars['String'];
};

export type UpdateProtocolUserInput = {
  protocolId: Scalars['ID'];
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UserAddedEvent = {
  __typename?: 'UserAddedEvent';
  addedUserId: Scalars['ID'];
  attributes?: Maybe<Array<KeyValueClass>>;
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  preferredLanguage?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tidSubject?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
  userName: Scalars['String'];
};

export type UserAttributeDto = {
  __typename?: 'UserAttributeDto';
  createdBy: Scalars['ID'];
  createdOn: Scalars['DateTimeOffset'];
  deleted: Scalars['Boolean'];
  deletedBy?: Maybe<Scalars['ID']>;
  deletedOn?: Maybe<Scalars['DateTimeOffset']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type UserDto = {
  __typename?: 'UserDto';
  attributes?: Maybe<Array<UserAttributeDto>>;
  createdBy: Scalars['ID'];
  createdOn: Scalars['DateTimeOffset'];
  deleted: Scalars['Boolean'];
  deletedBy?: Maybe<Scalars['ID']>;
  deletedOn?: Maybe<Scalars['DateTimeOffset']>;
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  preferredLanguage?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
  tidSubject?: Maybe<Scalars['ID']>;
  updatedBy?: Maybe<Scalars['ID']>;
  updatedOn?: Maybe<Scalars['DateTimeOffset']>;
  userName: Scalars['String'];
};

export type UserEmailRoleInput = {
  email: Scalars['String'];
  role: Scalars['String'];
};

export type UserIdWithRoleId = {
  __typename?: 'UserIdWithRoleId';
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UserInvitationCallback = {
  __typename?: 'UserInvitationCallback';
  invitation_id: Scalars['String'];
};

export type Users = {
  __typename?: 'Users';
  action: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  requestDate: Scalars['DateTimeOffset'];
  requestDateFormat: Scalars['String'];
  role: Scalars['String'];
  status: Scalars['String'];
  template?: Maybe<TemplatedMessage>;
  time: Scalars['String'];
  userName: Scalars['String'];
};

export type UsersAddedToProtocol = {
  __typename?: 'UsersAddedToProtocol';
  correlationId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTimeOffset']>;
  protocolId: Scalars['ID'];
  userId: Scalars['ID'];
  userRoles: Array<UserIdWithRoleId>;
};

export type ValidDocumentDto = {
  __typename?: 'ValidDocumentDto';
  name: Scalars['String'];
  status: Scalars['String'];
};

export type ValidDocumentsDto = {
  __typename?: 'ValidDocumentsDto';
  isValid: Scalars['Boolean'];
  organizationId: Scalars['ID'];
  projectId: Scalars['ID'];
  protocolId: Scalars['ID'];
  validationGroups: Array<DocumentValidationGroup>;
};

export type SendUserInvitationEmailMutationVariables = Exact<{
  input: InviteUserUi;
}>;


export type SendUserInvitationEmailMutation = { __typename?: 'CoreMutation', sendUserInvitationEmail: { __typename?: 'UserInvitationCallback', invitation_id: string } };

export type AccessRequestEmailMutationVariables = Exact<{
  input: RequestUserUi;
}>;


export type AccessRequestEmailMutation = { __typename?: 'CoreMutation', accessRequestEmail: { __typename?: 'EmailCallback', message_id: string } };

export type PutOrganizationMutationVariables = Exact<{
  input: OrganizationInput;
}>;


export type PutOrganizationMutation = { __typename?: 'CoreMutation', putOrganization?: { __typename?: 'Organization', id: string, name: string } | null };

export type RejectAccesRequestMutationVariables = Exact<{
  input: DeniedUser;
}>;


export type RejectAccesRequestMutation = { __typename?: 'CoreMutation', rejectAccesRequest: { __typename?: 'EmailCallback', message_id: string } };

export type DisableUserMutationVariables = Exact<{
  input: DisableUser;
}>;


export type DisableUserMutation = { __typename?: 'CoreMutation', disableUser: { __typename?: 'DisableUserResponse', user_email: string } };

export type AddNewProtocolTemplateMutationVariables = Exact<{
  input: ProtocolTemplateInput;
}>;


export type AddNewProtocolTemplateMutation = { __typename?: 'CoreMutation', addNewProtocolTemplate: { __typename?: 'ProtocolTemplate', name: string } };

export type EditProtocolTemplateMutationVariables = Exact<{
  input: ProtocolTemplateInput;
}>;


export type EditProtocolTemplateMutation = { __typename?: 'CoreMutation', editProtocolTemplate: { __typename?: 'ProtocolTemplate', name: string } };

export type AddNewProtocolMutationVariables = Exact<{
  input: AddProtocolInput;
}>;


export type AddNewProtocolMutation = { __typename?: 'CoreMutation', addNewProtocol: { __typename?: 'Protocol', id: string, name: string, cMSContentRootKey?: string | null } };

export type UpdateOrganizationProtocolParticipantSetFactorValuesMutationVariables = Exact<{
  input: OrganizationProtocolParticipantInput;
}>;


export type UpdateOrganizationProtocolParticipantSetFactorValuesMutation = { __typename?: 'CoreMutation', updateOrganizationProtocolParticipantSetFactorValues: { __typename?: 'OrganizationProtocolParticipantDto', protocolId: string, organizationId: string, factors?: Array<{ __typename?: 'FactorDto', id: number, name: string, scope: any, type: string }> | null, organizationParticipantFactorSetValues: Array<{ __typename?: 'OrganizationParticipantFactorSet', id: number, key1: string, key2?: string | null, key3?: string | null, key4?: string | null, key5?: string | null, factorValues: Array<{ __typename?: 'FactorValue', id: number, factorSetValueId: number, factorName: string, value: any }> }> } };

export type AddNewProjectMutationVariables = Exact<{
  input: AddProjectInput;
}>;


export type AddNewProjectMutation = { __typename?: 'CoreMutation', addNewProject: { __typename?: 'Project', id: string, name: string, status?: string | null, createdOn: any, createdBy: string, updatedOn?: any | null, updatedBy?: string | null, deleted: boolean, deletedOn?: any | null, deletedBy?: string | null, protocolId: string } };

export type UpdateProjectMutationVariables = Exact<{
  input: AddProjectInput;
}>;


export type UpdateProjectMutation = { __typename?: 'CoreMutation', updateProject: { __typename?: 'Project', id: string, name: string, periodStart: any, periodEnd: any, status?: string | null, protocolId: string, updatedOn?: any | null, updatedBy?: string | null, createdOn: any, createdBy: string, deleted: boolean, deletedOn?: any | null, deletedBy?: string | null, projectFactorValue?: Array<{ __typename?: 'ProjectFactorValueOutput', projectId: string, factorName: string, value: any }> | null } };

export type UpdateProjectStatusMutationVariables = Exact<{
  input: UpdateProjectStatusInput;
}>;


export type UpdateProjectStatusMutation = { __typename?: 'CoreMutation', updateProjectStatus: { __typename?: 'ProjectStatusOutput', status: string, id: string } };

export type DeleteOrganizationFromProtocolMutationVariables = Exact<{
  input: DeleteOrganizationsFromProtocolInput;
}>;


export type DeleteOrganizationFromProtocolMutation = { __typename?: 'CoreMutation', deleteOrganizationFromProtocol: boolean };

export type AddUsersToProtocolMutationVariables = Exact<{
  input: AddUsersToProtocolInput;
}>;


export type AddUsersToProtocolMutation = { __typename?: 'CoreMutation', addUsersToProtocol: { __typename?: 'CommandResult', correlationId: string } };

export type UpdateProtocolUserMutationVariables = Exact<{
  input: UpdateProtocolUserInput;
}>;


export type UpdateProtocolUserMutation = { __typename?: 'CoreMutation', updateProtocolUser: { __typename?: 'CommandResult', correlationId: string } };

export type DeleteProtocolUserMutationVariables = Exact<{
  input: DeleteProtocolUserInput;
}>;


export type DeleteProtocolUserMutation = { __typename?: 'CoreMutation', deleteProtocolUser: { __typename?: 'CommandResult', correlationId: string } };

export type PutOrganizationAttributesMutationVariables = Exact<{
  input: PutOrganizationAttributesInput;
}>;


export type PutOrganizationAttributesMutation = { __typename?: 'CoreMutation', putOrganizationAttributes: { __typename?: 'CommandResult', correlationId: string } };

export type AddOrganizationToUserMutationVariables = Exact<{
  input: AddOrganizationtoUserInput;
}>;


export type AddOrganizationToUserMutation = { __typename?: 'CoreMutation', addOrganizationToUser: { __typename?: 'AddOrganizationtoUser', organizationId: string } };

export type AcceptUserMutationVariables = Exact<{
  input: AcceptUserInput;
}>;


export type AcceptUserMutation = { __typename?: 'CoreMutation', acceptUser: { __typename?: 'AcceptUsers', email: Array<string> } };

export type PutAdvisorMutationVariables = Exact<{
  input: AdvisorInput;
}>;


export type PutAdvisorMutation = { __typename?: 'CoreMutation', putAdvisor?: { __typename?: 'Advisor', id: string, name: string, street: string, city?: string | null, state?: string | null, postalZipCode?: string | null, phone: string, contactPerson: string, email: string, createdOn: any, createdBy: string, updatedOn?: any | null, updatedBy?: string | null, deleted: boolean, deletedOn?: any | null, deletedBy?: string | null, advisorCountry: { __typename?: 'AdvisorCountryDto', name: string, isoCode: string, region: string } } | null };

export type EditProtocolMutationVariables = Exact<{
  input: EditProtocolInput;
}>;


export type EditProtocolMutation = { __typename?: 'CoreMutation', editProtocol: { __typename?: 'Protocol', id: string, name: string, cMSContentRootKey?: string | null, createdOn: any, createdBy: string } };

export type PutApiConnectionMutationVariables = Exact<{
  input: ApiConnectionsInput;
}>;


export type PutApiConnectionMutation = { __typename?: 'CoreMutation', putApiConnection?: { __typename?: 'ApiConnection', id: string, protocolId: string, clientId: string, clientName: string, applicationName: string, lastEditedOn?: any | null, lastEditedBy?: string | null, organizations_count?: number | null } | null };

export type DeleteAdvisorMutationVariables = Exact<{
  input: DeleteAdvisorsInput;
}>;


export type DeleteAdvisorMutation = { __typename?: 'CoreMutation', deleteAdvisor: boolean };

export type SubmitDocumentMutationVariables = Exact<{
  input: SubmitDocumentInput;
}>;


export type SubmitDocumentMutation = { __typename?: 'CoreMutation', submitDocument?: { __typename?: 'DocumentSubmittedToOrganizationResponse', created: Array<{ __typename?: 'DocumentSubmittedResponse', name: string, blobInfo?: string | null, blobIdentifier: string, documentId: string }>, updated: Array<{ __typename?: 'DocumentSubmittedResponse', name: string, blobInfo?: string | null, blobIdentifier: string, documentId: string }> } | null };

export type LinkDocumentToOrganizationProjectsMutationVariables = Exact<{
  input: LinkDocumentToOrganizationProjectsInput;
}>;


export type LinkDocumentToOrganizationProjectsMutation = { __typename?: 'CoreMutation', linkDocumentToOrganizationProjects: string };

export type EditDocumentMutationVariables = Exact<{
  input: EditDocumentInput;
}>;


export type EditDocumentMutation = { __typename?: 'CoreMutation', editDocument?: { __typename?: 'OrganizationDocumentEditedResponse', protocolId: string, organizationId: string, projectId: string, userId: string, correlationId: string, createdAt?: any | null, document: { __typename?: 'DocumentEdited', blobIdentifier: string, blobInfo?: string | null, name: string, type: string, typeName: string, size: any } } | null };

export type GetConfigurationQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetConfigurationQuery = { __typename?: 'CoreQuery', getConfiguration?: string | null };

export type GetMapLayersConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMapLayersConfigurationQuery = { __typename?: 'CoreQuery', mapConfigOptions: { __typename?: 'MapConfigOptions', layers: Array<{ __typename?: 'MapLayer', maxZoom: number, minZoom: number, name: string, templateUrl: string, type: string }> } };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'CoreQuery', roles: Array<{ __typename?: 'Role', id: string, name: string }> };

export type UsersRequestingByDateQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersRequestingByDateQuery = { __typename?: 'CoreQuery', usersRequestingByDate: Array<{ __typename?: 'AccessRequestByDate', title: any, data: Array<{ __typename?: 'DataAccessRequestByDate', id: string, propertyKey: string, userName: string, userEmail: string, description: string, active: string }> }> };

export type UsersRequestingAccessQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type UsersRequestingAccessQuery = { __typename?: 'CoreQuery', users__count: number, users: Array<{ __typename?: 'Users', id: string, requestDate: any, requestDateFormat: string, userName: string, email: string, role: string, time: string, status: string, action: string, template?: { __typename?: 'TemplatedMessage', templateId: string, parameters?: Array<{ __typename?: 'KeyValue', key: string, value: string } | null> | null } | null }> };

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type UsersQuery = { __typename?: 'CoreQuery', users__count: number, users: Array<{ __typename?: 'Users', id: string, userName: string, email: string, status: string, action: string, template?: { __typename?: 'TemplatedMessage', templateId: string, parameters?: Array<{ __typename?: 'KeyValue', key: string, value: string } | null> | null } | null }> };

export type UsersByProtocolQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  protocolId: Scalars['ID'];
}>;


export type UsersByProtocolQuery = { __typename?: 'CoreQuery', usersByProtocol__Count: number, usersByProtocol: Array<{ __typename?: 'Users', id: string, userName: string, email: string, status: string, action: string, role: string, template?: { __typename?: 'TemplatedMessage', templateId: string, parameters?: Array<{ __typename?: 'KeyValue', key: string, value: string } | null> | null } | null }> };

export type OrganizationsQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  protocolId: Scalars['String'];
}>;


export type OrganizationsQuery = { __typename?: 'CoreQuery', organizations__count: number, organizations: Array<{ __typename?: 'OrganizationDetail', id: string, name: string, address: string, phone: string, contactPerson: string, email: string, externalSourceId?: string | null, postalZipCode?: string | null, state?: string | null, street: string, type: string, city?: string | null, advisorNames?: Array<string> | null, organizationCountry: { __typename?: 'OrganizationCountry', isoCode: string, name: string, region: string } }> };

export type OrganizationsNameAndIdQueryVariables = Exact<{
  protocolId: Scalars['String'];
}>;


export type OrganizationsNameAndIdQuery = { __typename?: 'CoreQuery', organizations: Array<{ __typename?: 'OrganizationDetail', id: string, name: string, externalSourceId?: string | null, address: string, street: string, city?: string | null, state?: string | null, postalZipCode?: string | null, phone: string, contactPerson: string, email: string }> };

export type ProjectsNameAndIdQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsNameAndIdQuery = { __typename?: 'CoreQuery', projects: Array<{ __typename?: 'Project', id: string, name: string }> };

export type ProjectsQueryVariables = Exact<{
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BinaryFilter>;
}>;


export type ProjectsQuery = { __typename?: 'CoreQuery', projects__count: number, projects: Array<{ __typename?: 'Project', id: string, name: string, status?: string | null }> };

export type ProtocolsQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ProtocolsQuery = { __typename?: 'CoreQuery', protocolsDistinctBy: Array<string>, protocols: { __typename?: 'ProtocolReponse', protocols__count: number, protocols: Array<{ __typename?: 'ProtocolDTO', id: string, name: string, cMSContentRootKey?: string | null, protocolTemplateId: string, templateName?: string | null, projects_count?: number | null, createdOn: any, createdBy: string, updatedOn?: any | null, updatedBy?: string | null, deletedOn?: any | null, deletedBy?: string | null, deleted: boolean, admins?: Array<{ __typename?: 'ProtocolAdminUser', id: string, email: string }> | null, protocolFactorValue?: Array<{ __typename?: 'ProtocolFactorValue', name: string, value: any }> | null, documentDefinition?: { __typename?: 'DocumentDefinition', protocolKey: string, projectKey?: string | null, documentTypes: Array<{ __typename?: 'DocumentTypeItem', key: string, name: string, maxFileCount: number, required: boolean, allowedFileExtensions?: Array<{ __typename?: 'AllowedFileExtension', extension: string }> | null }>, documentGroups: Array<{ __typename?: 'DocumentGroup', name: string, minOptionalFileCount: number, maxOptionalFileCount: number, typeReferences?: Array<{ __typename?: 'DocumentTypeReference', typeKey: string }> | null }> } | null }> } };

export type TemplatesQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type TemplatesQuery = { __typename?: 'CoreQuery', templates__count: number, createdByList: Array<string | null>, templates: Array<{ __typename: 'ProtocolTemplate', name: string, id: string, createdOn: any, createdBy: string, updatedOn?: any | null, updatedBy?: string | null, factors?: Array<{ __typename: 'FactorsOutput', id?: number | null, name: string, scope: any, type: string }> | null }> };

export type UsersByRoleQueryVariables = Exact<{
  roleNames: Array<Scalars['String']> | Scalars['String'];
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BinaryFilter>;
}>;


export type UsersByRoleQuery = { __typename?: 'CoreQuery', usersByRole: Array<{ __typename?: 'UserDto', id: string, email: string, roles?: Array<string> | null }> };

export type TemplateDetailQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type TemplateDetailQuery = { __typename?: 'CoreQuery', templateDetail: Array<{ __typename?: 'ProtocolTemplate', id: string, name: string, factors?: Array<{ __typename?: 'FactorsOutput', id?: number | null, name: string, scope: any, type: string }> | null }> };

export type ProtocolDetailSummaryQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type ProtocolDetailSummaryQuery = { __typename?: 'CoreQuery', protocolDetailSummary: { __typename?: 'ProtocolSummary', name: string, admins: Array<string>, factors: Array<{ __typename?: 'ProtocolFactorDefinition', name: string, scope: any, type: string }> } };

export type InvitationQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type InvitationQueryQuery = { __typename?: 'CoreQuery', roles: Array<{ __typename?: 'Role', id: string, name: string }>, me: { __typename?: 'Me', globalRoles?: Array<string> | null, status: string } };

export type OrganizationDetailQueryVariables = Exact<{
  orgName: Scalars['String'];
  protocolId: Scalars['String'];
}>;


export type OrganizationDetailQuery = { __typename?: 'CoreQuery', organizationDetail: { __typename?: 'OrganizationDetail', id: string, name: string, externalSourceId?: string | null, street: string, city?: string | null, address: string, state?: string | null, postalZipCode?: string | null, phone: string, contactPerson: string, email: string, advisorNames?: Array<string> | null, type: string, organizationCountry: { __typename?: 'OrganizationCountry', name: string, isoCode: string, region: string } } };

export type GetOrganizationProtocolParticipantQueryVariables = Exact<{
  protocolId: Scalars['String'];
  organizationId: Scalars['String'];
}>;


export type GetOrganizationProtocolParticipantQuery = { __typename?: 'CoreQuery', getOrganizationProtocolParticipant: Array<{ __typename?: 'OrganizationProtocolParticipantDto', protocolId: string, organizationId: string, factors?: Array<{ __typename?: 'FactorDto', id: number, name: string, scope: any, type: string }> | null, organizationParticipantFactorSetValues: Array<{ __typename?: 'OrganizationParticipantFactorSet', id: number, key1: string, key2?: string | null, key3?: string | null, key4?: string | null, key5?: string | null, factorValues: Array<{ __typename?: 'FactorValue', id: number, factorSetValueId: number, factorName: string, value: any }> }> }> };

export type GetProjectsQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetProjectsQuery = { __typename?: 'CoreQuery', projects: Array<{ __typename?: 'Project', id: string, name: string, status?: string | null, createdOn: any, createdBy: string, updatedOn?: any | null, updatedBy?: string | null, deleted: boolean, deletedOn?: any | null, deletedBy?: string | null, protocolId: string, tC02Price: any, periodEnd: any, periodStart: any, projectFactorValue?: Array<{ __typename?: 'ProjectFactorValueOutput', factorName: string, value: any, projectId: string }> | null }> };

export type ProjectDefinitionQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type ProjectDefinitionQuery = { __typename?: 'CoreQuery', projectDefinition: { __typename?: 'ProjectProtocolFactorDefinition', protocolName: string, factorDefinitions?: Array<{ __typename?: 'FactorsOutput', id?: number | null, name: string, scope: any, type: string }> | null } };

export type ProjectDetailQueryVariables = Exact<{
  protocolId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type ProjectDetailQuery = { __typename?: 'CoreQuery', projectDetail: { __typename?: 'Project', id: string, name: string, periodStart: any, periodEnd: any, status?: string | null, protocolId: string, tC02Price: any, updatedOn?: any | null, updatedBy?: string | null, createdOn: any, createdBy: string, deleted: boolean, deletedOn?: any | null, deletedBy?: string | null, projectFactorValue?: Array<{ __typename?: 'ProjectFactorValueOutput', projectId: string, factorName: string, value: any }> | null, projectAttributes?: Array<{ __typename?: 'ProjectAttributesOutput', id: number, projectId: string, name: string, value: string }> | null } };

export type ProjectsByProtocolQueryVariables = Exact<{
  protocolId: Scalars['ID'];
}>;


export type ProjectsByProtocolQuery = { __typename?: 'CoreQuery', projectsByProtocol: Array<{ __typename?: 'Project', name: string, id: string, periodStart: any }> };

export type GetPowerBiEmbedTokenQueryVariables = Exact<{
  input: PowerBiEmbedTokenInput;
}>;


export type GetPowerBiEmbedTokenQuery = { __typename?: 'CoreQuery', getPowerBIEmbedToken: { __typename?: 'PowerBiEmbedToken', embedToken: string, embedUrl: string, expiration: any } };

export type UserEmailsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserEmailsQuery = { __typename?: 'CoreQuery', users: Array<{ __typename?: 'Users', email: string }> };

export type GetOrganizationAttributesQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  propertyName: Scalars['String'];
}>;


export type GetOrganizationAttributesQuery = { __typename?: 'CoreQuery', getOrganizationAttributes?: { __typename?: 'OrganizationAttributes', id: number, organizationId: string, name: string, value: string } | null };

export type OrganizationsByProtocolQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  protocolId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
}>;


export type OrganizationsByProtocolQuery = { __typename?: 'CoreQuery', organizationsByProtocol__count: number, organizationsByProtocol: Array<{ __typename: 'ProtocolOrganization', id: string, name: string, street: string, city?: string | null, state?: string | null, postalZipCode?: string | null, phone: string, contactPerson: string, email: string, externalSourceId?: string | null, organizationCountry: { __typename?: 'OrganizationCountry', name: string } }> };

export type OrganizationsByTypeQueryVariables = Exact<{
  protocolId: Scalars['String'];
  type: Scalars['String'];
}>;


export type OrganizationsByTypeQuery = { __typename?: 'CoreQuery', organizationsByType: Array<{ __typename?: 'ProtocolOrganization', id: string, protocolId: string, name: string }> };

export type GetOrganizationFileListQueryVariables = Exact<{
  protocolId: Scalars['ID'];
  organizationId: Scalars['ID'];
}>;


export type GetOrganizationFileListQuery = { __typename?: 'CoreQuery', getOrganizationFileList: { __typename?: 'DocumentResponse', files: Array<{ __typename?: 'DocumenFile', name: string, size?: any | null, date: any, path: string, url: string }> } };

export type AdvisorsQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type AdvisorsQuery = { __typename?: 'CoreQuery', advisorsCount: number, advisors: Array<{ __typename?: 'Advisor', id: string, name: string, street: string, city?: string | null, state?: string | null, postalZipCode?: string | null, phone: string, contactPerson: string, email: string, createdOn: any, createdBy: string, updatedOn?: any | null, updatedBy?: string | null, deleted: boolean, deletedOn?: any | null, deletedBy?: string | null, advisorCountry: { __typename?: 'AdvisorCountryDto', name: string, isoCode: string, region: string } }> };

export type PutOrganizationAdvisorMutationVariables = Exact<{
  input: PutOrganizationAdvisorInput;
}>;


export type PutOrganizationAdvisorMutation = { __typename?: 'CoreMutation', putOrganizationAdvisor: string };

export type AdvisorDetailQueryVariables = Exact<{
  advisorName: Scalars['String'];
}>;


export type AdvisorDetailQuery = { __typename?: 'CoreQuery', advisorDetail: { __typename?: 'Advisor', id: string, name: string, street: string, city?: string | null, state?: string | null, postalZipCode?: string | null, phone: string, contactPerson: string, email: string, createdOn: any, createdBy: string, updatedOn?: any | null, updatedBy?: string | null, deleted: boolean, deletedOn?: any | null, deletedBy?: string | null, advisorCountry: { __typename?: 'AdvisorCountryDto', name: string, isoCode: string, region: string } } };

export type AdvisorsFromOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type AdvisorsFromOrganizationQuery = { __typename?: 'CoreQuery', advisorsFromOrganization: Array<{ __typename?: 'OrganizationAdvisorOutput', id: string, name: string, advisorOutput?: Array<{ __typename?: 'AdvisorOutput', id: string, name: string }> | null }> };

export type ApiConnectionsByProtocolQueryVariables = Exact<{
  protocolId: Scalars['ID'];
  where?: InputMaybe<BinaryFilter>;
  sortBy?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type ApiConnectionsByProtocolQuery = { __typename?: 'CoreQuery', apiConnectionsByProtocol: { __typename?: 'ApiConnectionsResponse', apiConnections__count: number, apiConnections: Array<{ __typename?: 'ApiConnection', id: string, protocolId: string, clientId: string, clientName: string, applicationName: string, lastEditedOn?: any | null, lastEditedBy?: string | null, organizations_count?: number | null }> } };

export type ApiConnectionsDetailByProtocolQueryVariables = Exact<{
  protocolId: Scalars['ID'];
  apiConnectionId: Scalars['ID'];
}>;


export type ApiConnectionsDetailByProtocolQuery = { __typename?: 'CoreQuery', apiConnectionsDetailByProtocol?: { __typename?: 'ApiConnectionsOutput', id?: string | null, clientId: string, protocolId: string, clientName: string, applicationName: string, organizationApiConnections?: Array<{ __typename?: 'OrganizationApiConnectionsOutput', id: string, organizationId: string, name: string, internalId?: string | null, externalId?: string | null }> | null } | null };

export type CreateDocumentUploadLinkQueryVariables = Exact<{
  blobIdentifier: Scalars['String'];
}>;


export type CreateDocumentUploadLinkQuery = { __typename?: 'CoreQuery', createDocumentUploadLink: string };

export type ProtocolsDocumentDefinitionQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
}>;


export type ProtocolsDocumentDefinitionQuery = { __typename?: 'CoreQuery', protocols: { __typename?: 'ProtocolReponse', protocols: Array<{ __typename?: 'ProtocolDTO', id: string, name: string, documentDefinition?: { __typename?: 'DocumentDefinition', projectKey?: string | null, protocolKey: string, documentGroups: Array<{ __typename?: 'DocumentGroup', maxOptionalFileCount: number, minOptionalFileCount: number, name: string, typeReferences?: Array<{ __typename?: 'DocumentTypeReference', typeKey: string }> | null }>, documentTypes: Array<{ __typename?: 'DocumentTypeItem', name: string, required: boolean, maxFileCount: number, key: string, allowedFileExtensions?: Array<{ __typename?: 'AllowedFileExtension', extension: string }> | null }> } | null }> } };

export type ProjectDocumentsQueryVariables = Exact<{
  input: ProjectDocumentInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BinaryFilter>;
}>;


export type ProjectDocumentsQuery = { __typename?: 'CoreQuery', projectDocuments__count: number, projectDocuments: Array<{ __typename?: 'ProjectDocument', id: number, type: string, name: string, fileName: string, status: ProjectDocumentStatus, size: any, blobIdentifier: string, documentId: string, projectId: string, lastUpdated: any, projectsCount?: number | null }> };

export type DocumentQueryVariables = Exact<{
  documentId: Scalars['ID'];
}>;


export type DocumentQuery = { __typename?: 'CoreQuery', document: { __typename?: 'DocumentDto', id: string, organizationId: string, name: string, blobIdentifier: string, blobInfo?: string | null, size: any, type: { __typename?: 'DocumentTypeItem', key: string, name: string }, projectDocuments?: Array<{ __typename?: 'ProjectDocument', id: number, type: string, documentId: string, projectId: string, name: string, fileName: string, status: ProjectDocumentStatus, size: any, blobIdentifier: string }> | null } };

export type GetDocumentChangeLogsQueryVariables = Exact<{
  where?: InputMaybe<BinaryFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
  documentId: Scalars['ID'];
}>;


export type GetDocumentChangeLogsQuery = { __typename?: 'CoreQuery', documentChangeLogs__count: number, getDocumentChangeLogs: Array<{ __typename?: 'DocumentChangeLog', id: number, documentId: string, blobIdentifier?: string | null, change: string, time: any, user: string }> };

export type OrganizationByNameQueryVariables = Exact<{
  protocolId: Scalars['String'];
  name: Scalars['String'];
}>;


export type OrganizationByNameQuery = { __typename?: 'CoreQuery', organizations: Array<{ __typename?: 'OrganizationDetail', id: string, name: string }> };

export type GetProjectDocumentsValidationQueryVariables = Exact<{
  input: ProjectDocumentInput;
}>;


export type GetProjectDocumentsValidationQuery = { __typename?: 'CoreQuery', getProjectDocumentsValidation: { __typename?: 'ValidDocumentsDto', protocolId: string, organizationId: string, projectId: string, isValid: boolean, validationGroups: Array<{ __typename?: 'DocumentValidationGroup', isValid: boolean, name: string, minOptionalFileCount: number, message?: string | null, documentValidations: Array<{ __typename?: 'ValidDocumentDto', name: string, status: string }> }> } };

export type UsersAddedToProtocolsSubscriptionVariables = Exact<{
  correlationId: Scalars['ID'];
}>;


export type UsersAddedToProtocolsSubscription = { __typename?: 'CoreSubscription', userAddedToProtocol: { __typename?: 'UsersAddedToProtocol', protocolId: string, userRoles: Array<{ __typename?: 'UserIdWithRoleId', userId: string, roleId: string }> } };

export type CoreErrorsSubscriptionVariables = Exact<{
  correlationId: Scalars['ID'];
}>;


export type CoreErrorsSubscription = { __typename?: 'CoreSubscription', errors: { __typename?: 'EventError', correlationId: string, message: string, exceptions?: Array<{ __typename?: 'EventExceptionInfo', stackTrace: string, message: string, data?: Array<{ __typename?: 'EventExceptionData', key: string, value: string }> | null, innerException?: { __typename?: 'EventExceptionInfo', message: string } | null }> | null } };

export type ProtocolUserUpdatedSubscriptionVariables = Exact<{
  correlationId: Scalars['ID'];
}>;


export type ProtocolUserUpdatedSubscription = { __typename?: 'CoreSubscription', protocolUserUpdated: { __typename?: 'ProtocolUserUpdated', protocolId: string, roleId: string, userId: string } };

export type ProtocolUserDeletedSubscriptionVariables = Exact<{
  correlationId: Scalars['ID'];
}>;


export type ProtocolUserDeletedSubscription = { __typename?: 'CoreSubscription', protocolUserDeleted: { __typename?: 'ProtocolUserDeleted', userIds: Array<string>, protocolId: string } };

export const SendUserInvitationEmailDocument = gql`
    mutation sendUserInvitationEmail($input: InviteUserUI!) {
  sendUserInvitationEmail(input: $input) {
    invitation_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendUserInvitationEmailGQL extends Apollo.Mutation<SendUserInvitationEmailMutation, SendUserInvitationEmailMutationVariables> {
    override document = SendUserInvitationEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccessRequestEmailDocument = gql`
    mutation AccessRequestEmail($input: RequestUserUI!) {
  accessRequestEmail(input: $input) {
    message_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccessRequestEmailGQL extends Apollo.Mutation<AccessRequestEmailMutation, AccessRequestEmailMutationVariables> {
    override document = AccessRequestEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PutOrganizationDocument = gql`
    mutation PutOrganization($input: OrganizationInput!) {
  putOrganization(input: $input) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PutOrganizationGQL extends Apollo.Mutation<PutOrganizationMutation, PutOrganizationMutationVariables> {
    override document = PutOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RejectAccesRequestDocument = gql`
    mutation rejectAccesRequest($input: DeniedUser!) {
  rejectAccesRequest(input: $input) {
    message_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RejectAccesRequestGQL extends Apollo.Mutation<RejectAccesRequestMutation, RejectAccesRequestMutationVariables> {
    override document = RejectAccesRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisableUserDocument = gql`
    mutation DisableUser($input: DisableUser!) {
  disableUser(input: $input) {
    user_email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DisableUserGQL extends Apollo.Mutation<DisableUserMutation, DisableUserMutationVariables> {
    override document = DisableUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddNewProtocolTemplateDocument = gql`
    mutation AddNewProtocolTemplate($input: ProtocolTemplateInput!) {
  addNewProtocolTemplate(input: $input) {
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewProtocolTemplateGQL extends Apollo.Mutation<AddNewProtocolTemplateMutation, AddNewProtocolTemplateMutationVariables> {
    override document = AddNewProtocolTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditProtocolTemplateDocument = gql`
    mutation EditProtocolTemplate($input: ProtocolTemplateInput!) {
  editProtocolTemplate(input: $input) {
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditProtocolTemplateGQL extends Apollo.Mutation<EditProtocolTemplateMutation, EditProtocolTemplateMutationVariables> {
    override document = EditProtocolTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddNewProtocolDocument = gql`
    mutation AddNewProtocol($input: AddProtocolInput!) {
  addNewProtocol(input: $input) {
    id
    name
    cMSContentRootKey
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewProtocolGQL extends Apollo.Mutation<AddNewProtocolMutation, AddNewProtocolMutationVariables> {
    override document = AddNewProtocolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrganizationProtocolParticipantSetFactorValuesDocument = gql`
    mutation UpdateOrganizationProtocolParticipantSetFactorValues($input: OrganizationProtocolParticipantInput!) {
  updateOrganizationProtocolParticipantSetFactorValues(input: $input) {
    protocolId
    organizationId
    factors {
      id
      name
      scope
      type
    }
    organizationParticipantFactorSetValues {
      id
      key1
      key2
      key3
      key4
      key5
      factorValues {
        id
        factorSetValueId
        factorName
        value
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationProtocolParticipantSetFactorValuesGQL extends Apollo.Mutation<UpdateOrganizationProtocolParticipantSetFactorValuesMutation, UpdateOrganizationProtocolParticipantSetFactorValuesMutationVariables> {
    override document = UpdateOrganizationProtocolParticipantSetFactorValuesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddNewProjectDocument = gql`
    mutation AddNewProject($input: AddProjectInput!) {
  addNewProject(input: $input) {
    id
    name
    status
    createdOn
    createdBy
    updatedOn
    updatedBy
    deleted
    deletedOn
    deletedBy
    protocolId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewProjectGQL extends Apollo.Mutation<AddNewProjectMutation, AddNewProjectMutationVariables> {
    override document = AddNewProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectDocument = gql`
    mutation UpdateProject($input: AddProjectInput!) {
  updateProject(input: $input) {
    id
    name
    periodStart
    periodEnd
    status
    protocolId
    projectFactorValue {
      projectId
      factorName
      value
    }
    updatedOn
    updatedBy
    createdOn
    createdBy
    deleted
    deletedOn
    deletedBy
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectGQL extends Apollo.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> {
    override document = UpdateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectStatusDocument = gql`
    mutation UpdateProjectStatus($input: UpdateProjectStatusInput!) {
  updateProjectStatus(input: $input) {
    status
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectStatusGQL extends Apollo.Mutation<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables> {
    override document = UpdateProjectStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOrganizationFromProtocolDocument = gql`
    mutation DeleteOrganizationFromProtocol($input: DeleteOrganizationsFromProtocolInput!) {
  deleteOrganizationFromProtocol(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOrganizationFromProtocolGQL extends Apollo.Mutation<DeleteOrganizationFromProtocolMutation, DeleteOrganizationFromProtocolMutationVariables> {
    override document = DeleteOrganizationFromProtocolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUsersToProtocolDocument = gql`
    mutation AddUsersToProtocol($input: AddUsersToProtocolInput!) {
  addUsersToProtocol(input: $input) {
    correlationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUsersToProtocolGQL extends Apollo.Mutation<AddUsersToProtocolMutation, AddUsersToProtocolMutationVariables> {
    override document = AddUsersToProtocolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProtocolUserDocument = gql`
    mutation UpdateProtocolUser($input: UpdateProtocolUserInput!) {
  updateProtocolUser(input: $input) {
    correlationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProtocolUserGQL extends Apollo.Mutation<UpdateProtocolUserMutation, UpdateProtocolUserMutationVariables> {
    override document = UpdateProtocolUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProtocolUserDocument = gql`
    mutation DeleteProtocolUser($input: DeleteProtocolUserInput!) {
  deleteProtocolUser(input: $input) {
    correlationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProtocolUserGQL extends Apollo.Mutation<DeleteProtocolUserMutation, DeleteProtocolUserMutationVariables> {
    override document = DeleteProtocolUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PutOrganizationAttributesDocument = gql`
    mutation PutOrganizationAttributes($input: PutOrganizationAttributesInput!) {
  putOrganizationAttributes(input: $input) {
    correlationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PutOrganizationAttributesGQL extends Apollo.Mutation<PutOrganizationAttributesMutation, PutOrganizationAttributesMutationVariables> {
    override document = PutOrganizationAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddOrganizationToUserDocument = gql`
    mutation AddOrganizationToUser($input: AddOrganizationtoUserInput!) {
  addOrganizationToUser(input: $input) {
    organizationId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddOrganizationToUserGQL extends Apollo.Mutation<AddOrganizationToUserMutation, AddOrganizationToUserMutationVariables> {
    override document = AddOrganizationToUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptUserDocument = gql`
    mutation AcceptUser($input: AcceptUserInput!) {
  acceptUser(input: $input) {
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptUserGQL extends Apollo.Mutation<AcceptUserMutation, AcceptUserMutationVariables> {
    override document = AcceptUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PutAdvisorDocument = gql`
    mutation PutAdvisor($input: AdvisorInput!) {
  putAdvisor(input: $input) {
    id
    name
    street
    city
    state
    advisorCountry {
      name
      isoCode
      region
    }
    postalZipCode
    phone
    contactPerson
    email
    createdOn
    createdBy
    updatedOn
    updatedBy
    deleted
    deletedOn
    deletedBy
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PutAdvisorGQL extends Apollo.Mutation<PutAdvisorMutation, PutAdvisorMutationVariables> {
    override document = PutAdvisorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditProtocolDocument = gql`
    mutation EditProtocol($input: EditProtocolInput!) {
  editProtocol(input: $input) {
    id
    name
    cMSContentRootKey
    createdOn
    createdBy
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditProtocolGQL extends Apollo.Mutation<EditProtocolMutation, EditProtocolMutationVariables> {
    override document = EditProtocolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PutApiConnectionDocument = gql`
    mutation PutApiConnection($input: ApiConnectionsInput!) {
  putApiConnection(input: $input) {
    id
    protocolId
    clientId
    clientName
    applicationName
    lastEditedOn
    lastEditedBy
    organizations_count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PutApiConnectionGQL extends Apollo.Mutation<PutApiConnectionMutation, PutApiConnectionMutationVariables> {
    override document = PutApiConnectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAdvisorDocument = gql`
    mutation DeleteAdvisor($input: DeleteAdvisorsInput!) {
  deleteAdvisor(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAdvisorGQL extends Apollo.Mutation<DeleteAdvisorMutation, DeleteAdvisorMutationVariables> {
    override document = DeleteAdvisorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitDocumentDocument = gql`
    mutation SubmitDocument($input: SubmitDocumentInput!) {
  submitDocument(input: $input) {
    created {
      name
      blobInfo
      blobIdentifier
      documentId
    }
    updated {
      name
      blobInfo
      blobIdentifier
      documentId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitDocumentGQL extends Apollo.Mutation<SubmitDocumentMutation, SubmitDocumentMutationVariables> {
    override document = SubmitDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LinkDocumentToOrganizationProjectsDocument = gql`
    mutation LinkDocumentToOrganizationProjects($input: LinkDocumentToOrganizationProjectsInput!) {
  linkDocumentToOrganizationProjects(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LinkDocumentToOrganizationProjectsGQL extends Apollo.Mutation<LinkDocumentToOrganizationProjectsMutation, LinkDocumentToOrganizationProjectsMutationVariables> {
    override document = LinkDocumentToOrganizationProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditDocumentDocument = gql`
    mutation EditDocument($input: EditDocumentInput!) {
  editDocument(input: $input) {
    protocolId
    organizationId
    projectId
    document {
      blobIdentifier
      blobInfo
      name
      type
      typeName
      size
    }
    userId
    correlationId
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditDocumentGQL extends Apollo.Mutation<EditDocumentMutation, EditDocumentMutationVariables> {
    override document = EditDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConfigurationDocument = gql`
    query GetConfiguration($key: String!) {
  getConfiguration(key: $key)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConfigurationGQL extends Apollo.Query<GetConfigurationQuery, GetConfigurationQueryVariables> {
    override document = GetConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMapLayersConfigurationDocument = gql`
    query GetMapLayersConfiguration {
  mapConfigOptions {
    layers {
      maxZoom
      minZoom
      name
      templateUrl
      type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMapLayersConfigurationGQL extends Apollo.Query<GetMapLayersConfigurationQuery, GetMapLayersConfigurationQueryVariables> {
    override document = GetMapLayersConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRolesDocument = gql`
    query GetRoles {
  roles {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRolesGQL extends Apollo.Query<GetRolesQuery, GetRolesQueryVariables> {
    override document = GetRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersRequestingByDateDocument = gql`
    query UsersRequestingByDate {
  usersRequestingByDate {
    title
    data {
      id
      propertyKey
      userName
      userEmail
      description
      active
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersRequestingByDateGQL extends Apollo.Query<UsersRequestingByDateQuery, UsersRequestingByDateQueryVariables> {
    override document = UsersRequestingByDateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersRequestingAccessDocument = gql`
    query UsersRequestingAccess($where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int) {
  users(where: $where, sortBy: $sortBy, offset: $offset, limit: $limit) {
    id
    requestDate
    requestDateFormat
    userName
    email
    role
    time
    status
    action
    id
    template {
      templateId
      parameters {
        key
        value
      }
    }
  }
  users__count(where: $where)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersRequestingAccessGQL extends Apollo.Query<UsersRequestingAccessQuery, UsersRequestingAccessQueryVariables> {
    override document = UsersRequestingAccessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query Users($where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int) {
  users(where: $where, sortBy: $sortBy, offset: $offset, limit: $limit) {
    id
    userName
    email
    status
    action
    id
    template {
      templateId
      parameters {
        key
        value
      }
    }
  }
  users__count(where: $where)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    override document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersByProtocolDocument = gql`
    query UsersByProtocol($where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int, $protocolId: ID!) {
  usersByProtocol(
    where: $where
    sortBy: $sortBy
    offset: $offset
    limit: $limit
    protocolId: $protocolId
  ) {
    id
    userName
    email
    status
    action
    id
    role
    template {
      templateId
      parameters {
        key
        value
      }
    }
  }
  usersByProtocol__Count(protocolId: $protocolId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersByProtocolGQL extends Apollo.Query<UsersByProtocolQuery, UsersByProtocolQueryVariables> {
    override document = UsersByProtocolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationsDocument = gql`
    query Organizations($where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int, $protocolId: String!) {
  organizations(
    where: $where
    sortBy: $sortBy
    offset: $offset
    limit: $limit
    protocolId: $protocolId
  ) {
    id
    name
    address
    phone
    contactPerson
    email
    externalSourceId
    postalZipCode
    state
    street
    type
    city
    advisorNames
    organizationCountry {
      isoCode
      name
      region
    }
  }
  organizations__count(where: $where)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationsGQL extends Apollo.Query<OrganizationsQuery, OrganizationsQueryVariables> {
    override document = OrganizationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationsNameAndIdDocument = gql`
    query OrganizationsNameAndId($protocolId: String!) {
  organizations(protocolId: $protocolId) {
    id
    name
    externalSourceId
    address
    street
    city
    state
    postalZipCode
    phone
    contactPerson
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationsNameAndIdGQL extends Apollo.Query<OrganizationsNameAndIdQuery, OrganizationsNameAndIdQueryVariables> {
    override document = OrganizationsNameAndIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectsNameAndIdDocument = gql`
    query ProjectsNameAndId {
  projects {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectsNameAndIdGQL extends Apollo.Query<ProjectsNameAndIdQuery, ProjectsNameAndIdQueryVariables> {
    override document = ProjectsNameAndIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectsDocument = gql`
    query Projects($sortBy: String, $offset: Int, $limit: Int, $where: BinaryFilter) {
  projects(sortBy: $sortBy, offset: $offset, limit: $limit, where: $where) {
    id
    name
    status
  }
  projects__count(where: $where)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectsGQL extends Apollo.Query<ProjectsQuery, ProjectsQueryVariables> {
    override document = ProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProtocolsDocument = gql`
    query Protocols($where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int) {
  protocols(where: $where, sortBy: $sortBy, offset: $offset, limit: $limit) {
    protocols {
      id
      name
      cMSContentRootKey
      protocolTemplateId
      templateName
      admins {
        id
        email
      }
      protocolFactorValue {
        name
        value
      }
      projects_count
      createdOn
      createdBy
      updatedOn
      updatedBy
      deletedOn
      deletedBy
      deleted
      documentDefinition {
        protocolKey
        projectKey
        documentTypes {
          key
          name
          maxFileCount
          allowedFileExtensions {
            extension
          }
          required
        }
        documentGroups {
          name
          minOptionalFileCount
          maxOptionalFileCount
          typeReferences {
            typeKey
          }
        }
      }
    }
    protocols__count
  }
  protocolsDistinctBy(where: $where, propertyName: "TemplateName")
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProtocolsGQL extends Apollo.Query<ProtocolsQuery, ProtocolsQueryVariables> {
    override document = ProtocolsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplatesDocument = gql`
    query Templates($where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int) {
  createdByList: templatesDistinctBy(propertyName: "CreatedBy", where: $where)
  templates(where: $where, sortBy: $sortBy, offset: $offset, limit: $limit) {
    name
    id
    createdOn
    createdBy
    updatedOn
    updatedBy
    factors {
      id
      name
      scope
      type
      __typename
    }
    __typename
  }
  templates__count(where: $where)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatesGQL extends Apollo.Query<TemplatesQuery, TemplatesQueryVariables> {
    override document = TemplatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersByRoleDocument = gql`
    query UsersByRole($roleNames: [String!]!, $sortBy: String, $offset: Int, $limit: Int, $where: BinaryFilter) {
  usersByRole(
    roleNames: $roleNames
    sortBy: $sortBy
    offset: $offset
    limit: $limit
    where: $where
  ) {
    id
    email
    roles
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersByRoleGQL extends Apollo.Query<UsersByRoleQuery, UsersByRoleQueryVariables> {
    override document = UsersByRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TemplateDetailDocument = gql`
    query TemplateDetail($name: String!) {
  templateDetail(name: $name) {
    id
    name
    factors {
      id
      name
      scope
      type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateDetailGQL extends Apollo.Query<TemplateDetailQuery, TemplateDetailQueryVariables> {
    override document = TemplateDetailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProtocolDetailSummaryDocument = gql`
    query ProtocolDetailSummary($protocolId: ID!) {
  protocolDetailSummary(protocolId: $protocolId) {
    name
    factors {
      name
      scope
      type
    }
    admins
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProtocolDetailSummaryGQL extends Apollo.Query<ProtocolDetailSummaryQuery, ProtocolDetailSummaryQueryVariables> {
    override document = ProtocolDetailSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvitationQueryDocument = gql`
    query InvitationQuery {
  roles {
    id
    name
  }
  me {
    globalRoles
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvitationQueryGQL extends Apollo.Query<InvitationQueryQuery, InvitationQueryQueryVariables> {
    override document = InvitationQueryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationDetailDocument = gql`
    query OrganizationDetail($orgName: String!, $protocolId: String!) {
  organizationDetail(orgName: $orgName, protocolId: $protocolId) {
    id
    name
    externalSourceId
    street
    city
    address
    state
    postalZipCode
    phone
    contactPerson
    email
    advisorNames
    organizationCountry {
      name
      isoCode
      region
    }
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationDetailGQL extends Apollo.Query<OrganizationDetailQuery, OrganizationDetailQueryVariables> {
    override document = OrganizationDetailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationProtocolParticipantDocument = gql`
    query GetOrganizationProtocolParticipant($protocolId: String!, $organizationId: String!) {
  getOrganizationProtocolParticipant(
    protocolId: $protocolId
    organizationId: $organizationId
  ) {
    protocolId
    organizationId
    factors {
      id
      name
      scope
      type
    }
    organizationParticipantFactorSetValues {
      id
      key1
      key2
      key3
      key4
      key5
      factorValues {
        id
        factorSetValueId
        factorName
        value
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationProtocolParticipantGQL extends Apollo.Query<GetOrganizationProtocolParticipantQuery, GetOrganizationProtocolParticipantQueryVariables> {
    override document = GetOrganizationProtocolParticipantDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectsDocument = gql`
    query GetProjects($where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int) {
  projects(where: $where, sortBy: $sortBy, offset: $offset, limit: $limit) {
    id
    name
    status
    createdOn
    createdBy
    updatedOn
    updatedBy
    deleted
    deletedOn
    deletedBy
    protocolId
    tC02Price
    projectFactorValue {
      factorName
      value
      projectId
    }
    periodEnd
    periodStart
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectsGQL extends Apollo.Query<GetProjectsQuery, GetProjectsQueryVariables> {
    override document = GetProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectDefinitionDocument = gql`
    query ProjectDefinition($protocolId: ID!) {
  projectDefinition(protocolId: $protocolId) {
    protocolName
    factorDefinitions {
      id
      name
      scope
      type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectDefinitionGQL extends Apollo.Query<ProjectDefinitionQuery, ProjectDefinitionQueryVariables> {
    override document = ProjectDefinitionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectDetailDocument = gql`
    query ProjectDetail($protocolId: ID!, $projectId: ID!) {
  projectDetail(protocolId: $protocolId, projectId: $projectId) {
    id
    name
    periodStart
    periodEnd
    status
    protocolId
    tC02Price
    projectFactorValue {
      projectId
      factorName
      value
    }
    projectAttributes {
      id
      projectId
      name
      value
    }
    updatedOn
    updatedBy
    createdOn
    createdBy
    deleted
    deletedOn
    deletedBy
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectDetailGQL extends Apollo.Query<ProjectDetailQuery, ProjectDetailQueryVariables> {
    override document = ProjectDetailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectsByProtocolDocument = gql`
    query ProjectsByProtocol($protocolId: ID!) {
  projectsByProtocol(protocolId: $protocolId) {
    name
    id
    periodStart
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectsByProtocolGQL extends Apollo.Query<ProjectsByProtocolQuery, ProjectsByProtocolQueryVariables> {
    override document = ProjectsByProtocolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPowerBiEmbedTokenDocument = gql`
    query GetPowerBIEmbedToken($input: PowerBiEmbedTokenInput!) {
  getPowerBIEmbedToken(input: $input) {
    embedToken
    embedUrl
    expiration
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPowerBiEmbedTokenGQL extends Apollo.Query<GetPowerBiEmbedTokenQuery, GetPowerBiEmbedTokenQueryVariables> {
    override document = GetPowerBiEmbedTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserEmailsDocument = gql`
    query UserEmails {
  users {
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserEmailsGQL extends Apollo.Query<UserEmailsQuery, UserEmailsQueryVariables> {
    override document = UserEmailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationAttributesDocument = gql`
    query GetOrganizationAttributes($organizationId: ID!, $propertyName: String!) {
  getOrganizationAttributes(
    organizationId: $organizationId
    propertyName: $propertyName
  ) {
    id
    organizationId
    name
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationAttributesGQL extends Apollo.Query<GetOrganizationAttributesQuery, GetOrganizationAttributesQueryVariables> {
    override document = GetOrganizationAttributesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationsByProtocolDocument = gql`
    query OrganizationsByProtocol($where: BinaryFilter, $protocolId: String!, $limit: Int, $offset: Int, $sortBy: String) {
  organizationsByProtocol(
    where: $where
    sortBy: $sortBy
    offset: $offset
    limit: $limit
    protocolId: $protocolId
  ) {
    id
    name
    street
    city
    state
    postalZipCode
    organizationCountry {
      name
    }
    phone
    contactPerson
    email
    externalSourceId
    __typename
  }
  organizationsByProtocol__count(where: $where, protocolId: $protocolId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationsByProtocolGQL extends Apollo.Query<OrganizationsByProtocolQuery, OrganizationsByProtocolQueryVariables> {
    override document = OrganizationsByProtocolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationsByTypeDocument = gql`
    query OrganizationsByType($protocolId: String!, $type: String!) {
  organizationsByType(protocolId: $protocolId, type: $type) {
    id
    protocolId
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationsByTypeGQL extends Apollo.Query<OrganizationsByTypeQuery, OrganizationsByTypeQueryVariables> {
    override document = OrganizationsByTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOrganizationFileListDocument = gql`
    query GetOrganizationFileList($protocolId: ID!, $organizationId: ID!) {
  getOrganizationFileList(
    protocolId: $protocolId
    organizationId: $organizationId
  ) {
    files {
      name
      size
      date
      path
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationFileListGQL extends Apollo.Query<GetOrganizationFileListQuery, GetOrganizationFileListQueryVariables> {
    override document = GetOrganizationFileListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdvisorsDocument = gql`
    query Advisors($where: BinaryFilter, $sortBy: String, $offset: Int, $limit: Int) {
  advisors(where: $where, sortBy: $sortBy, offset: $offset, limit: $limit) {
    id
    name
    street
    city
    state
    advisorCountry {
      name
      isoCode
      region
    }
    postalZipCode
    phone
    contactPerson
    email
    createdOn
    createdBy
    updatedOn
    updatedBy
    deleted
    deletedOn
    deletedBy
  }
  advisorsCount(where: $where)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdvisorsGQL extends Apollo.Query<AdvisorsQuery, AdvisorsQueryVariables> {
    override document = AdvisorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PutOrganizationAdvisorDocument = gql`
    mutation PutOrganizationAdvisor($input: PutOrganizationAdvisorInput!) {
  putOrganizationAdvisor(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PutOrganizationAdvisorGQL extends Apollo.Mutation<PutOrganizationAdvisorMutation, PutOrganizationAdvisorMutationVariables> {
    override document = PutOrganizationAdvisorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdvisorDetailDocument = gql`
    query AdvisorDetail($advisorName: String!) {
  advisorDetail(advisorName: $advisorName) {
    id
    name
    street
    city
    state
    advisorCountry {
      name
      isoCode
      region
    }
    postalZipCode
    phone
    contactPerson
    email
    createdOn
    createdBy
    updatedOn
    updatedBy
    deleted
    deletedOn
    deletedBy
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdvisorDetailGQL extends Apollo.Query<AdvisorDetailQuery, AdvisorDetailQueryVariables> {
    override document = AdvisorDetailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AdvisorsFromOrganizationDocument = gql`
    query AdvisorsFromOrganization {
  advisorsFromOrganization {
    id
    name
    advisorOutput {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AdvisorsFromOrganizationGQL extends Apollo.Query<AdvisorsFromOrganizationQuery, AdvisorsFromOrganizationQueryVariables> {
    override document = AdvisorsFromOrganizationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApiConnectionsByProtocolDocument = gql`
    query ApiConnectionsByProtocol($protocolId: ID!, $where: BinaryFilter, $sortBy: String, $limit: Int, $offset: Int) {
  apiConnectionsByProtocol(
    protocolId: $protocolId
    where: $where
    sortBy: $sortBy
    limit: $limit
    offset: $offset
  ) {
    apiConnections {
      id
      protocolId
      clientId
      clientName
      applicationName
      lastEditedOn
      lastEditedBy
      organizations_count
    }
    apiConnections__count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApiConnectionsByProtocolGQL extends Apollo.Query<ApiConnectionsByProtocolQuery, ApiConnectionsByProtocolQueryVariables> {
    override document = ApiConnectionsByProtocolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApiConnectionsDetailByProtocolDocument = gql`
    query ApiConnectionsDetailByProtocol($protocolId: ID!, $apiConnectionId: ID!) {
  apiConnectionsDetailByProtocol(
    protocolId: $protocolId
    apiConnectionId: $apiConnectionId
  ) {
    id
    clientId
    protocolId
    clientName
    applicationName
    organizationApiConnections {
      id
      organizationId
      name
      internalId
      externalId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApiConnectionsDetailByProtocolGQL extends Apollo.Query<ApiConnectionsDetailByProtocolQuery, ApiConnectionsDetailByProtocolQueryVariables> {
    override document = ApiConnectionsDetailByProtocolDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDocumentUploadLinkDocument = gql`
    query createDocumentUploadLink($blobIdentifier: String!) {
  createDocumentUploadLink(blobIdentifier: $blobIdentifier)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDocumentUploadLinkGQL extends Apollo.Query<CreateDocumentUploadLinkQuery, CreateDocumentUploadLinkQueryVariables> {
    override document = CreateDocumentUploadLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProtocolsDocumentDefinitionDocument = gql`
    query ProtocolsDocumentDefinition($where: BinaryFilter) {
  protocols(where: $where) {
    protocols {
      documentDefinition {
        documentGroups {
          maxOptionalFileCount
          minOptionalFileCount
          name
          typeReferences {
            typeKey
          }
        }
        documentTypes {
          name
          required
          maxFileCount
          key
          allowedFileExtensions {
            extension
          }
        }
        projectKey
        protocolKey
      }
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProtocolsDocumentDefinitionGQL extends Apollo.Query<ProtocolsDocumentDefinitionQuery, ProtocolsDocumentDefinitionQueryVariables> {
    override document = ProtocolsDocumentDefinitionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectDocumentsDocument = gql`
    query ProjectDocuments($input: ProjectDocumentInput!, $limit: Int, $offset: Int, $sortBy: String, $where: BinaryFilter) {
  projectDocuments(
    input: $input
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    where: $where
  ) {
    id
    type
    name
    fileName
    status
    size
    blobIdentifier
    documentId
    projectId
    lastUpdated
    projectsCount
  }
  projectDocuments__count(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectDocumentsGQL extends Apollo.Query<ProjectDocumentsQuery, ProjectDocumentsQueryVariables> {
    override document = ProjectDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DocumentDocument = gql`
    query Document($documentId: ID!) {
  document(documentId: $documentId) {
    id
    organizationId
    name
    type {
      key
      name
    }
    blobIdentifier
    blobInfo
    size
    projectDocuments {
      id
      type
      documentId
      projectId
      name
      fileName
      status
      size
      blobIdentifier
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DocumentGQL extends Apollo.Query<DocumentQuery, DocumentQueryVariables> {
    override document = DocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDocumentChangeLogsDocument = gql`
    query GetDocumentChangeLogs($where: BinaryFilter, $limit: Int, $offset: Int, $sortBy: String, $documentId: ID!) {
  getDocumentChangeLogs(
    where: $where
    sortBy: $sortBy
    offset: $offset
    limit: $limit
    documentId: $documentId
  ) {
    id
    documentId
    blobIdentifier
    change
    time
    user
  }
  documentChangeLogs__count(where: $where, documentId: $documentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDocumentChangeLogsGQL extends Apollo.Query<GetDocumentChangeLogsQuery, GetDocumentChangeLogsQueryVariables> {
    override document = GetDocumentChangeLogsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrganizationByNameDocument = gql`
    query OrganizationByName($protocolId: String!, $name: String!) {
  organizations(
    protocolId: $protocolId
    where: {expr: {column: "name", operator: "=", value: $name}}
  ) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationByNameGQL extends Apollo.Query<OrganizationByNameQuery, OrganizationByNameQueryVariables> {
    override document = OrganizationByNameDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectDocumentsValidationDocument = gql`
    query GetProjectDocumentsValidation($input: ProjectDocumentInput!) {
  getProjectDocumentsValidation(input: $input) {
    protocolId
    organizationId
    projectId
    isValid
    validationGroups {
      isValid
      name
      minOptionalFileCount
      message
      documentValidations {
        name
        status
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectDocumentsValidationGQL extends Apollo.Query<GetProjectDocumentsValidationQuery, GetProjectDocumentsValidationQueryVariables> {
    override document = GetProjectDocumentsValidationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersAddedToProtocolsDocument = gql`
    subscription UsersAddedToProtocols($correlationId: ID!) {
  userAddedToProtocol(correlationId: $correlationId) {
    protocolId
    userRoles {
      userId
      roleId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersAddedToProtocolsGQL extends Apollo.Subscription<UsersAddedToProtocolsSubscription, UsersAddedToProtocolsSubscriptionVariables> {
    override document = UsersAddedToProtocolsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CoreErrorsDocument = gql`
    subscription CoreErrors($correlationId: ID!) {
  errors(correlationId: $correlationId) {
    correlationId
    message
    exceptions {
      stackTrace
      message
      data {
        key
        value
      }
      innerException {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CoreErrorsGQL extends Apollo.Subscription<CoreErrorsSubscription, CoreErrorsSubscriptionVariables> {
    override document = CoreErrorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProtocolUserUpdatedDocument = gql`
    subscription ProtocolUserUpdated($correlationId: ID!) {
  protocolUserUpdated(correlationId: $correlationId) {
    protocolId
    roleId
    userId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProtocolUserUpdatedGQL extends Apollo.Subscription<ProtocolUserUpdatedSubscription, ProtocolUserUpdatedSubscriptionVariables> {
    override document = ProtocolUserUpdatedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProtocolUserDeletedDocument = gql`
    subscription ProtocolUserDeleted($correlationId: ID!) {
  protocolUserDeleted(correlationId: $correlationId) {
    userIds
    protocolId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProtocolUserDeletedGQL extends Apollo.Subscription<ProtocolUserDeletedSubscription, ProtocolUserDeletedSubscriptionVariables> {
    override document = ProtocolUserDeletedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }