<app-spinner [isWorking]="isWorking$ | async"></app-spinner>

<div class="div-panel-grid-wraping div-panel-grid-wraping-border mt-1">
    <div>
        <div class="subtitle-edit">

            <div class="subtitle-bold">{{ 'Organization Information'| transloco }}</div>
            <div class="div-buttons-right ">
                <ccx-button size="medium" color="secondary" buttonStyle="outline"
                        (buttonClick)="openEditOrgFrom()">
                <svg-icon  name="edit"></svg-icon>
                <span class="p-btn-edit">{{ 'Edit' | transloco }}</span>
            </ccx-button>
            </div>
        </div>

        <div class="div-panel-grid-wraping">
            <div class="div-input-form-2 org-info">

                <div>
                    <label class="heading-black-bold"> {{ 'Name '| transloco }}</label>
                    <label class="heading-black pb-1"> {{ organization.name}}</label>

                    <label class="heading-black-bold"> {{ ' External Source ID'| transloco }}</label>
                    <label class="heading-black pb-1"> {{ organization.externalSourceId }}</label>

                    <label class="heading-black-bold"> {{ ' Address'| transloco }}</label>
                    <label class="heading-black pb-1"> {{ organization.address}}</label>

                    <label class="heading-black-bold"> {{ 'Type '| transloco }}</label>
                    <label class="heading-black pb-1"> {{ organization.type }} </label>

                </div>

                <div>
                    <label class="heading-black-bold"> {{ 'Primary Contact '| transloco }}</label>
                    <label class="heading-black pb-1"> {{organization.contactPerson}}</label>

                    <label class="heading-black-bold"> {{ 'Phone '| transloco }}</label>
                    <label class="heading-black pb-1">{{organization.phone}}</label>

                    <label class="heading-black-bold"> {{ 'Email '| transloco }}</label>
                    <label class="heading-black pb-1"> {{ organization.email }}</label>

                    <label class="heading-black-bold"> {{ 'Advisors '| transloco }}</label>
                    <p *ngFor="let o of organization.advisorNames">{{ o }}</p>

                </div>

            </div>
        </div>



    </div>

</div>
