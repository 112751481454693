<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Change Log' | transloco }}</h4>
        <modus-button size="25" button-style="borderless" color="primary" icon-only="close" aria-label="close"
            (buttonClick)="dialogClose()"></modus-button>
    </div>
    <div class="modal-body" cdk-scrollable>
        <ccx-grid  [dataSource]="DocumentChangeLogDataSource" 
        (onCellClick)="onCellClicked($event)"
          [rowSelection]="'multiple'"
          (selectionChanged)="onSelectionChanged($event)"
          [suppressMovableColumns]="true" [suppressCellFocus]="true"
>
        </ccx-grid>
    </div>

</div>
<div class="modal-footer">
    <ccx-button width="7.3125rem" size="medium" color="primary" (buttonClick)="dialogClose()">{{ 'Back'|
        transloco }}
    </ccx-button>
</div>