import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable, isDevMode, NgModule} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticationOperation, IsAuthenticatedOperator} from './@core/functions/operator-functions';
import {NoHttpInterceptorHeader} from './@auth/auth-interceptor';
import {map} from 'rxjs/operators';
import {GetTranslationsByLangGQL} from './@core/generated/operations-cms-graphql';
import {TIdService} from './@auth/services/tId.service';
import {provideTransloco, Translation, TranslocoLoader, TranslocoModule} from '@jsverse/transloco';


/**
 * Language Dictionary
 *
 * @remarks
 * When transloco Loads a dictionary, this service saves a copy of the entries to be retrieved primarily from
 * Apollo Links, since Transloco service depends on GraphQL requests, we can't otherwise inject it to Apollo without
 * creating a circular dependency
 */
@Injectable()
export class DictionaryService {
    private _dictionary: Map<string, string> = new Map();

    setDict(m: Map<string, string>): void {
        this._dictionary = m;
    }

    getEntry(entry: string): string {
        return this._dictionary.get(entry) || entry;
    }
}

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private getTranslationsClient: GetTranslationsByLangGQL,
                private tidService: TIdService,
                private dict: DictionaryService) {
    }

    getTranslation(lang: string): Observable<Translation> {
        // does not work with http interceptor
        return IsAuthenticatedOperator(token => {
            const JWT = `Bearer ${token?.tokens?.token}`;
            return this.getTranslationsClient.fetch({lang, parentKey: 'Main - UI'}, {
                context: {
                    headers: new HttpHeaders().set(NoHttpInterceptorHeader, '').set('Authorization', JWT),
                },
            })
                .pipe(
                    map(e => {
                        let map = new Map<string, string>();
                        if (e.data.i18n) {
                            e.data.i18n.forEach(l => {
                                if (l) {
                                    map.set(l.key, l.value);
                                }
                            });
                        }
                        const m = Object.fromEntries(map);
                        this.dict.setDict(map);
                        return m;
                    })
                )
        }, this.tidService, AuthenticationOperation.ReturnEmptyObject)
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        provideTransloco({
            config: {
                availableLangs: ['en-US', 'es-MX', 'fr-CA'],
                fallbackLang: 'en-US',
                defaultLang: 'en-US',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: false,
                prodMode: !isDevMode(),
                missingHandler: {
                    allowEmpty: false,
                    logMissingKey: true
                },
                interpolation: ['<<', '>>']
            },
            loader: TranslocoHttpLoader
        })
    ]
})
export class TranslocoRootModule {
}

``
