import {Component, Input, OnInit} from '@angular/core';

interface Chip {
    id: number;
    display: string;
}

@Component({
    selector: 'app-chip-select',
    template: `
  <div class="div-chip-select">
    <div class="chips">
        <modus-chip size="small" value={{chip.display}} show-close *ngFor="let chip of selectedChips"
            (closeClick)="closeClick(chip)"></modus-chip>
    </div>
    <modus-select (valueChange)="valueChange($event)" [optionsDisplayProp]="displayoption"
        [options]="selectOptions"></modus-select>
</div>
  `,
    styleUrls: ['./chip-select.component.scss']
})
export class ChipSelectComponent implements OnInit {

    constructor() {
    }


    selectedChips: Chip[] = [];

    ngOnInit(): void {
    }

    @Input() selectOptions!: any[];
    @Input() displayoption!: string;

    closeClick(e: any): void {
        this.removeChip(e.id)
    }

    valueChange(e: any): void {
        if (!this.selectedChips.find(x => x.id == e.detail.id)) {
            this.selectedChips.push(e.detail)
        } else {
            this.removeChip(e.detail.id)
        }
    }

    removeChip(chipId: number) {
        this.selectedChips = this.selectedChips.filter(function (x) {
            return x.id != chipId;
        });
    }

}
