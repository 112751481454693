import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'change-log-cell-editor',
    template: `
        <div >
       
            <div class="change-log__text">{{params.data.change}}</div>
       
            <span class="change-log__link"  *ngIf="params.data.fileName" (click)="LinkInvokeParentMethod()"> {{params.data.fileName}}</span>
        
            
        </div>

    `,
    styles: [
        `
            .change-log__text{
                
            }
            .change-log__link{
              display: block;
              color: #217CBB;
              font-size: 0.875rem;
              font-weight: 600;
              line-height: 1.8em;
              cursor:pointer;
            }
        `,
    ],
})
export class ChangeLogCellEditor implements ICellRendererAngularComp {
    public params!: any;
  


    agInit(params: any): void {
        this.params = params;
    }

    public LinkInvokeParentMethod() {
        this.params.context.componentParent.linkMethodParent(
            this.params.node);
    }

    refresh(): boolean {
        return false;
    }
}
