import {createAction, props} from "@ngrx/store";
import {
    AccessRequestByDate, AddOrganizationtoUserInput,
    DeniedUser,
    OrganizationsQuery, OrganizationsQueryVariables
} from "src/app/@core/generated/operations-core-graphql";


export const orgListLoad = createAction('[accessRequest] org List Load',
    props<{ variables: OrganizationsQueryVariables | null }>());

export const orgListSuccess = createAction('[accessRequest] org List Load Success',
    props<{ orgList: OrganizationsQuery }>()
);

export const AccessRequestAddOrgUserLoad = createAction('[accessRequest] Add Organization to user Load',
    props<{ input: AddOrganizationtoUserInput }>())


export const AccessRequestAddOrgUserSuccess = createAction('[accessRequest] Add Organization to user  Success',
    props<{ result: any | null }>());


export const AccessRequestAddOrgUserFail = createAction('[accessRequest] Add Organization to user  Fail',
    props<{ errors: any }>());

export const AccessRequestByDateLoad = createAction('[accessRequest] Access Request by Date Load',
    props<{ input: string | null }>())

export const AccessRequestByDateSuccess = createAction('[accessRequest]  Access Request by Date Success',
    props<{ result: AccessRequestByDate[] }>());


export const AccessRequestRejectOrgUserLoad = createAction('[accessRequest] Reject User Access Request to user Load',
    props<{ input: DeniedUser }>())


export const AccessRequestRejectOrgUserSuccess = createAction('[accessRequest] Add OrgReject User Access Request to user Success',
    props<{ result: any | null }>());


export const AccessRequestRejectOrgUserFail = createAction('[accessRequest] Reject User Access Request to user Fail',
    props<{ errors: any }>());
