<div class="ccx-grid" *ngIf="(dataSource.dataResponse$ | async) as src">
    <div class="ccx-grid-table">

        <ccx-ag-grid style="width: 100%; height: 100%;" domLayout="autoHeight" [rowData]="src.data || []"
            [columnDefs]="dataSource.gridColumnConfigurations" [defaultColDef]="defaultColDef"
            (onCellLinkClick)="onCellClicked($any($event))" (selectionChanged)="selectionChanged.emit($event)"
            (sort)="onGridSort($event)" [pinnedTopRowData]="pinnedTopRowData" [getRowStyle]="getRowStyle"
            [rowClassRules]="rowClassRules" (onGridReady)="gridReady($event)" [rowSelection]="rowSelection"
            [suppressMovableColumns]="suppressMovableColumns" [suppressCellFocus]="suppressCellFocus"
            (modelUpdated)="modelUpdated.emit($event)" [gridOptions]="gridOptions"
            [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
             (firstDataRendered)="onFirstDataRendered($any($event))"
            [headerHeight]="headerHeight"></ccx-ag-grid>

    </div>
    <div class="ccx-grid-pager" *ngIf="maxPage">
        <ccx-pager [maxPage]="maxPage" (currentPageChanged)="onCurrentPageChange($event)"
            [currentPage]="dataSource.pageState?.currentPage || 0"></ccx-pager>
    </div>

</div>