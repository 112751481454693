import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

@Component({
    selector: 'app-modus-select',
    template: `
        <modus-select
            #select
            [disabled]="disabled"
            [errorText]="errorText"
            [formControl]="formControl"
            [helperText]="helperText"
            [label]="label"
            [options]="options"
            [optionsDisplayProp]="optionsDisplayProp"
            [required]="required"
            [selectValue]="value"
            [value]="value"
            [size]="size"
            [validText]="validText"         
            (valueChange)="onSelectValueChange.emit(select.value)"
            >
        </modus-select>
    `,
})
export class ModusSelectComponent {

    @Input() errorText!: string;
    @Input() formControl!: FormControl;
    @Input() helperText!: string;
    @Input() label!: string;
    @Input() options: unknown[] = [];
    @Input() optionsDisplayProp!: string;
    @Input() required!: boolean;
    @Input() size: 'medium' | 'large' = 'medium';
    @Input() validText!: string;
    @Input() value: unknown;
    @Input() disabled!: boolean;
    @Output() onSelectValueChange = new EventEmitter<unknown>();
}


export type GridDataModel<TData> = {
    data: TData[],
    count: number;
}

export function isValidOptionValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        const value = control.value;
        const validValue = value?.id !== '0';

        return !validValue ? {isValid: true} : null;
    }
}




