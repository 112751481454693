import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {Crumb} from '@trimble-oss/modus-web-components';

@Component({
    selector: 'ccx-breadcrumb',
    styleUrls: ['./ccx-breadcrumb.component.scss'],
    templateUrl: './ccx-breadcrumb.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CcxBreadcrumbComponent {

    @Input() crumbs: NavCrumb[] | null = null;
    @Output() crumbClicked = new EventEmitter<CustomEvent>();

    crumbClick($event: CustomEvent) {
        this.crumbClicked.emit($event);
    }
}

export interface NavCrumb {
    text: string;
    routerLink: string | null;
}
