import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';


@Component({
    selector: 'button-cell',
    template: `
        <modus-badge *ngIf="status == 'invited'" color="tertiary">Invited</modus-badge>
    `,
    styles: [
        `
            .btn {
                line-height: 0.5;
            }
        `,
    ],
})
export class invitedBadgeCellEditor implements ICellRendererAngularComp {
    public params!: ICellRendererParams;

    status: 'invited' | 'request' | 'inactive' | 'active' = 'active';

    agInit(params: ICellRendererParams): void {
        this.params = params.data;
        this.status = params.data.status;
    }


    refresh(): boolean {
        return false;
    }
}
