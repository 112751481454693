import {Component} from '@angular/core';

@Component({
    selector: 'ccx-expansion-panel',
    styleUrls: ['ccx-expansion-panel.component.scss'],
    template: `<div class="row">
  <div class="col">
    <div class="tabs">
      <div class="tab">
        <input type="checkbox" id="chck1" (click)="collapse()">
        <label class="tab-label" for="chck1" >
          <ng-content select="[slot=start]"></ng-content>
          <svg-icon [svgClass]="collapsed ? 'svgIcon':'svgIconCollapsed'"  name="down-arrow2"></svg-icon>
        </label>
        <div class="tab-content" [ngClass]="{'collapse': collapsed}">
          <ng-content select="[slot=end]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>`

})

export class CcxExpansionPanel {
    collapsed = true;

    collapse() {

        this.collapsed = !this.collapsed;
        console.log(this.collapsed)
    }
}
