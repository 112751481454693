import {DatePipe} from '@angular/common';
import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {ContentService} from '../services/content.service';


@Pipe({
    name: 'localizedDate',
    pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private contentService: ContentService) {
    }

    transform(value: Date | string, format = 'longDate'): string {
        const datePipe = new DatePipe(this.contentService.getCurrentLanguage() || 'en-US');
        return datePipe.transform(value, format) || '';
    }
}

