import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS, APOLLO_NAMED_OPTIONS, NamedOptions} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, DocumentNode, InMemoryCache, Operation, split} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from '../environments/environment';
import {onError} from '@apollo/client/link/error';
import {ResponseParams, TemplateResponse} from './models/notifications/TemplateResponse';
import {DictionaryService} from './transloco-root.module';
import {getMainDefinition} from '@apollo/client/utilities';
import {TIdTokenService} from './@auth/services/tId.token.service';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {createClient} from 'graphql-ws';

const cmsUri = `${environment.umbracoHostUrl + environment.graphQlCmsApiUrl}/tillage/v1`; // <-- add the URL of the GraphQL server here
const coreUri = `${environment.coreApiUrl}`;
const protocolBeefUri = `${environment.coreProtocolBeefUrl}`;
const protocolNerpUri = `${environment.coreProtocolNerpUrl}`;
const webSocketUri = `${environment.webSocketHost}`;

function isWebSocketOperation(op: Operation) {
    const {kind, operation}: any = getMainDefinition(op.query);
    return kind === 'OperationDefinition' && operation === 'subscription';
}

export function createApollo(
    httpLink: HttpLink,
    dict: DictionaryService,
    tokenService: TIdTokenService): NamedOptions {

    const addTokenRequestFunc = async () => {
        const token = tokenService.getCcxToken();
        return {
            Authorization: `Bearer ${token?.tokens?.token}`
        }
    };

    const errorLink = onError(({
                                   graphQLErrors,
                                   networkError,
                                   response,
                                   operation,
                                   forward
                               }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({message, extensions}) => {
                const value: TemplateResponse = {
                    templateId: message,
                    parameters: extensions['data'] as ResponseParams
                }
                let translationValue: string = '';
                if (value.templateId) {
                    translationValue = dict.getEntry(value.templateId);
                    const responseParams: any = value.parameters;
                    let formatParams: any;

                    if (responseParams) {

                        if (Array.isArray(responseParams)) {
                            formatParams = responseParams
                        } else {
                            const params = Object.entries(responseParams);
                            formatParams = params.map(([k, v]) => ({key: k ?? "", value: v ?? ""}));
                        }

                        formatParams.forEach(
                            (param: { [x: string]: string; }) => {
                                translationValue = translationValue.replace(`{{${param['key']}}}`, param['value'])
                            }
                        );
                    }
                }
            });

        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';

    const coreWs = new GraphQLWsLink(
        createClient({
            url: `${protocol}://${webSocketUri}${coreUri}`,
            connectionParams: addTokenRequestFunc
        })
    );

    const coreLink = ApolloLink.from([
        errorLink,
        split(
            isWebSocketOperation,
            coreWs,
            httpLink.create({uri: coreUri})
        )
    ]);

    const cmsLink = ApolloLink.from([
        errorLink,
        httpLink.create({uri: cmsUri})
    ]);

    const beefWs = new GraphQLWsLink(
        createClient({
            url: `${protocol}://${webSocketUri}${protocolBeefUri}`,
            connectionParams: addTokenRequestFunc
        })
    );

    const beefLink = ApolloLink.from([
        errorLink,
        split(
            isWebSocketOperation,
            beefWs,
            httpLink.create({uri: protocolBeefUri})
        )
    ]);

    const nerpWs = new GraphQLWsLink(
        createClient({
            url: `${protocol}://${webSocketUri}${protocolNerpUri}`,
            connectionParams: addTokenRequestFunc
        })
    );
    const nerpLink = ApolloLink.from([
        errorLink,
        split(
            isWebSocketOperation,
            nerpWs,
            httpLink.create({uri: protocolNerpUri})
        )
    ]);

    return {
        default: {
            link: coreLink,
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {fetchPolicy: 'network-only', errorPolicy: 'none'},
                query: {fetchPolicy: 'network-only', errorPolicy: 'none'}
            }
        },
        cms: {
            link: cmsLink,
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {fetchPolicy: 'network-only', errorPolicy: 'none'},
                query: {fetchPolicy: 'network-only', errorPolicy: 'none'}
            }
        },
        beef: {
            link: beefLink,
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {fetchPolicy: 'network-only', errorPolicy: 'none'},
                query: {fetchPolicy: 'network-only', errorPolicy: 'none'}
            }
        },
        nerp: {
            link: nerpLink,
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {fetchPolicy: 'network-only', errorPolicy: 'none'},
                query: {fetchPolicy: 'network-only', errorPolicy: 'none'}
            }
        }
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink, DictionaryService, TIdTokenService],
        },
    ],
})
export class CmsGraphQLModule {
}
