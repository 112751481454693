import {Injectable} from '@angular/core';
import {PowerBiEmbedToken, PowerBiEmbedTokenInput} from '../../@core/generated/operations-core-graphql';

@Injectable()
export class PowerBiEmbedTokenService {

    constructor() {

    }

    storeToken(token: PowerBiEmbedToken): void {
        localStorage.setItem('powerBiEmbedToken', JSON.stringify(token));
    }

    getValidToken(): PowerBiEmbedToken | null {
        let token: PowerBiEmbedToken;

        const tokenJson = localStorage.getItem('powerBiEmbedToken') ?? '';
        try {
            token = JSON.parse(tokenJson);
            if (!token || !token.hasOwnProperty('expiration')) {
                return null;
            }
            const expiration = new Date(token.expiration);
            // check if expiration date in utc time is greater than current date
            if (expiration.getTime() > new Date().getTime()) {
                return token;
            }
            return null;
        } catch (e) {
        }
        return null;
    }
}
