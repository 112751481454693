<div class="ag-grid">
    <div class="grid">
        <ag-grid-angular class="ag-theme-alpine" domLayout="autoHeight" [rowData]="rowData" [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [gridOptions]="gridOptions"
            (cellEditingStopped)="onCellEditingStopped($event)" (gridReady)="gridReady($event)" [context]="context"
            (updatedData)="onUpdatedData()" [rowSelection]="rowSelection" [enableCellTextSelection]='true'
            [suppressRowClickSelection]='true' (selectionChanged)="selectionChanged.emit($event)"
            (cellClicked)="oncellClicked($event)" (sortChanged)="onSortChanged($event)"
            [pinnedTopRowData]="pinnedTopRowData" [getRowStyle]="getRowStyle" [rowClassRules]="rowClassRules"
            [rowSelection]="rowSelection" [suppressMovableColumns]="suppressMovableColumns"
            (modelUpdated)="modelUpdated.emit($event)" [headerHeight]="headerHeight"
            [suppressCellFocus]="suppressCellFocus" (rowValueChanged)="onRowValueChanged($event)"
            [overlayLoadingTemplate]="overlayLoadingTemplate" [overlayNoRowsTemplate]="overlayNoRowsTemplate">
        </ag-grid-angular>
    </div>
    <br><br>
    <div class="actions">
        <ccx-button *ngIf="addingRows" size="medium" buttonStyle="borderless" (click)="addRow()">New Constant
        </ccx-button>
    </div>
</div>
