<div class="card" (click)="onClickCounter(counterInfo)">
    <div class="card-content">
        <div [class]="counterSizeClass">
            <p class="content-name">{{ counterInfo.name| transloco }}</p>

            <p *ngIf="counterInfo.disableFormat ; else noformatValue" class="content-count">{{ counterInfo.count}}</p>

            <ng-template #noformatValue>
                <p class="content-count">{{ counterInfo.count | number:'1.0-0':'en-US'}}</p>
            </ng-template>

        </div>
    </div>
</div>