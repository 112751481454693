import {Component, Input, OnInit} from '@angular/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {Observable} from 'rxjs';
import {formatBytes, formatDateTime} from '../../functions/value-formatters';
import { DocumentManagerFile } from '../document-manager-shell/+state/document-manager.reducer';

@Component({
    selector: 'ccx-file-browser',
    template: `
        <ag-grid-angular
            class="ag-theme-alpine"
            domLayout="autoHeight"
            [rowData]="files"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    `,
    styles: [
        `
`
    ]
})
export class CcxFileBrowserComponent implements OnInit {
    @Input() files: DocumentManagerFile[] = [];

    columnDefs: ColDef[] = [
        {headerName: 'name', editable: false, field: 'name'},
        {
            headerName: 'Download',
            editable: false,
            field: 'Link',
            cellRenderer: (params: any) => {
                // escape quote character from params.data.url
                const escapedPath = params.data.url.replace(/"/g, '%22');

                return `<a href="${escapedPath}" target="_blank" rel="noopener">Download</a>`
            }
        },
        {headerName: 'size', editable: false, field: 'size', valueFormatter: (params: any) => formatBytes(params.value)},
        {headerName: 'date', editable: false, field: 'date', valueFormatter: (params: any) => formatDateTime(params.value)},
    ];

    gridOptions : GridOptions = {
        readOnlyEdit: true,
        stopEditingWhenCellsLoseFocus: true,
        context: {
            componentParent: this
        }
    }
    private gridApi!: GridApi<any>;

    constructor() {
    }

    ngOnInit(): void {
    }

    onGridReady($event: GridReadyEvent<any>) {
        this.gridApi = $event.api;
        this.gridApi.sizeColumnsToFit();
    }
}
