import {gql} from 'apollo-angular';
import {Injectable} from '@angular/core';
import * as Apollo from 'apollo-angular';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
    DateTime: any;
    Guid: any;
};

export type BasePage = {
    alternateContent?: Maybe<Scalars['String']>;
    body?: Maybe<Scalars['String']>;
    footer?: Maybe<Scalars['String']>;
    header: Scalars['String'];
    menuIcon: Scalars['String'];
    navigationPath: Scalars['String'];
    pageTitle: Scalars['String'];
    redirectPath?: Maybe<Scalars['String']>;
    visibleOnSitemap: Scalars['Boolean'];
};

export type BeefPage = BasePage & Roles & {
    __typename?: 'BeefPage';
    alternateContent?: Maybe<Scalars['String']>;
    body?: Maybe<Scalars['String']>;
    childIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
    footer?: Maybe<Scalars['String']>;
    header: Scalars['String'];
    id?: Maybe<Scalars['Int']>;
    key?: Maybe<Scalars['Guid']>;
    menuIcon: Scalars['String'];
    navigationPath: Scalars['String'];
    pageTitle: Scalars['String'];
    parentId?: Maybe<Scalars['Int']>;
    redirectPath?: Maybe<Scalars['String']>;
    roleCollection?: Maybe<Array<Maybe<RoleList>>>;
    shurgurbles?: Maybe<Scalars['String']>;
    sortOrder?: Maybe<Scalars['Int']>;
    visibleOnSitemap: Scalars['Boolean'];
};

export type ContentQuery = {
    __typename?: 'ContentQuery';
    beefPage?: Maybe<Array<Maybe<BeefPage>>>;
    dictionaryItems?: Maybe<Array<Maybe<DictionaryItem>>>;
    i18n?: Maybe<Array<Maybe<KeyValue>>>;
    moduleValidation?: Maybe<Array<Maybe<ModuleValidation>>>;
    nitrogenPage?: Maybe<Array<Maybe<NitrogenPage>>>;
    pageNotFound?: Maybe<Array<Maybe<PageNotFound>>>;
    simplePage?: Maybe<Array<Maybe<SimplePage>>>;
    siteMap?: Maybe<JsonResponse>;
    tillagePage?: Maybe<Array<Maybe<TillagePage>>>;
    usersInvitation?: Maybe<Array<Maybe<UsersInvitation>>>;
};


export type ContentQueryBeefPageArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    keys?: InputMaybe<Array<InputMaybe<Scalars['Guid']>>>;
    lang?: InputMaybe<Scalars['String']>;
};


export type ContentQueryDictionaryItemsArgs = {
    items: Array<InputMaybe<Scalars['String']>>;
};


export type ContentQueryI18nArgs = {
    isoCode?: InputMaybe<Scalars['String']>;
    keys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    parentKey: Scalars['String'];
};


export type ContentQueryModuleValidationArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    key: Scalars['Guid'];
    lang?: InputMaybe<Scalars['String']>;
};


export type ContentQueryNitrogenPageArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    keys?: InputMaybe<Array<InputMaybe<Scalars['Guid']>>>;
    lang?: InputMaybe<Scalars['String']>;
};


export type ContentQueryPageNotFoundArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    keys?: InputMaybe<Array<InputMaybe<Scalars['Guid']>>>;
    lang?: InputMaybe<Scalars['String']>;
};


export type ContentQuerySimplePageArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    keys?: InputMaybe<Array<InputMaybe<Scalars['Guid']>>>;
    lang?: InputMaybe<Scalars['String']>;
};


export type ContentQuerySiteMapArgs = {
    key: Scalars['Guid'];
    lang?: InputMaybe<Scalars['String']>;
};


export type ContentQueryTillagePageArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    keys?: InputMaybe<Array<InputMaybe<Scalars['Guid']>>>;
    lang?: InputMaybe<Scalars['String']>;
};


export type ContentQueryUsersInvitationArgs = {
    ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
    keys?: InputMaybe<Array<InputMaybe<Scalars['Guid']>>>;
    lang?: InputMaybe<Scalars['String']>;
};

export type DictionaryItem = {
    __typename?: 'DictionaryItem';
    createDate: Scalars['DateTime'];
    deleteDate?: Maybe<Scalars['DateTime']>;
    hasIdentity: Scalars['Boolean'];
    id: Scalars['Int'];
    itemKey: Scalars['String'];
    parentId?: Maybe<Scalars['ID']>;
    translations?: Maybe<Array<Maybe<DictionaryTranslation>>>;
    updateDate: Scalars['DateTime'];
};

export type DictionaryTranslation = {
    __typename?: 'DictionaryTranslation';
    createDate: Scalars['DateTime'];
    deleteDate?: Maybe<Scalars['DateTime']>;
    hasIdentity: Scalars['Boolean'];
    id: Scalars['Int'];
    language?: Maybe<Language>;
    /** @deprecated This will be replaced by language ISO code in V13. */
    languageId: Scalars['Int'];
    updateDate: Scalars['DateTime'];
    value: Scalars['String'];
};

export type JsonResponse = {
    __typename?: 'JsonResponse';
    json: Scalars['String'];
};

export type KeyValue = {
    __typename?: 'KeyValue';
    key: Scalars['String'];
    value: Scalars['String'];
};

export type Language = {
    __typename?: 'Language';
    createDate: Scalars['DateTime'];
    deleteDate?: Maybe<Scalars['DateTime']>;
    id: Scalars['Int'];
    isDefault: Scalars['Boolean'];
    isMandatory: Scalars['Boolean'];
    isoCode: Scalars['String'];
    updateDate: Scalars['DateTime'];
};

export type ModuleValidation = {
    __typename?: 'ModuleValidation';
    allModules?: Maybe<Array<Maybe<ModulesInfo>>>;
    hasAccess?: Maybe<Array<Maybe<ModulesInfo>>>;
};

export type ModulesInfo = {
    __typename?: 'ModulesInfo';
    key: Scalars['ID'];
    name: Scalars['String'];
};

export type NitrogenPage = BasePage & Roles & {
    __typename?: 'NitrogenPage';
    alternateContent?: Maybe<Scalars['String']>;
    body?: Maybe<Scalars['String']>;
    childIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
    footer?: Maybe<Scalars['String']>;
    header: Scalars['String'];
    id?: Maybe<Scalars['Int']>;
    key?: Maybe<Scalars['Guid']>;
    menuIcon: Scalars['String'];
    navigationPath: Scalars['String'];
    pageTitle: Scalars['String'];
    parentId?: Maybe<Scalars['Int']>;
    redirectPath?: Maybe<Scalars['String']>;
    roleCollection?: Maybe<Array<Maybe<RoleList>>>;
    sortOrder?: Maybe<Scalars['Int']>;
    visibleOnSitemap: Scalars['Boolean'];
};

/** PageNotFound */
export type PageNotFound = BasePage & {
    __typename?: 'PageNotFound';
    alternateContent?: Maybe<Scalars['String']>;
    body?: Maybe<Scalars['String']>;
    childIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
    footer?: Maybe<Scalars['String']>;
    header: Scalars['String'];
    id?: Maybe<Scalars['Int']>;
    key?: Maybe<Scalars['Guid']>;
    menuIcon: Scalars['String'];
    navigationPath: Scalars['String'];
    pageTitle: Scalars['String'];
    parentId?: Maybe<Scalars['Int']>;
    redirectPath?: Maybe<Scalars['String']>;
    sortOrder?: Maybe<Scalars['Int']>;
    visibleOnSitemap: Scalars['Boolean'];
};

export type RoleList = {
    __typename?: 'RoleList';
    childIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
    id?: Maybe<Scalars['Int']>;
    key?: Maybe<Scalars['Guid']>;
    parentId?: Maybe<Scalars['Int']>;
    selectAccessLevel?: Maybe<Array<Maybe<Scalars['String']>>>;
    selectRole?: Maybe<Array<Maybe<Scalars['String']>>>;
    sortOrder?: Maybe<Scalars['Int']>;
};

export type Roles = {
    roleCollection?: Maybe<Array<Maybe<RoleList>>>;
};

export type SimplePage = BasePage & Roles & {
    __typename?: 'SimplePage';
    alternateContent?: Maybe<Scalars['String']>;
    body?: Maybe<Scalars['String']>;
    childIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
    footer?: Maybe<Scalars['String']>;
    header: Scalars['String'];
    id?: Maybe<Scalars['Int']>;
    key?: Maybe<Scalars['Guid']>;
    menuIcon: Scalars['String'];
    navigationPath: Scalars['String'];
    pageTitle: Scalars['String'];
    parentId?: Maybe<Scalars['Int']>;
    redirectPath?: Maybe<Scalars['String']>;
    roleCollection?: Maybe<Array<Maybe<RoleList>>>;
    sortOrder?: Maybe<Scalars['Int']>;
    visibleOnSitemap: Scalars['Boolean'];
};

export type TillagePage = BasePage & Roles & {
    __typename?: 'TillagePage';
    alternateContent?: Maybe<Scalars['String']>;
    body?: Maybe<Scalars['String']>;
    childIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
    footer?: Maybe<Scalars['String']>;
    header: Scalars['String'];
    id?: Maybe<Scalars['Int']>;
    key?: Maybe<Scalars['Guid']>;
    menuIcon: Scalars['String'];
    navigationPath: Scalars['String'];
    pageTitle: Scalars['String'];
    parentId?: Maybe<Scalars['Int']>;
    redirectPath?: Maybe<Scalars['String']>;
    roleCollection?: Maybe<Array<Maybe<RoleList>>>;
    sortOrder?: Maybe<Scalars['Int']>;
    visibleOnSitemap: Scalars['Boolean'];
};

export type UsersInvitation = {
    __typename?: 'UsersInvitation';
    childIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
    footerText: Scalars['String'];
    id?: Maybe<Scalars['Int']>;
    key?: Maybe<Scalars['Guid']>;
    needAccessText: Scalars['String'];
    needRoleText: Scalars['String'];
    parentId?: Maybe<Scalars['Int']>;
    requestSentText: Scalars['String'];
    sortOrder?: Maybe<Scalars['Int']>;
    titleText: Scalars['String'];
};

export type GetTranslationsByLangQueryVariables = Exact<{
    lang: Scalars['String'];
    parentKey: Scalars['String'];
}>;


export type GetTranslationsByLangQuery = {
    __typename?: 'ContentQuery',
    i18n?: Array<{ __typename?: 'KeyValue', key: string, value: string } | null> | null
};

export type GetUsersInvitationQueryVariables = Exact<{
    keys: Array<InputMaybe<Scalars['Guid']>> | InputMaybe<Scalars['Guid']>;
    lang?: InputMaybe<Scalars['String']>;
}>;


export type GetUsersInvitationQuery = {
    __typename?: 'ContentQuery',
    usersInvitation?: Array<{
        __typename?: 'UsersInvitation',
        needAccessText: string,
        requestSentText: string,
        needRoleText: string,
        titleText: string,
        footerText: string
    } | null> | null
};

export type BeefPageQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
    keys?: InputMaybe<Array<InputMaybe<Scalars['Guid']>> | InputMaybe<Scalars['Guid']>>;
}>;


export type BeefPageQuery = {
    __typename?: 'ContentQuery',
    beefPage?: Array<{
        __typename?: 'BeefPage',
        id?: number | null,
        parentId?: number | null,
        childIds?: Array<number | null> | null,
        key?: any | null,
        sortOrder?: number | null,
        alternateContent?: string | null,
        body?: string | null,
        footer?: string | null,
        header: string,
        navigationPath: string,
        pageTitle: string,
        redirectPath?: string | null,
        visibleOnSitemap: boolean,
        menuIcon: string,
        roleCollection?: Array<{
            __typename?: 'RoleList',
            selectAccessLevel?: Array<string | null> | null,
            selectRole?: Array<string | null> | null
        } | null> | null
    } | null> | null
};

export type BeefProtocolPagesQueryVariables = Exact<{
    protocolPageKeys?: InputMaybe<Array<InputMaybe<Scalars['Guid']>> | InputMaybe<Scalars['Guid']>>;
    lang?: InputMaybe<Scalars['String']>;
    protocolPageIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;


export type BeefProtocolPagesQuery = {
    __typename?: 'ContentQuery',
    beefPage?: Array<{
        __typename?: 'BeefPage',
        id?: number | null,
        parentId?: number | null,
        childIds?: Array<number | null> | null,
        key?: any | null,
        sortOrder?: number | null,
        alternateContent?: string | null,
        body?: string | null,
        footer?: string | null,
        header: string,
        navigationPath: string,
        pageTitle: string,
        redirectPath?: string | null,
        visibleOnSitemap: boolean,
        menuIcon: string,
        roleCollection?: Array<{
            __typename?: 'RoleList',
            selectAccessLevel?: Array<string | null> | null,
            selectRole?: Array<string | null> | null
        } | null> | null
    } | null> | null
};

export type NitrogenProtocolPagesQueryVariables = Exact<{
    protocolPageKeys?: InputMaybe<Array<InputMaybe<Scalars['Guid']>> | InputMaybe<Scalars['Guid']>>;
    lang?: InputMaybe<Scalars['String']>;
    protocolPageIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
    adminPageKey?: InputMaybe<Array<InputMaybe<Scalars['Guid']>> | InputMaybe<Scalars['Guid']>>;
    adminPageIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
}>;


export type NitrogenProtocolPagesQuery = {
    __typename?: 'ContentQuery',
    simplePage?: Array<{
        __typename?: 'SimplePage',
        pageTitle: string,
        navigationPath: string,
        visibleOnSitemap: boolean,
        menuIcon: string,
        childIds?: Array<number | null> | null,
        parentId?: number | null,
        id?: number | null,
        key?: any | null,
        header: string
    } | null> | null,
    nitrogenPage?: Array<{
        __typename?: 'NitrogenPage',
        pageTitle: string,
        navigationPath: string,
        visibleOnSitemap: boolean,
        menuIcon: string,
        childIds?: Array<number | null> | null,
        parentId?: number | null,
        id?: number | null,
        key?: any | null,
        header: string
    } | null> | null
};

export type ModuleValidationQueryVariables = Exact<{
    key: Scalars['Guid'];
    ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
    lang?: InputMaybe<Scalars['String']>;
}>;


export type ModuleValidationQuery = {
    __typename?: 'ContentQuery',
    moduleValidation?: Array<{
        __typename?: 'ModuleValidation',
        allModules?: Array<{ __typename?: 'ModulesInfo', key: string, name: string } | null> | null,
        hasAccess?: Array<{ __typename?: 'ModulesInfo', key: string, name: string } | null> | null
    } | null> | null
};

export type PageNotFoundQueryVariables = Exact<{
    keys?: InputMaybe<Array<InputMaybe<Scalars['Guid']>> | InputMaybe<Scalars['Guid']>>;
    ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>>;
    lang?: InputMaybe<Scalars['String']>;
}>;


export type PageNotFoundQuery = {
    __typename?: 'ContentQuery',
    pageNotFound?: Array<{
        __typename?: 'PageNotFound',
        pageTitle: string,
        navigationPath: string,
        visibleOnSitemap: boolean,
        header: string,
        menuIcon: string,
        redirectPath?: string | null,
        body?: string | null,
        footer?: string | null,
        alternateContent?: string | null,
        id?: number | null,
        key?: any | null,
        parentId?: number | null,
        sortOrder?: number | null,
        childIds?: Array<number | null> | null
    } | null> | null
};

export const GetTranslationsByLangDocument = gql`
    query getTranslationsByLang($lang: String!, $parentKey: String!) {
        i18n(isoCode: $lang, parentKey: $parentKey) {
            key
            value
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class GetTranslationsByLangGQL extends Apollo.Query<GetTranslationsByLangQuery, GetTranslationsByLangQueryVariables> {
    override document = GetTranslationsByLangDocument;
    override client = 'cms';

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const GetUsersInvitationDocument = gql`
    query getUsersInvitation($keys: [Guid]!, $lang: String) {
        usersInvitation(keys: $keys, lang: $lang) {
            needAccessText
            requestSentText
            needRoleText
            titleText
            footerText
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class GetUsersInvitationGQL extends Apollo.Query<GetUsersInvitationQuery, GetUsersInvitationQueryVariables> {
    override document = GetUsersInvitationDocument;
    override client = 'cms';

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const BeefPageDocument = gql`
    query BeefPage($lang: String, $ids: [Int], $keys: [Guid]) {
        beefPage(lang: $lang, keys: $keys, ids: $ids) {
            id
            parentId
            childIds
            key
            sortOrder
            roleCollection {
                selectAccessLevel
                selectRole
            }
            alternateContent
            body
            footer
            header
            navigationPath
            pageTitle
            redirectPath
            visibleOnSitemap
            menuIcon
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class BeefPageGQL extends Apollo.Query<BeefPageQuery, BeefPageQueryVariables> {
    override document = BeefPageDocument;
    override client = 'cms';

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const BeefProtocolPagesDocument = gql`
    query BeefProtocolPages($protocolPageKeys: [Guid], $lang: String, $protocolPageIds: [Int]) {
        beefPage(keys: $protocolPageKeys, lang: $lang, ids: $protocolPageIds) {
            id
            parentId
            childIds
            key
            sortOrder
            roleCollection {
                selectAccessLevel
                selectRole
            }
            alternateContent
            body
            footer
            header
            navigationPath
            pageTitle
            redirectPath
            visibleOnSitemap
            menuIcon
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class BeefProtocolPagesGQL extends Apollo.Query<BeefProtocolPagesQuery, BeefProtocolPagesQueryVariables> {
    override document = BeefProtocolPagesDocument;
    override client = 'cms';

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const NitrogenProtocolPagesDocument = gql`
    query NitrogenProtocolPages($protocolPageKeys: [Guid], $lang: String, $protocolPageIds: [Int], $adminPageKey: [Guid], $adminPageIds: [Int]) {
        simplePage(keys: $adminPageKey, lang: $lang, ids: $adminPageIds) {
            pageTitle
            navigationPath
            visibleOnSitemap
            menuIcon
            childIds
            parentId
            id
            key
            header
        }
        nitrogenPage(keys: $protocolPageKeys, lang: $lang, ids: $protocolPageIds) {
            pageTitle
            navigationPath
            visibleOnSitemap
            menuIcon
            childIds
            parentId
            id
            key
            header
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class NitrogenProtocolPagesGQL extends Apollo.Query<NitrogenProtocolPagesQuery, NitrogenProtocolPagesQueryVariables> {
    override document = NitrogenProtocolPagesDocument;
    override client = 'cms';

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const ModuleValidationDocument = gql`
    query ModuleValidation($key: Guid!, $ids: [Int], $lang: String) {
        moduleValidation(key: $key, ids: $ids, lang: $lang) {
            allModules {
                key
                name
            }
            hasAccess {
                key
                name
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ModuleValidationGQL extends Apollo.Query<ModuleValidationQuery, ModuleValidationQueryVariables> {
    override document = ModuleValidationDocument;
    override client = 'cms';

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const PageNotFoundDocument = gql`
    query PageNotFound($keys: [Guid], $ids: [Int], $lang: String) {
        pageNotFound(keys: $keys, ids: $ids, lang: $lang) {
            pageTitle
            navigationPath
            visibleOnSitemap
            header
            menuIcon
            redirectPath
            body
            footer
            alternateContent
            id
            key
            parentId
            sortOrder
            childIds
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class PageNotFoundGQL extends Apollo.Query<PageNotFoundQuery, PageNotFoundQueryVariables> {
    override document = PageNotFoundDocument;
    override client = 'cms';

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
