import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';


interface CustomCellRendererParams extends ICellRendererParams {
    onClickAccept: (value: string) => boolean;

}

@Component({
    selector: 'button-cell',
    template: `
        <ccx-button *ngIf="status" size="small" buttonStyle="borderless"
                    (buttonClick)="acceptRequest()">
            Accept
        </ccx-button>
        <modus-badge *ngIf="!status" color="tertiary">Accepted</modus-badge>
    `,
    styles: [
        `
            .btn {
                line-height: 0.5;
            }
        `,
    ],
})
export class acceptButtonCellEditor implements ICellRendererAngularComp {
    public params!: CustomCellRendererParams;
    status = true;

    agInit(params: CustomCellRendererParams): void {
        this.params = params;
        this.status = this.params.data.action === 'Accepted' ? false : true;

    }

    public acceptRequest() {

        this.params.onClickAccept(this.params.data.email);
    }


    refresh(): boolean {
        return false;
    }
}
