import moment from 'moment';

export function decimalValueFormatter(value: any, decimals: number = 2) {

    if (value === undefined || value === null || value === '') {
        return '';
    }
    const number = +value;
    return (+number.toFixed(decimals)).toLocaleString("en", { maximumFractionDigits: decimals })
}

export function formatBytes(bytes: number, decimals: number = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function formatDateTime(value: string) {
    return moment(value).format('MMMM DD, yyyy HH:mm:ss')
}
