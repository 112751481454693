import { Injectable } from "@angular/core";
import { CreateDocumentUploadLinkGQL } from "../generated/operations-core-graphql";
import { first, map } from "rxjs";

@Injectable()
export class DocumentService {

	constructor(readonly gqlCreateDocumentDownloadLink: CreateDocumentUploadLinkGQL
	) {

	}

	public downloadDocument(blobIdentifier: string) {

		this.gqlCreateDocumentDownloadLink.watch({ blobIdentifier })
			.valueChanges.pipe(
				first(),
				map(e => e.data.createDocumentUploadLink)
			).subscribe(url => {
				window.open(url, '_blank');
			})
	}
}