import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-float-modal',
    styleUrls: ['./float-modal.component.scss'],
    template: `
   <div class="float-modal">
    <div class=" {{'float-modal-content float-modal-size-' + size }}">
      <ng-content></ng-content>
    </div>
   </div>
`
})
export class FloatModalComponent implements OnInit {

    @Input() size!: 'small' | 'medium' | 'large'
    @Output() openclose = new EventEmitter<string>();

    ngOnInit(): void {
    }

    OpenClose(buttonseleted: string): void {
        console.log(buttonseleted)
        this.openclose.emit(buttonseleted);
    }
}
