<app-float-modal [size]="size">
    <div class="ccx-modal">
        <div class="header">
            <div class="closeicon">
                <svg (click)="chageModalStatus()" class="icon-close" height="20" width="20" viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path
                            d="M19 7.30929L17.6907 6L12.5 11.1907L7.30929 6L6 7.30929L11.1907 12.5L6 17.6907L7.30929 19L12.5 13.8093L17.6907 19L19 17.6907L13.8093 12.5L19 7.30929Z"
                            fill="#6A6976"></path>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="24" height="24" fill="white"></rect>
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div class="header-title">
                <div class="header-text">{{ headerText }}</div>
                <div class="subheader-text">{{ subHeaderText }}</div>
            </div>
        </div>


        <div class="body">
            <ng-content></ng-content>
        </div>
        <div class="footer">
            <ccx-button buttonStyle="outline" color="secondary" (buttonClick)="handleSecondaryButton()">
                {{ secondaryButtonText }}
            </ccx-button>
            <ccx-button *ngIf="isDelete ; else notDelete" type="submit" [disabled]="submitDisabled" class="button"
                        size="medium" color="danger"
                        (buttonClick)="handlePrimaryAction()">
                {{ primaryButtonText }}
            </ccx-button>
            <ng-template #notDelete>
                <ccx-button type="submit" [disabled]="submitDisabled" class="button" size="medium" color="primary"
                            (buttonClick)="handlePrimaryAction()">
                    {{ primaryButtonText }}
                </ccx-button>
            </ng-template>


        </div>
    </div>
</app-float-modal>
