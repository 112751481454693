<div class="card">
    <p class="title">{{ 'Projects'| transloco }}</p>
    <div *ngFor="let data of projectsData">
        <div class="row">
            <modus-badge color="{{data.type}}" size="small" class="left-side">{{ data.status }}</modus-badge>
            <h1 class="right-side">{{ data.projectName }}</h1>
        </div>
        <hr class="solid divider">
    </div>
</div>
