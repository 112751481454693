<nav aria-label="Breadcrumb" class="breadcrumb">
    <ul>
        <li *ngFor="let c of crumbs">
            <ng-container *ngIf="c.routerLink; then withLink; else textOnly">
            </ng-container>
            <ng-template #withLink>
                <a [routerLink]="c.routerLink">{{ c.text }}</a>
            </ng-template>
            <ng-template #textOnly>
                <span>{{ c.text }}</span>
            </ng-template>
        </li>
    </ul>
</nav>
