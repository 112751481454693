<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'Edit Coefficients' | transloco }}</h5>
    </div>
    <div class="modal-body" cdk-scrollable>
        <app-spinner [isWorking]="isWorking()"></app-spinner>
        <div class="grid__container" *ngIf="coefficientFormGroupDef() as formGroupDef">
            <ccx-table [formGroupDef]="formGroupDef"
                       [columnDefs]="colDefs()"
                       [rowData]="coefficients()"
                       [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
                       (onCellEditRequestEvent)="cellEditRequest($event)"
                       [formArray]="coefficientsFormArray"
            ></ccx-table>
            <div class="pt-1">
                <ccx-button size="medium" buttonStyle="borderless"
                            (buttonClick)="addCoefficient()">{{ 'Add Coefficient'| transloco }}
                </ccx-button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <ccx-button size="medium" buttonStyle="outline" color="secondary"
                    (buttonClick)="dialogClose()">{{ 'Cancel'| transloco }}
        </ccx-button>
        <ccx-button size="medium" color="primary" [disabled]="!coefficientsFormArray.valid"
                    (buttonClick)="saveCoefficients()">{{ 'Save'| transloco }}
        </ccx-button>
    </div>
</div>
