<div style="position:relative" *ngIf="(usersRequestingByDate$ | async) as datainfo">
    <p class="title-ar">{{ 'Access Request' | transloco }}</p>
    <div class="menu-list" *ngFor="let obj of datainfo">
        <div class="menu-item-title">{{ obj?.title  | localizedDate }}</div>
        <div class="menu-item menu-list-content" *ngFor="let objdata of obj?.data">
            <ul>
                <li><b>{{ objdata?.userName }}</b> ( {{ objdata?.userEmail }} ) {{ objdata?.description | transloco }}
                </li>
                <li class="btn">
                    <ccx-button size="small" buttonStyle="outline" bcolor="primary" class="m-1"
                                (buttonClick)="rejectButtonAction(objdata.userEmail)">{{ "Reject"| transloco }}
                    </ccx-button>
                    <ccx-button size="small" color="primary" class="m-1"
                                (buttonClick)="acceptButtonAction(objdata)">{{ "Accept"| transloco }}
                    </ccx-button>

                </li>
            </ul>
        </div>
    </div>
</div>


<form [formGroup]="organizationForm" *ngIf=" showModal && (organizationlist$ | async) as organizations">
    <app-confirmation-modal
        [size]="'large'"
        [submitDisabled]="!organizationForm.valid"
        (onCloseModal)="onCloseModal()"
        (onClickPrimaryButton)="onAsignOrganizationtoUser()"
        (onClickSecondaryButton)="accessRequestCancel()"
        [headerText]="headerTextModal"
        [subHeaderText]="subHeaderTextModal"
        primaryButtonText="{{'Accept'| transloco}}"
        secondaryButtonText="{{'Cancel'| transloco}}">

        <app-modus-select
            [formControl]="$any(organizationForm).controls['organization']"
            id="select-projects"
            size="large"
            label="{{'Organization'| transloco}}"
            [options]="organizations"
            [optionsDisplayProp]="'display'"
            [required]="true"
            (onSelectValueChange)="valueChange($event)"
            [value]="organization"
            name="Organization" ngDefaultControl
        >
        </app-modus-select>
    </app-confirmation-modal>
</form>


<div class="spinner" *ngIf="(isWorking$ | async) as isWorking">
    <app-spinner [isWorking]="isWorking"></app-spinner>
</div>
