import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ccx-select',
    template: `
        <div class="select__container">
            <select (change)="change($event)" [(ngModel)]="selected">
                <option *ngFor="let e of _selectOptions" [value]="e.id">{{ e.text }}</option>
            </select>
        </div>
    `,
    styles: [`
        .select__container {
            & select {
                display: inline-block;
                width: 100%;
                height: calc(2em + .375rem + 2px);
                padding: .1875rem 1.75rem .1875rem .75rem;
                font-size: .75rem;
                font-weight: 400;
                line-height: 2;
                color: var(--modus-gray-6, #6a6e79);
                vertical-align: middle;
                background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.06' height='6.28'%3E%3Cpath data-name='Path 3707' d='M.53.53l5 5 5-5' fill='none' stroke='%236a6e79' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E") no-repeat right .75rem center/11px 6.26px;
                border: 1px solid #e0e1e9;
                border-radius: .125rem;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none
            }
        }
    `
    ]
})
export class CcxSelectComponent {
    public _selectOptions: SelectItem[] = [];
    @Input() set selectOptions(value: SelectItem[] | string[] | number[]) {
        this._selectOptions = value.map((e: SelectItem | string | number) => new SelectItem(e));
    }

    @Input() selected: any;
    @Output() selectedChange = new EventEmitter<any>();
    @Output() selectionChanged = new EventEmitter<any>();

    change($event: Event) {
        this.selectionChanged.emit($event);
    }
}

export class SelectItem {
    id: string | number;
    text: string | number;

    constructor(item: { id: string | number, text: string | number } | string | number) {
        if (typeof item === 'string' || typeof item === 'number') {
            this.id = item;
            this.text = item;
        } else {
            this.id = item.id;
            this.text = item.text;
        }
    }
}
