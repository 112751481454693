import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';

@Component({
    selector: 'ccx-pager',
    styles: [`
        .hoverable {
            cursor: pointer;
        }

        .hoverable:hover {
            background-color: #e0e1e9;
        }

        ol {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            padding: 0px;
        }

        li {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            list-style-type: none;
            min-width: min-content;
            font-size: 0.875rem;
            height: 2rem;
            width: 2rem;
        }

        li.disabled {
            opacity: 0.3;
        }

        li.active {
            background-color: var(--modus-pagination-active-bg, #dcedf9);
            color: var(--modus-pagination-active-color, #217cbb);
        }
    `],
    template: `
        <nav aria-label="grid pagination">
            <ol>
                <li *ngIf="requiresPagingWithCarets" (click)="setCurrentPage(0)"
                    [ngClass]="{'disabled': currentPage === 0}"
                    class="hoverable">
                    <svg
                        height="20"
                        width="20"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        class="icon-chevron-left-thick"
                        xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path
                                d="M 21.753,28.06 A 2.483,2.483 0 0 0 22.864,26 c 0,-0.523 -0.165,-1.024 -0.476,-1.45 L 22.362,24.515 22.334,24.483 14.941,16 22.334,7.517 22.362,7.485 22.388,7.45 C 22.699,7.024 22.864,6.523 22.864,6 22.864,5.177 22.449,4.407 21.752,3.94 20.617,3.18 19.048,3.439 18.233,4.514 l -8.572,9.974 -0.026,0.03 -0.024,0.032 a 2.445,2.445 0 0 0 0,2.9 l 0.024,0.032 0.026,0.03 8.572,9.974 c 0.816,1.075 2.384,1.335 3.52,0.574 z"
                                fill="#6a6976"
                                id="path145"/>
                            <path
                                d="m 33.664397,28.059782 a 2.483,2.483 0 0 0 1.111,-2.06 c 0,-0.523 -0.165,-1.024 -0.476,-1.45 l -0.026,-0.035 -0.028,-0.032 -7.393,-8.483 7.393,-8.4830002 0.028,-0.032 0.026,-0.035 c 0.311,-0.426 0.476,-0.927 0.476,-1.45 0,-0.823 -0.415,-1.593 -1.112,-2.06 -1.135,-0.76 -2.704,-0.501 -3.519,0.574 l -8.572,9.9740002 -0.026,0.03 -0.024,0.032 a 2.445,2.445 0 0 0 0,2.9 l 0.024,0.032 0.026,0.03 8.572,9.974 c 0.816,1.075 2.384,1.335 3.52,0.574 z"
                                fill="#6a6976"
                                id="path145-8"/>
                        </g>
                    </svg>
                </li>
                <li *ngIf="requiresPagingWithCarets" (click)="prev()" [ngClass]="{'disabled': currentPage === 0}"
                    class="hoverable" tabindex="0">
                    <svg class="icon-chevron-left-thick" height="20" width="20" xmlns="http://www.w3.org/2000/svg"
                         fill="currentColor" viewBox="0 0 32 32">
                        <path
                            d="M21.753 28.06A2.483 2.483 0 0 0 22.864 26c0-.523-.165-1.024-.476-1.45l-.026-.035-.028-.032L14.941 16l7.393-8.483.028-.032.026-.035c.311-.426.476-.927.476-1.45 0-.823-.415-1.593-1.112-2.06-1.135-.76-2.704-.501-3.519.574l-8.572 9.974-.026.03-.024.032a2.445 2.445 0 0 0 0 2.9l.024.032.026.03 8.572 9.974c.816 1.075 2.384 1.335 3.52.574z"
                            fill="#6A6976"></path>
                    </svg>
                </li>
                <li *ngFor="let p of pages" [ngClass]="{'active': p.active}" class="hoverable"
                    (click)="setCurrentPage(p.i)">
                    {{ p.i + 1 }}
                </li>
                <li *ngIf="requiresPagingWithCarets" (click)="next()" [ngClass]="{'disabled': currentPage === maxPage}"
                    class="hoverable"
                    tabindex="0">
                    <svg class="icon-chevron-right-thick" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                         height="20"
                         width="20" viewBox="0 0 32 32">
                        <g>
                            <path
                                d="m13.767 27.486 8.572-9.974.026-.03.023-.032a2.444 2.444 0 0 0 0-2.9l-.023-.032-.026-.03-8.572-9.974c-.815-1.075-2.384-1.335-3.52-.574A2.482 2.482 0 0 0 9.136 6c0 .523.164 1.024.476 1.45l.025.035.028.032L17.058 16l-7.392 8.483-.028.032-.026.034A2.442 2.442 0 0 0 9.136 26c0 .823.415 1.594 1.111 2.06 1.136.762 2.704.502 3.52-.573z"
                                fill="#6A6976"></path>
                        </g>
                    </svg>
                </li>
                <li *ngIf="requiresPagingWithCarets" (click)="setCurrentPage(maxPage)"
                    [ngClass]="{'disabled': currentPage === maxPage}"
                    class="hoverable">
                    <svg
                        height="20"
                        width="20"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        class="icon-chevron-left-thick"
                        xmlns="http://www.w3.org/2000/svg">
                        <g
                            transform="matrix(-1,0,0,1,37.955016,0)">
                            <path
                                d="M 21.753,28.06 A 2.483,2.483 0 0 0 22.864,26 c 0,-0.523 -0.165,-1.024 -0.476,-1.45 L 22.362,24.515 22.334,24.483 14.941,16 22.334,7.517 22.362,7.485 22.388,7.45 C 22.699,7.024 22.864,6.523 22.864,6 22.864,5.177 22.449,4.407 21.752,3.94 20.617,3.18 19.048,3.439 18.233,4.514 l -8.572,9.974 -0.026,0.03 -0.024,0.032 a 2.445,2.445 0 0 0 0,2.9 l 0.024,0.032 0.026,0.03 8.572,9.974 c 0.816,1.075 2.384,1.335 3.52,0.574 z"
                                fill="#6a6976"
                                id="path145"/>
                            <path
                                d="m 33.664397,28.059782 a 2.483,2.483 0 0 0 1.111,-2.06 c 0,-0.523 -0.165,-1.024 -0.476,-1.45 l -0.026,-0.035 -0.028,-0.032 -7.393,-8.483 7.393,-8.4830002 0.028,-0.032 0.026,-0.035 c 0.311,-0.426 0.476,-0.927 0.476,-1.45 0,-0.823 -0.415,-1.593 -1.112,-2.06 -1.135,-0.76 -2.704,-0.501 -3.519,0.574 l -8.572,9.9740002 -0.026,0.03 -0.024,0.032 a 2.445,2.445 0 0 0 0,2.9 l 0.024,0.032 0.026,0.03 8.572,9.974 c 0.816,1.075 2.384,1.335 3.52,0.574 z"
                                fill="#6a6976"
                                id="path145-8"/>
                        </g>
                    </svg>
                </li>
            </ol>
        </nav>
    `
})
export class CcxPagerComponent implements OnInit, OnChanges {
    @Input() maxPage!: number;
    @Input() currentPage!: number;

    @Output() currentPageChanged = new EventEmitter<number>();

    pages: { i: number, active: boolean }[];
    showFirstPage = false;
    showLastPage = false;
    requiresPagingWithCarets = true;


    constructor() {
        this.pages = [];
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const currentPageChanges: SimpleChange | undefined = changes['currentPage'];
        const maxPageChanges: SimpleChange | undefined = changes['maxPage'];
        if (currentPageChanges || maxPageChanges) {
            let currentPage = currentPageChanges?.currentValue || this.currentPage;
            let maxPage: number = maxPageChanges?.currentValue || this.maxPage;
            this.recalculateVisiblePages(maxPage, currentPage);
        }
    }

    recalculateVisiblePages(maxPage: number, currentPage: number) {
        let firstPage = currentPage;
        let lastPage = currentPage;
        let firstPageI = 0;
        let lastPageI = 0;
        this.pages = [];

        this.requiresPagingWithCarets = maxPage > 6;

        const visiblePagesSpread = 4 - (this.requiresPagingWithCarets ? 1 : 0);
        for (let i = 0; i < visiblePagesSpread; i++) {
            if (firstPage > 0) {
                firstPage--;
            } else {
                lastPageI = visiblePagesSpread - i;
                break;
            }
        }
        for (let i = 0; i < visiblePagesSpread; i++) {
            if (lastPage < maxPage) {
                lastPage++;
            } else {
                firstPageI = visiblePagesSpread - i;
                break;
            }
        }
        firstPage = (firstPage - firstPageI) < 0 ? 0 : firstPage - firstPageI;
        lastPage = (lastPage + lastPageI)> maxPage ? maxPage : lastPage + lastPageI;
        for (let i = firstPage; i <= lastPage; i++) {
            this.pages.push({i, active: currentPage === i});
        }

        this.showFirstPage = this.requiresPagingWithCarets && firstPage > 0;
        this.showLastPage = this.requiresPagingWithCarets && lastPage < maxPage;
    }

    setCurrentPage(p: number) {
        if (p !== this.currentPage) {
            this.currentPageChanged.emit(p);
        }
    }

    prev() {
        if (this.currentPage > 0) {
            this.currentPageChanged.emit(this.currentPage - 1);
        }
    }

    next() {
        if (this.currentPage < this.maxPage) {
            this.currentPageChanged.emit(this.currentPage + 1);
        }
    }
}
