import {AfterViewInit, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
    selector: 'ccx-table-button-cell-renderer',
    template: `
        <div class="ccx-table-cell__wrapper">
            <button
                class="ccx-table-cell__button"
                [disabled]="disabled"
                (click)="onClick($event, params)"
            >
                <svg-icon svgClass="" [name]="icon"></svg-icon>
            </button>
        </div>
    `,
    styles: [`
        .ccx-table-cell__wrapper {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
        }

        .ccx-table-cell__button {
            width: 100%;
            height: 100%;
            background-color: transparent;
            color: #ffffff;
            border: none;
            border-radius: 0.25rem;
            font-size: calc(var(--ag-font-size) + 1px);
            padding: 0.25rem;
            cursor: pointer;

            &:disabled {
                background-color: var(--modus-gray-300);
                color: var(--modus-gray-500);
                cursor: not-allowed;
            }
        }
    `]
})
export class CcxTableButtonCellRendererComponent implements ICellRendererAngularComp, AfterViewInit {
    disabled!: boolean;
    icon!: string;
    params: any;
    onClick!: (event: MouseEvent, params: any) => void;

    ngAfterViewInit(): void {

    }

    agInit(params: ICellRendererParams &
        { onClick: (params: any) => void, icon: string, disabled: boolean }): void {
        this.params = params;
        this.disabled = params.disabled;
        this.icon = params.icon;
        this.onClick = params.onClick;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}
