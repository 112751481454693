import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { Component, Inject } from "@angular/core";


export interface ccxConfirmationDialogProps {
    headerText: string;
    subHeaderText: string;
    primaryButtonText: string;
    secondaryButtonText: string;
    submitDisabled: boolean;
    isDelete: boolean;
    actionData?: any;
    onPrimaryButtonClick: (e: any) => void;
    onSecondaryButtonClick: (e: any) => void;
}

@Component({
    selector: 'ccx-confirmation-dialog',
    templateUrl: './ccx-confirmation-dialog.component.html',
    styleUrls: ['./ccx-confirmation-dialog.component.scss'],
})
export class CcxConfirmationDialogComponent {

    headerText!: string;
    subHeaderText!: string;
    primaryButtonText!: string;
    secondaryButtonText!: string;
    submitDisabled!: boolean;
    isDelete!: boolean;
    onPrimaryButtonClick!: (e: any) => void;
    onSecondaryButtonClick!: (e: any) => void;
    actionData!: any;

    constructor(
        private dialogRef: DialogRef<any>,
        @Inject(DIALOG_DATA) private data: ccxConfirmationDialogProps) {
        dialogRef.disableClose = true;

        this.headerText = data.headerText;
        this.subHeaderText = data.subHeaderText;
        this.primaryButtonText = data.primaryButtonText;
        this.secondaryButtonText = data.secondaryButtonText;
        this.submitDisabled = data.submitDisabled;
        this.isDelete = data.isDelete;
        this.onPrimaryButtonClick = data.onPrimaryButtonClick;
        this.onSecondaryButtonClick = data.onSecondaryButtonClick;
        this.actionData = data.actionData;
    }


    handlePrimaryAction(): void {

        this.onPrimaryButtonClick(this.actionData);
        this.dialogRef.close();
    }

    handleSecondaryButton(): void {
        this.onSecondaryButtonClick(this.actionData);
        this.dialogRef.close();
    }

    dialogClose() {
        this.dialogRef.close();
    }


}
