import {Component,  Input,  OnInit} from "@angular/core";
import {
    Observable,
} from "rxjs";
import {Store, select} from '@ngrx/store';
import {TranslocoService} from "@jsverse/transloco";
import {ContentService} from "src/app/@core/services/content.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {State} from "../../../protocol-beef/state/protocol-beef.reducer";
import {beefActions, beefSelectors} from "../../../protocol-beef/state";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OrganizationDetailQuery, OrganizationDetail } from "src/app/@core/generated/operations-core-graphql";

@Component({
    selector: 'ccx-protocol-organization-profile',
    templateUrl: './protocol-organization-profile.component.html',
    styleUrls: ['./protocol-organization-profile.component.scss'],
})



export class ProtocolOrganizationProfileComponent implements OnInit {

    isWorking$!: Observable<boolean>;
    protocolID!: string;
    organizationID!: string;
    @Input()  organization!: OrganizationDetail ;

    constructor(readonly store: Store<State>, private route: ActivatedRoute,
        readonly langSvc: TranslocoService,
        readonly contentSrv: ContentService,
        private router: Router,
        private fb: FormBuilder,
       readonly toastr: ToastrService) {
    }





    ngOnInit(): void {

        this.route.params.subscribe((params) => {
            this.protocolID = params['protocolId'];
            this.organizationID = params['organizationId'];
        });

        // this.isWorking$ = this.store.pipe(
        //     select(beefSelectors.selectIsPending)
        // );

        }

        openEditOrgFrom() {
            this.router.navigate([`/modules/protocols/${this.protocolID}/organizations/${this.organizationID}/editorganization`]);
        }



}
