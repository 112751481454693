import {AfterViewInit, Component, ElementRef, OnDestroy, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {Column, ICellEditorParams, ICellRendererParams} from 'ag-grid-community';
import {actionModalInfo} from 'src/app/Components/data-table/DataTableOptions';
import {CdkScrollable, OverlayRef} from '@angular/cdk/overlay';
import {CcxOverlayService} from '../../../services/overlay.service';

@Component({
    selector: 'ccx-grid-kebab-menu-editor',
    template: `
        <div #menuWrapperRef>
            <ng-template #templateRef>
                <div class="ccx-grid-kebab-menu-renderer__wrapper" (clickedOut)="closeDropdown()">
                    <ul>
                        <ng-container *ngFor="let item of params.actions">
                            <li *ngIf="params.disableIf ; else nodisabled">
                                <button [disabled]="params.disableIf(params.data.status, item.id)"
                                        (click)="onSelectionClicked(item, $any($event))">
                                    {{ item.text }}
                                </button>
                            </li>
                            <ng-template #nodisabled>
                                <li (itemClick)="onSelectionClicked(item, $any($event))">
                                    <button (click)="onSelectionClicked(item, $any($event))">
                                        {{ item.text }}
                                    </button>
                                </li>
                            </ng-template>
                        </ng-container>
                    </ul>
                </div>
            </ng-template>
        </div>

    `,
    styles: [`
        .ccx-grid-kebab-menu-renderer__wrapper {
            background-color: var(--modus-white);
            border: 1px solid var(--modus-gray-6);
            border-radius: 4px;
            box-shadow: 0 0 4px 0 rgba(54, 53, 69, 0.30);
            overflow: hidden;
            overflow-y: auto;
        }

        .ccx-grid-kebab-menu-renderer__wrapper ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            min-width: 150px;
            flex-direction: column;
            align-items: stretch;
        }

        .ccx-grid-kebab-menu-renderer__wrapper ul li {
            border-bottom: 1px solid var(--modus-border-color);
        }

        .ccx-grid-kebab-menu-renderer__wrapper ul li:last-child {
            border-bottom: none;
        }

        .ccx-grid-kebab-menu-renderer__wrapper ul li button {
            background: none;
            border: none;
            color: var(--modus-black);
            cursor: pointer;
            display: flex;
            padding: 7px 12px;
            align-items: center;
            gap: 4px;
            align-self: stretch;
            font-size: 1rem;
            font-weight: 400;
            text-align: left;
            width: 100%;
        }

        .ccx-grid-kebab-menu-renderer__wrapper ul li button:hover {
            background-color: var(--modus-gray-0);
        }

        .ccx-grid-kebab-menu-renderer__wrapper ul li button:disabled {
            color: #B7B9C3;
            cursor: not-allowed;
        }

        .ccx-grid-kebab-menu-renderer__wrapper ul li button:disabled:hover {
            background-color: transparent;
            color: #B7B9C3;
        }
    `]
})
export class KebabMenuCellEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
    @ViewChild('templateRef') templateRef!: TemplateRef<unknown>;
    @ViewChild('menuWrapperRef', {read: ElementRef}) menuWrapperRef!: ElementRef<HTMLDivElement>;
    isInit = false;
    private ref!: OverlayRef;
    private parentComponent!: CdkScrollable;
    private column!: Column<any>;

    constructor(private overlayService: CcxOverlayService,
                private viewContainerRef: ViewContainerRef) {
    }

    ngOnDestroy(): void {
        this.ref.detach();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.isInit = true;
        })
    }

    params!: KebabMenuParams;

    agInit(params: KebabMenuParams): void {
        this.params = params;
        this.parentComponent = params.context.parentElementRef;
        this.column = params.column;
    }

    getValue() {

    }

    isPopup?(): boolean {
        return true;
    }

    getPopupPosition?(): 'over' | 'under' | undefined {
        return 'under';
    }

    isCancelBeforeStart?(): boolean {
        return false;
    }

    isCancelAfterEnd?(): boolean {
        return false;
    }

    focusIn?(): void {

    }

    focusOut?(): void {

    }

    afterGuiAttached?(): void {
        this.ref = this.overlayService.attachOverlay(this.templateRef, this.viewContainerRef, this.menuWrapperRef,
            this.parentComponent,
            '',
            '', [{
                originX: 'end',
                originY: 'top',
                overlayX: 'end',
                overlayY: 'top',
                offsetX: this.column.getActualWidth()
            }]);
    }

    closeDropdown() {
        if (this.isInit) {
            this.params.api.stopEditing();
        }
    }

    onSelectionClicked(item: {
        text: string;
        action: (params: KebabMenuParams, $event: PointerEvent) => void
    }, $event: PointerEvent) {
        item.action(this.params, $event);
        this.params.api.stopEditing();
    }
}

export type KebabMenuParams = ICellEditorParams<any, any, any> & {
    actions: KebabMenuAction[];
    disableIf?: (value: string, id: string) => boolean;
    componentParent: any;
}

export type KebabMenuAction = {
    id: string;
    text: string;
    action: (params: KebabMenuParams, $event: PointerEvent) => void
    actionModalInfo?: actionModalInfo
}
