import {Pipe, PipeTransform} from "@angular/core";
import {TemplateResponse} from "src/app/models/notifications/TemplateResponse";
import {ContentService} from "../services/content.service";


@Pipe({
    name: "translateTemplate"
})
export class TranslateTemplatePipe implements PipeTransform {

    constructor(
        private contService: ContentService
    ) {
    }

    transform(value: TemplateResponse) {
        return this.contService.translateTemplate(value);
    }

}
