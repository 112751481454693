import {Inject, Injectable} from '@angular/core';
import {TranslocoService} from '@jsverse/transloco';
import {WINDOW_REF} from '../shared.module';
import {TemplateResponse} from 'src/app/models/notifications/TemplateResponse';
import {DatePipe, formatDate} from '@angular/common';

@Injectable()
export class ContentService {
    constructor(
        private translocoService: TranslocoService,
        @Inject(WINDOW_REF) protected window: any) {
    }

    loadCurrentLanguage(): void {
        const lang = this.getCurrentLanguage();
        this.saveLanguage(lang);
        this.translocoService.setActiveLang(lang);
    }

    getCurrentLanguage(): string {
        const lang = localStorage.getItem(APP_STORAGE_LANGUAGE_KEY);
        if (!lang) {
            const availableLanguages = this.translocoService.getAvailableLangs().map(e => e.toString());
            const isBrowserLanguageAvailable = availableLanguages.includes(window.navigator.language);
            if (isBrowserLanguageAvailable) {
                return window.navigator.language;
            }
            return this.translocoService.getDefaultLang();
        }
        return lang;
    }

    changeCurrentLanguage(lang: string): void {
        this.saveLanguage(lang);
        this.window.location.reload();
    }

    saveLanguage(lang: string) {
        localStorage.setItem(APP_STORAGE_LANGUAGE_KEY, lang);
    }

    translateTemplate(value: TemplateResponse) {
        let translationValue: string = '';

        if (value.templateId) {
            translationValue = this.translocoService.translate(value.templateId);
            const responseParams: any = value.parameters;
            let formatParams: any;

            if (responseParams) {

                if (Array.isArray(responseParams)) {
                    formatParams = responseParams
                } else {
                    const params = Object.entries(responseParams);
                    formatParams = params.map(([k, v]) => ({key: k ?? "", value: v ?? ""}));
                }

                formatParams.forEach(
                    (param: { [x: string]: string; }) => {
                        translationValue = translationValue.replace(`{{${param['key']}}}`, param['value'])
                    }
                );
            }
        }

        return translationValue;
    }

    localized(value: Date | string, format = 'longDate') {
        return formatDate(value, format, this.getCurrentLanguage()).toString();
    }
}

export const APP_STORAGE_LANGUAGE_KEY = 'lang';
