import {CoefficientEditorState} from './coefficient-editor.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ColDef} from 'ag-grid-community';
import {
    CcxTableCellRendererComponent
} from '../../../../@core/components/ccx-table/ccx-table-cell-renderer.component';
import {formFunctions} from '../../../../@core/functions';
import {
    CcxTableTextEditorCellRenderer
} from '../../../../@core/components/ccx-table/ccx-table-text-editor-cell-renderer.component';


export const selectCoefficientEditState =
    createFeatureSelector<CoefficientEditorState>('coefficientEditor');

export const selectIsWorking = createSelector(selectCoefficientEditState, (state) => state.isWorking);
export const selectIsDialogOpen = createSelector(selectCoefficientEditState, (state) => state.isDialogOpen);
export const selectParameters = createSelector(selectCoefficientEditState, (state) => {
    return {
        ...state,
        protocolId: state.protocolId,
        organizationId: state.organizationId,
    };
});
export const selectOrganizationProtocolParticipantFactors = createSelector(selectCoefficientEditState, (state) => {
    const data = state.data?.getOrganizationProtocolParticipant[0];
    if (!data) {
        return null;
    }
    return data;
});

export const selectCoefficients = createSelector(selectCoefficientEditState, state => state.currentCoefficients);

export const selectCoefficientFactorForm = (keys: {
    name: string,
    control: FormControl
}[]) => createSelector(selectOrganizationProtocolParticipantFactors, (state): FormGroup | null => {
    const factors = state?.factors?.map((factor) => {
        return {
            name: factor.name,
            type: factor.type,
        };
    });
    if (!factors) {
        return null;
    }
    const formGroup = new FormGroup({});
    formGroup.addControl('id', new FormControl<string | null>(null, Validators.required));
    keys.forEach((key) => {
        const abstractControl = formFunctions.cloneAbstractControl(key.control);
        formGroup.addControl(key.name, abstractControl);
    });

    factors.forEach((factor) => {
        switch (factor.type) {
            case 'Numbers':
                formGroup.addControl(factor.name, new FormControl<number | null>(null,
                    [Validators.required,
                        Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]));
                break;
            case 'Percentage (%)':
                formGroup.addControl(factor.name, new FormControl<number | null>(null,
                    [Validators.required, Validators.min(0),
                        Validators.max(1), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]));
                break;
            case 'Alphanumeric':
                formGroup.addControl(factor.name, new FormControl<number | null>(null,
                    Validators.required));
                break;
        }
    });
    return formGroup;
});

export const selectCoefficientColumnDefs = (factorKeyFormControls: {
    name: string,
    control: FormControl
}[], colDefs: ColDef[]) => createSelector(selectOrganizationProtocolParticipantFactors, (state): null | ColDef[] => {
    const factors = state?.factors?.map((factor) => {
        return {
            name: factor.name,
        };
    });
    if (!factors) {
        return null;
    }
    const columns: ColDef[] = factors.map((factor) => {
        return {
            headerName: factor.name,
            field: factor.name,
            sortable: false,
            editable: true,
            cellRenderer: CcxTableCellRendererComponent,
            cellEditor: CcxTableTextEditorCellRenderer
        };
    });
    factorKeyFormControls.forEach((key) => {
        columns.unshift({
            headerName: key.name,
            field: key.name,
            sortable: false,
            editable: true,
            minWidth: 50,
            cellRenderer: CcxTableCellRendererComponent,
            cellEditor: CcxTableTextEditorCellRenderer
        });
    });
    return columns.concat(colDefs);
});
