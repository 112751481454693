import {AfterViewInit, Component, ElementRef, OnDestroy, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {FormControl} from '@angular/forms';
import {Column, GridApi, ICellEditorParams} from 'ag-grid-community';
import {getFormArrayItemFromCellNode} from './ccx-table.component';
import {FlexibleConnectedPositionStrategy, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {CcxOverlayService} from '../../services/overlay.service';

@Component({
    selector: 'ccx-table-edit-cell-renderer',
    template: `
        <div class="ccx-table-cell__wrapper" #cellWrapperRef>
            <ng-template #templateRef>
                <div style="position:relative;width:100%">
                    <input
                        #input
                        [formControl]="formControl"
                        [class.invalid]="formControl.invalid"
                        (keydown.enter)="hitEnterKey($event)"
                        (keydown.escape)="hitEscapeKey($event)"
                        (keydown.tab)="hitTabKey($event, false)"
                        (keydown.shift.tab)="hitTabKey($event, true)"
                        (focusout)="api.stopEditing()"
                        class="ccx-table-cell-edit-input"
                    />
                    <span class="ccx-table-cell-edit-input__invalid__message" *ngIf="formControl.hasError('required')">
                    {{ 'This is a required field' | transloco }}
                </span>
                    <span class="ccx-table-cell-edit-input__invalid__message" *ngIf="formControl.hasError('pattern')">
                    {{ 'Invalid format' | transloco }}
                </span>
                    <span class="ccx-table-cell-edit-input__invalid__message"
                          *ngIf="formControl.hasError('min') || formControl.hasError('max')">
                    {{ 'Value out of range' | transloco }}
                </span>
                </div>
            </ng-template>
        </div>

    `,
    styles: [`
        .ccx-table-cell-edit-input {
            font-size: calc(var(--ag-font-size) + 1px);
            padding-left: calc(var(--ag-cell-horizontal-padding) - 1px);
            box-sizing: border-box;
            width: 100%;
            height: 100%;

            &:focus-visible {
                outline: none;
                border: 2px solid var(--modus-blue);
                box-shadow: 0 0 3px var(--modus-blue);
            }

            &.invalid {
                border: 2px solid var(--modus-alert-danger-border-color, #da212c);
            }
        }

        .ccx-table-cell-edit-input__invalid__message {
            position: absolute;
            left: 0;
            background-color: var(--modus-alert-danger-border-color, #da212c);
            line-height: 1rem;
            color: #ffffff;
            padding: 0.105rem;
            display: block;
            min-height: 1rem;
        }

    `]
})
// TODO: make abstract control for editors with cdk overlays to reduce code duplication
// So far its this and the ccx-select-cell-renderer
export class CcxTableTextEditorCellRenderer implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
    @ViewChild('templateRef') templateRef!: TemplateRef<unknown>;
    @ViewChild('cellWrapperRef', {read: ElementRef}) cellWrapperRef!: ElementRef<HTMLDivElement>;
    @ViewChild('input', { read: ViewContainerRef }) input!: ViewContainerRef;
    private ref!: OverlayRef;
    private positionStrategy!: FlexibleConnectedPositionStrategy;
    private parentComponent: any;
    private column!: Column<any>;
    private rowHeight: number | undefined;
    api!: GridApi<any>;

    constructor(
        private overlayService: CcxOverlayService,
        private viewContainerRef: ViewContainerRef) {}

    formControl!: FormControl;

    agInit(params: ICellEditorParams): void {
        this.api = params.api;
        this.rowHeight = this.api.getRowNode(params.node.rowIndex!.toString())?.rowHeight ?? 0;
        this.column = params.column;
        this.parentComponent = params.context.parentElementRef;

        this.formControl = getFormArrayItemFromCellNode(params.context.componentParent.formArray,
            params.node.rowIndex || 0, params.column.getColId());
    }

    getValue(): any {
        return this.formControl.value;
    }

    afterGuiAttached(): void {
        this.ref = this.overlayService.attachOverlay(this.templateRef, this.viewContainerRef, this.cellWrapperRef,
            this.parentComponent, this.column.getActualWidth(), this.rowHeight);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.input && this.input.element.nativeElement) {
                this.input.element.nativeElement.focus();
            }
        }, 50);
    }

    ngOnDestroy(): void {
        this.ref.detach();
    }

    hitEnterKey($event: any) {
        this.api.stopEditing();
        this.api.tabToNextCell();
    }

    hitEscapeKey($event: any) {
        this.api.stopEditing(true);
        const fs = this.api.getFocusedCell();
        if (fs) {
            this.api.setFocusedCell(fs.rowIndex, fs.column.getColId());
        }
    }


    hitTabKey($event: Event, shiftPressed: boolean) {
        this.api.stopEditing();
        let currentCell = this.api.getFocusedCell();
        let newFocusedCell;

        do {
            if (shiftPressed) {
                this.api.tabToPreviousCell();
            } else {
                this.api.tabToNextCell();
            }

            newFocusedCell = this.api.getFocusedCell();
            currentCell = newFocusedCell;
        } while (currentCell !== newFocusedCell);

        if (currentCell) {
            setTimeout(() => this.api.setFocusedCell(currentCell!.rowIndex, currentCell!.column.getColId()));
        }
    }
}
