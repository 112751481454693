import { Component, EventEmitter, Host, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { combineLatestWith, filter, first, map, Observable, of, takeUntil } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { ColDef, GridOptions, Optional } from 'ag-grid-community';
import { CreateDocumentUploadLinkGQL, GetDocumentChangeLogsQueryVariables, LinkDocumentToOrganizationProjectsInput } from 'src/app/@core/generated/operations-core-graphql';
import { documentManagerActions, documentManagerSelectors } from 'src/app/@core/components/document-manager-shell';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { DataSource } from 'src/app/@core/components/data-source';
import { localizeGrid } from 'src/app/@core/utils';
import { GridColumnConfigurationAg } from 'src/app/@core/components/ccx-grid/ccx-grid.component';
import { ContentService } from 'src/app/@core/services/content.service';
import { TranslocoService } from '@jsverse/transloco';
import { ChangeLogCellEditor } from 'src/app/@core/components/ccx-ag-datagrid/cellEditor/change-log-link-cell-editor.component';
import { ChangeLogRow } from 'src/app/modules/protocol-nerp/models';
import { protocolsSelectors } from 'src/app/modules/protocols/state';
import { DocumentService } from 'src/app/@core/services/document.service';

interface Project {
    id: string;
    name: string;
    checked: boolean;
}

@Component({
    selector: 'change-log-modal',
    templateUrl: './change-log-modal.component.html',
    styleUrls: ['./change-log-modal.component.scss']
})
export class ChangeLogModalComponent implements OnInit, OnDestroy {

    private onDestroy$ = new EventEmitter<void>();
    private store = inject(Store<State>);
    projectsList!: Project[];
    DocumentChangeLogDataSource!: DataSource<ChangeLogRow>;
    defaultProjectSelected!: Project;
    documentId!: string;
    isSubmitDisabled: Observable<boolean> = of(true);

    changeLogGridConfig: GridColumnConfigurationAg[] = [
        {
            field: 'id',
            headerName: 'id',
            hide: true,
            columnSourceId: 'id',
            id: 'id',
            type: 'leftAligned',
            filterType: 'none',
            typeValue: 'string'
        },
        {
            field: 'documentId',
            headerName: 'documentId',
            hide: true,
            columnSourceId: 'documentId',
            id: 'documentId',
            type: 'leftAligned',
            filterType: 'none',
            typeValue: 'string'
        },
        {
            field: 'time',
            headerName: 'Time',
            columnSourceId: 'time',
            id: 'time',
            type: 'leftAligned',
            filterType: 'dateTime',
            typeValue: 'fulldatetime',
        },
        {
            field: 'change',
            headerName: 'Change',
            columnSourceId: 'change',
            id: 'change',
            type: 'leftAligned',
            filterType: 'none',
            cellRenderer: ChangeLogCellEditor,
            typeValue: 'string',
            minWidth: 300,
            wrapText: true,
            autoHeight: true,
            cellStyle: { 'line-height': '1.8em', 'padding': '0.5em 0px', 'word-break': 'break-word' },
        },
        {
            field: 'user',
            headerName: 'User',
            columnSourceId: 'user',
            id: 'user',
            type: 'leftAligned',
            filterType: 'none',
            typeValue: 'string'
        },
        {
            field: 'blobIdentifier',
            headerName: 'blobIdentifier',
            columnSourceId: 'blobIdentifier',
            id: 'blobIdentifier',
            hide: true,
            type: 'leftAligned',
            filterType: 'none',
            typeValue: 'string'
        },
        {
            field: 'fileName',
            headerName: 'fileName',
            columnSourceId: 'fileName',
            id: 'fileName',
            hide: true,
            type: 'leftAligned',
            filterType: 'none',
            typeValue: 'string'
        },

    ]

    constructor(
        private dialogRef: DialogRef<any>,
        @Inject(DIALOG_DATA) private data: any,
        private contSrvc: ContentService, private langSrc: TranslocoService,
        private docSrvc: DocumentService
    ) {
        dialogRef.disableClose = true;
        this.documentId = data.documentId;

    }
    ngOnInit(): void {

        this.store.select(documentManagerSelectors.selectChangeLogModalDialogState).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(open => {
            if (!open) {
                this.dialogRef.close();
            }
        });

        this.DocumentChangeLogDataSource = new DataSource({
            itemsPerPage: 5, gridColumnConfig: this.changeLogGridConfig,
        }, this.langSrc);


        this.DocumentChangeLogDataSource.onDataRequest$
            .subscribe((e) => {
                if (this.documentId) {
                    const input: GetDocumentChangeLogsQueryVariables = {
                        where: e.where,
                        offset: e.offset,
                        limit: e.limit,
                        sortBy: e.sortBy,
                        documentId: this.documentId
                    };

                    this.store.dispatch(
                        documentManagerActions.documentChangeLogsLoad({
                            input: input
                        })
                    )
                }

            });

        this.DocumentChangeLogDataSource.dataResponse$ = this.store.pipe(
            select(documentManagerSelectors.selectChangeLogGrid),
            map((item) => {
                return {
                    ...item,
                    data: localizeGrid(
                        this.changeLogGridConfig,
                        item.data,
                        this.contSrvc
                    ),
                };
            })
        );

        this.store.select(documentManagerSelectors.selectChangeLogList)
            .pipe(takeUntil(this.onDestroy$),
                filter(e => {
                    return e == null;
                })
            ).subscribe(e => {
                this.DocumentChangeLogDataSource.onPageChange(0);
            });


    }

    dialogClose() {
        this.store.dispatch(documentManagerActions.documentChangeLogsDialogOpen({ value: false, documentId: null }));
    }

    ngOnDestroy(): void {
        this.onDestroy$.emit();
        this.onDestroy$.complete();
    }

    onCellClicked($event: { data: ChangeLogRow }) {
        this.docSrvc.downloadDocument($event.data.blobIdentifier ?? '');
    }



    onSelectionChanged(grid: any) { }


}
