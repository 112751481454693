import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
    selector: 'link-cell',
    template: `
        <ng-container>
            <div style="padding-left: 17px;" *ngIf="params.data.isButton === false">{{ linkDescription }}
            </div>
            <ccx-button size="medium" buttonStyle="borderless"
                        (buttonClick)="LinkInvokeParentMethod()">{{ linkDescription }}
            </ccx-button>
        </ng-container>

    `,
    styles: [
        `
            .btn {
                line-height: 0.5;
            }
        `,
    ],
})
export class LinkCellEditor implements ICellRendererAngularComp {
    public params!: ICellRendererParams;
    public linkDescription!: string;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.linkDescription = params.data[params.column?.getColId() || '']
    }

    public LinkInvokeParentMethod() {
        this.params.context.componentParent.linkMethodParent(
            this.params.node);
    }

    refresh(): boolean {
        return false;
    }
}
