import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
    selector: 'link-to-project-cell',
    template: `
        <ng-container>
            <span [className]="'wrap-button'" style="display:block; word-break: break-all;" (click)="LinkInvokeParentMethod()"> 
            {{linkDescription}} <svg-icon *ngIf="showIcon" class="cell-svg-icon" name="clip" [viewBox]="'8 0 20 25'"></svg-icon> 
            </span>
        </ng-container>

    `,
    styles: [
        `
            .wrap-button {
              padding-left: calc(var(--ag-cell-horizontal-padding) - 1px);
              padding-right: calc(var(--ag-cell-horizontal-padding) - 1px);
              display: block;
              word-break: break-all;
              color: #217CBB;
              font-size: 0.875rem;
              font-weight: 600;
              cursor:pointer;
            }

            .cell-svg-icon {
                width: 17px;
                height: 20px;
                position: absolute;
                margin-left: 5px;
            }
        `,
    ],
})
export class LinkToProjectCellEditor implements ICellRendererAngularComp {
    public params!: ICellRendererParams;
    public linkDescription!: string;
    public showIcon: boolean = false;


    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.linkDescription = params.data[params.column?.getColId() || '']
        if (this.params.data.projectsCount > 1) {
            this.showIcon = true
        }
        this.params.column?.getActualWidth();


    }

    public LinkInvokeParentMethod() {
        this.params.context.componentParent.linkMethodParent(
            this.params.node);
    }

    refresh(): boolean {
        return false;
    }
}
