import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
    selector: 'dots-cell',
    template: `
      <ng-container *ngIf="!params.data.hideMenu">
        <modus-dropdown toggle-element-id="dropdownToggle-95c70000-e5bd-cc96-1953-08db99c7b8a3" animate-list=""
                        class="hydrated">
            <div class="row-action" id="dropdownToggle-95c70000-e5bd-cc96-1953-08db99c7b8a3" slot="dropdownToggle">
                <svg width="24" height="24" fill="#6A6976" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="17.5" r="1.5"></circle>
                    <circle cx="12" cy="12" r="1.5"></circle>
                    <circle cx="12" cy="6.5" r="1.5"></circle>
                </svg>
            </div>
        </modus-dropdown>
    </ng-container>
    `,
    styles: [
        `
            .btn {
                line-height: 0.5;
            }
        `,
    ],
})
export class dotsCellRender implements ICellRendererAngularComp {
    public params!: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    public invokeParentMethod() {
        this.params.context.componentParent.removeButtonParent(
            this.params.node.rowIndex);
    }


    refresh(): boolean {
        return false;
    }
}
