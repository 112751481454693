import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Observable, throttle} from 'rxjs';

@Injectable({
    providedIn: 'platform'
})
export class LayoutService {

    private menuState = false;
    private menuExpanded = new BehaviorSubject<boolean>(false);
    private menuExpanded$ = this.menuExpanded.asObservable();

    toggleExpandMenu() {
        this.menuState = !this.menuState;
        this.menuExpanded.next(this.menuState);
    }

    setExpandMenu(state?: boolean) {
        this.menuState = state !== undefined ? state : !this.menuState;
        this.menuExpanded.next(this.menuState);
    }

    onMenuExpanded(): Observable<boolean> {
        return this.menuExpanded$;
    }
}
