import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {FormArray, FormControl} from '@angular/forms';
import {IDropdownSettings} from '../multi-select-dropdown-ext';
import {TranslocoService} from '@jsverse/transloco';
import {Column, GridApi, ICellRendererParams} from 'ag-grid-community';
import {getFormArrayItemFromCellNode} from './ccx-table.component';
import {CcxSelectCellEditorParams} from './ccx-table-cell-renderer.component';
import {
    FlexibleConnectedPositionStrategy,
    OverlayContainer, OverlayRef,
    PositionStrategy,
    ViewportRuler
} from '@angular/cdk/overlay';
import {DOCUMENT_REF} from '../../shared.module';
import {Platform} from '@angular/cdk/platform';
import {CcxOverlayService} from '../../services/overlay.service';
import {ComponentPortal, DomPortal, TemplatePortal} from '@angular/cdk/portal';
import {CcxButtonComponent} from '../ccx-button/ccx-button.component';
import {ModusInputComponent} from '../modus-input/modus-input.component';

@Component({
    selector: 'ccx-select-cell-renderer',
    template: `
        <div class="ccx-table-cell__wrapper" #cellWrapperRef>
            <ng-template #templateRef>
                <select class="ccx-table-cell__select" [formControl]="$any(formControl)"
                        (change)="handleSelectedChange($event)"
                        (focusout)="api.stopEditing()"
                        (keydown.escape)="hitEscapeKey($event)"
                        (keydown.tab)="hitTabKey($event, false)"
                        (keydown.shift.tab)="hitTabKey($event, true)"
                        #input>
                    <option *ngFor="let item of listData" [value]="item.id">{{ item.text }}</option>
                </select>
            </ng-template>
        </div>
    `,
    styles: [`
        .ccx-table-cell__wrapper {
            box-sizing: border-box;
            width: 100%;
            height: var(--ag-row-height, 3rem);

            &.invalid {
                border: 2px solid var(--modus-alert-danger-border-color, #da212c);
            }
        }

        .ccx-table-cell__select {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
            border-radius: 0.25rem;
            font-size: calc(var(--ag-font-size) + 1px);
            padding: 0.25rem;
            cursor: pointer;

            &:disabled {
                background-color: var(--modus-gray-300);
                color: var(--modus-gray-500);
                cursor: not-allowed;
            }
        }`]
})
export class CcxSelectCellRendererComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
    @ViewChild('templateRef') templateRef!: TemplateRef<unknown>;
    @ViewChild('cellWrapperRef', {read: ElementRef}) cellWrapperRef!: ElementRef<HTMLDivElement>;
    @ViewChild('input', { read: ViewContainerRef }) input!: ViewContainerRef;
    formControl: FormControl | null = null;
    value!: any;
    listData: { id: string, text: string }[] = [];
    private ref!: OverlayRef;
    private positionStrategy!: FlexibleConnectedPositionStrategy;
    private parentComponent: any;
    private column!: Column<any>;
    private rowHeight: number | undefined;
    api!: GridApi<any>;
    constructor(private langSvc: TranslocoService, @Inject(DOCUMENT_REF) private document: any,
                private viewportRuler: ViewportRuler,
                private platform: Platform,
                private overlayContainer: OverlayContainer,
                private overlayService: CcxOverlayService,
                private viewContainerRef: ViewContainerRef) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.input && this.input.element.nativeElement) {
                this.input.element.nativeElement.focus();
            }
        }, 50);
    }

    ngOnDestroy(): void {
        this.ref.detach();
    }

    afterGuiAttached(): void {
        this.ref = this.overlayService.attachOverlay(this.templateRef, this.viewContainerRef, this.cellWrapperRef,
            this.parentComponent, this.column.getActualWidth(), this.rowHeight);
    }

    agInit(params: CcxSelectCellEditorParams): void {
        this.api = params.api;
        this.rowHeight = params.api.getRowNode(params.node.rowIndex!.toString())?.rowHeight ?? 0;
        this.column = params.column;
        this.parentComponent = params.context.parentElementRef;


        this.value = params.value;
        this.listData = params.listData;
        const formArray = params.context.componentParent.formArray as FormArray;
        const formArrayHasValueWithinCellRowIndex =
            formArray.length > (params.node.rowIndex || 0);
        if (formArrayHasValueWithinCellRowIndex) {
            this.formControl = getFormArrayItemFromCellNode(params.context.componentParent.formArray,
                params.node.rowIndex || 0, params.column?.getColId() || '');
        }
    }

    focusIn(): void {
    }

    focusOut(): void {
    }

    getPopupPosition(): "over" | "under" | undefined {
        return 'over';
    }

    getValue(): any {
        return this.formControl?.value;
    }

    isCancelAfterEnd(): boolean {
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    handleSelectedChange($event: any) {
        this.api.stopEditing();
        this.api.tabToNextCell();
    }

    hitTabKey($event: Event, shiftPressed: boolean) {
        this.api.stopEditing();
        let currentCell = this.api.getFocusedCell();
        let newFocusedCell;

        do {
            if (shiftPressed) {
                this.api.tabToPreviousCell();
            } else {
                this.api.tabToNextCell();
            }

            newFocusedCell = this.api.getFocusedCell();
            currentCell = newFocusedCell;
        } while (currentCell !== newFocusedCell);

        if (currentCell) {
            setTimeout(() => this.api.setFocusedCell(currentCell!.rowIndex, currentCell!.column.getColId()));
        }
    }

    hitEscapeKey($event: any) {
        this.api.stopEditing(true);
        const fs = this.api.getFocusedCell();
        if (fs) {
            this.api.setFocusedCell(fs.rowIndex, fs.column.getColId());
        }
    }
}
