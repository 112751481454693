import {Injectable, NgZone} from '@angular/core';
import {ProtocolPageSiteMap, SiteMapIcon, SiteMapState} from '../../state/sitenav/sitenav.reducer';
import {select, Store} from '@ngrx/store';
import * as SiteMapActions from '../../state/sitenav/sitenav.actions';
import {environment} from '../../../environments/environment';
import {ContentService} from './content.service';
import {catchError, EMPTY, filter, from, mergeMap, Observable, of, Subscription, toArray} from 'rxjs';
import * as siteMapSelectors from '../../state/sitenav/sitenav.selectors';
import {map, withLatestFrom} from 'rxjs/operators';
import {SvgIconRegistryService} from 'angular-svg-icon';
import {ActivatedRoute, Router} from '@angular/router';
import {ModuleValidation} from '../generated/operations-cms-graphql';
import {CcxMenuItem} from '../components/ccx-side-navigation/ccx-side-navigation.service';

@Injectable()
export class PageNavigationService {
    private navigateToFirstNavWhenLandingOnRootSubscription: Subscription | undefined;

    constructor(private store: Store<SiteMapState>, private contentService: ContentService,
                private iconReg: SvgIconRegistryService, private zone: NgZone,
                private router: Router, private route: ActivatedRoute) {
    }

    private siteMapNavigate(path: string, data: any | null) {
        const pathPrefix = path.startsWith('/') ? '' : '/modules/';
        this.zone.run(() => {
            this.ProtocolMenuOptions(path);
            return this.router.navigate([`${pathPrefix}${path}`], {
                queryParams: data,
            });
        });
    }

    ProtocolMenuOptions(path: string) {
        let protocolView = '';
        switch (path) {
            case '/modules/protocols/organizations':
                protocolView = 'protocol-organization';
                break;
        }

        this.store.dispatch(
            SiteMapActions.ProtocolMenuSelected({
                ProtocolMenuActive: protocolView,
            })
        );
    }

    loadModuleValidation() {
        const lang = this.contentService.getCurrentLanguage();
        this.store.dispatch(
            SiteMapActions.SiteMapLoad({
                contentRootKey: environment.rootNavContentKey,
                lang: lang,
                protocolId: null
            })
        );
        this.store.dispatch(
            SiteMapActions.ModuleValidationLoad({
                moduleKey: environment.rootNavContentKey,
                lang: lang,
            })
        );
        this.store.dispatch(
            SiteMapActions.PageErrorInfoLoad({
                pageKey: [
                    environment.rootNavContentKey,
                    '1fc76fda-2e68-40b5-9935-28aebe53bacc',
                    '3be483e9-5c51-4e86-8b05-d36dbab50b48'
                ],
                lang: lang,
            })
        );
    }

    getSideNavData(): Observable<CcxMenuItem[] | undefined> {
        return this.store.pipe(
            select(siteMapSelectors.selectRootSiteMap),
            withLatestFrom(this.store.select(siteMapSelectors.selectModuleValidation)),
            withLatestFrom(this.store.select(siteMapSelectors.selectedProtocolNameActive)),
            filter(([[siteMap, moduleValidation], protocolName]) => {
                return siteMap.length !== 0 || siteMap[0]?.Node !== undefined;
            }),
            map(([[siteMap, validation], ps]) => {
                const isSiteMapEmpty = siteMap.length === 0 || siteMap[0]?.Node === undefined;
                if (!isSiteMapEmpty) {
                    let siteMapArray = this.buildSiteNavChildren(siteMap, validation);
                    const isSiteMapNotRoot = siteMap[0].Node?.key !== environment.rootNavContentKey;
                    if (siteMapArray && isSiteMapNotRoot) {
                        siteMapArray[0].children?.unshift({
                            onClick: null,
                            title: ps,
                            isLabel: true,
                            show: true,
                            selected: false,
                            sortOrder: -1
                        });
                        siteMapArray[0].children?.unshift({
                            onClick: (e: any) => this.goBackToRootNav(),
                            title: 'Back',
                            icon: 'back-arrow',
                            expanded: false,
                            isLabel: false,
                            show: true,
                            selected: false,
                            sortOrder: -2,
                        });
                    }
                    return siteMapArray;
                }
                return [];
            })
        );
    }

    private buildSiteNavChildren(node: ProtocolPageSiteMap[], validation: (ModuleValidation | null)[]): CcxMenuItem[] | undefined {
        if (!node || node.length == 0) {
            return;
        }
        let response: CcxMenuItem[] = [];
        node.forEach((sm) => {
            let hasAccess = validation[0]?.hasAccess?.find(e => e?.key === sm.Node?.key)
            if (hasAccess === undefined && sm.Node?.key !== environment.rootNavContentKey) {
                return;
            }
            let children: CcxMenuItem[] | undefined;
            let callback: ((e: CcxMenuItem) => void) | null = null;
            if (sm.Children) {
                children = this.buildSiteNavChildren(sm.Children, validation);
            } else {
                callback = (e: CcxMenuItem) =>
                    this.siteMapNavigate(sm.Node?.navigationPath || '', {
                        key: sm?.Node?.key,
                    });
            }
            response.push({
                icon: sm.Node?.menuIcon || '',
                title: sm.Node?.pageTitle || '',
                route: sm.Node?.navigationPath || '',
                onClick: callback,
                children: children,
                expanded: false,
                show: true,
                selected: false,
                sortOrder: sm.Node?.sortOrder ?? 0,
            });
        });
        return response.sort((a, b) =>
            a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0
        );
    }

    goProtocolName(): void {

    }
    goBackToRootNav(): void {
        let lang = this.contentService.getCurrentLanguage();

        this.store.dispatch(
            SiteMapActions.SwitchMapRootLoad({
                newRootKey: environment.rootNavContentKey,
                lang: lang,
                protocolId: null
            })
        );

        this.store.dispatch(
            SiteMapActions.ModuleValidationLoad({
                moduleKey: environment.rootNavContentKey,
                lang: lang,
            })
        );

        this.store.dispatch(
            SiteMapActions.ProtocolMenuSelected({
                ProtocolMenuActive: 'protocol-list',
            })
        );
    }

    navigateToFirstNavWhenLandingOnRoot() {
        if (this.navigateToFirstNavWhenLandingOnRootSubscription
            && !this.navigateToFirstNavWhenLandingOnRootSubscription.closed) {
            return;
        }
        this.navigateToFirstNavWhenLandingOnRootSubscription = this.store.select(siteMapSelectors.selectRootSiteMap).pipe(
            filter(siteMap => {
                const route = this.route.snapshot.firstChild?.url[0]?.path;
                const routeIsNotMainPage = route && route !== 'modules';
                if (routeIsNotMainPage) {
                    return false;
                }
                return siteMap.length > 0;
            })
        )
            .subscribe(siteMap => {
                const isSiteMapEmpty = siteMap.length === 0
                    || siteMap[0]?.Node === undefined
                    || siteMap[0]?.Children?.length === 0;

                if (isSiteMapEmpty) {
                    this.zone.run(() => {
                        this.router.navigate(['/modules/no-content'], {
                            queryParams: {},
                        });
                    });
                    return;
                }

                const firstContentRoute = this.getFirstChildrenOfSiteMap(siteMap);
                if (firstContentRoute && firstContentRoute.Node) {
                    this.zone.run(() => {
                        this.router.navigate([firstContentRoute?.Node?.navigationPath], {
                            queryParams: {key: firstContentRoute?.Node?.key},
                        });
                    });
                }
            });
    }

    getFirstChildrenOfSiteMap(children: ProtocolPageSiteMap[]): ProtocolPageSiteMap {
        const firstChildren = children.reduce((a, v) =>
            v.Node?.sortOrder < a.Node?.sortOrder ? v : a);
        if (firstChildren.Children) {
            return this.getFirstChildrenOfSiteMap(firstChildren.Children);
        }
        return firstChildren;
    }
}
