import {
    Directive,
    forwardRef,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Directive({
    selector: 'modus-text-input',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ModusTextInputDirective),
            multi: true,
        },
    ],
})
export class ModusTextInputDirective implements ControlValueAccessor, OnInit {
    @Input() disabled!: boolean;
    @Input() errorText!: string;
    @Input() formControl!: FormControl;
    @Input() helperText!: string;
    @Input() label!: string;
    @Input() options!: unknown[];
    @Input() optionsDisplayProp!: string;
    @Input() required!: boolean | string;
    @Input() selectValue: unknown;
    @Input() size!: 'medium' | 'large';
    @Input() validText!: string;

    @Output() valueChange = new EventEmitter<string>();

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    private _value!: string;

    get value() {
        return this._value;
    }

    set value(value) {
        if (value !== this._value) {
            this._value = value;
            this.onChange(this._value);
            this.onTouched();
            this.elementRef.nativeElement.value = value;
        }
    }

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        const modusTextInput = this.elementRef.nativeElement as HTMLModusTextInputElement;
        modusTextInput.disabled = this.disabled;
        modusTextInput.errorText = this.errorText;
        modusTextInput.helperText = this.helperText;
        modusTextInput.label = this.label;
        if (typeof this.required === "boolean") {
            modusTextInput.required = this.required;
        }
        modusTextInput.size = this.size;
        modusTextInput.validText = this.validText;
        modusTextInput.value = this.value;

        if (!this.formControl) {
            this.formControl = new FormControl(null);
        }
    }

    @HostListener('valueChange', ['$event.detail'])
    listenForValueChange(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        // if (value) {
        this.value = value;
        // }
    }
}
