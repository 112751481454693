import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ContentService } from 'src/app/@core/services/content.service';
import { CcxCalendarHeader } from './date-time-picker-header/date-time-picker-header.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./date-time-picker.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    encapsulation: ViewEncapsulation.None
})

export class DateTimepickerComponent implements OnInit {
    customHeader = CcxCalendarHeader;

    @Input() form!: FormGroup;
    @Input() controlName!: string;
    @Output() valueChange = new EventEmitter<any>();
    @Output() onReset = new EventEmitter<any>();

    constructor(
        private _adapter: DateAdapter<any>,
        @Inject(MAT_DATE_LOCALE) private _locale: string,
        private contentService: ContentService) {
    }
    ngOnInit(): void {
        this._locale = this.contentService.getCurrentLanguage() || 'en-US';
        this._adapter.setLocale(this._locale);
    }

    onDateChange(event: any) {
        if (event.value) {
            const date = new Date(event.value);
            this.valueChange.emit(date);
        }
    }

}
