import { KebabMenuAction } from "../components/ccx-ag-datagrid/cellEditor/kebab-menu-cell-editor.component";
import { CcxConfirmationDialogComponent, ccxConfirmationDialogProps } from "../components/ccx-confirmation-dialog/ccx-confirmation-dialog.component";


export function openSelectedActionDialog(selectedAction: KebabMenuAction, dialogService: any, actionData: any) {

    if (selectedAction.actionModalInfo) {

        const dialogRef: ccxConfirmationDialogProps = {
            headerText: selectedAction.actionModalInfo.headerText,
            subHeaderText: selectedAction.actionModalInfo.description,
            primaryButtonText: selectedAction.actionModalInfo.primaryButton.text,
            secondaryButtonText: selectedAction.actionModalInfo.secondaryButton.text,
            submitDisabled: false,
            isDelete: true,
            onPrimaryButtonClick: selectedAction.actionModalInfo.primaryButton.onActionClick,
            onSecondaryButtonClick: selectedAction.actionModalInfo.secondaryButton.onActionClick, 
            actionData: actionData
        }

        dialogService.create(CcxConfirmationDialogComponent, dialogRef);
    }
}