import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger, state} from '@angular/animations';

@Component({
    selector: 'app-side-panel',
    templateUrl: './side-panel.component.html',
    styleUrls: ['./side-panel.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('in', style({opacity: 1, transform: 'translateX(0)'})),
            transition('void => *', [
                style({opacity: 1, transform: 'translateX(100%)'}),
                animate(200)
            ]),
            transition('* => void', [
                animate(200, style({opacity: 1, transform: 'translateX(100%)'}))
            ])
        ])
    ]
})
export class SidePanelComponent implements OnInit {

    constructor() {
    }


    ngOnInit(): void {
    }

    @Output() onCloseSidePanel = new EventEmitter();

    @Input() show = false;

}
