import {
    Directive,
    forwardRef,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Directive({
    selector: 'modus-select',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ModusSelectDirective),
            multi: true,
        },
    ],
})
export class ModusSelectDirective implements ControlValueAccessor, OnInit {

    @Input() disabled!: boolean;
    @Input() errorText!: string;
    @Input() formControl!: FormControl;
    @Input() helperText!: string;
    @Input() label!: string;
    @Input() options!: unknown[];
    @Input() optionsDisplayProp!: string;
    @Input() required!: boolean;
    @Input() selectValue: unknown;
    @Input() size!: 'medium' | 'large';
    @Input() validText!: string;

    @Output() valueChange = new EventEmitter<Event>();

    onChange: any = () => {
    };
    onTouched: any = () => {
    };

    private _value!: string;

    get value() {
        return this._value;
    }

    set value(value) {
        if (value !== this._value) {
            this._value = value;
            this.onChange(this._value);
            this.onTouched();
            this.elementRef.nativeElement.value = value;
        }
    }

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        const modusSelect = this.elementRef.nativeElement as HTMLModusSelectElement;
        modusSelect.disabled = this.disabled;
        modusSelect.errorText = this.errorText;
        modusSelect.helperText = this.helperText;
        modusSelect.label = this.label;
        modusSelect.options = this.options;
        modusSelect.optionsDisplayProp = this.optionsDisplayProp;
        modusSelect.required = this.required;
        modusSelect.size = this.size;
        modusSelect.validText = this.validText;
        modusSelect.value = this.selectValue;

        if (!this.formControl) {
            this.formControl = new FormControl(null);
        }
    }

    @HostListener('valueChange', ['$event'])
    listenForValueChange(value: any): void {
        this.value = value.detail;
    }

    registerOnChange(fn: Function): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: string): void {
        // if (value) {
        this.value = value;
        // }
    }
}
