import {
    Component,
    ContentChild,
    ContentChildren,
    Directive, EventEmitter,
    Input,
    OnInit, Output,
    QueryList,
    TemplateRef
} from '@angular/core';
import {DataSource} from '../data-source';

@Directive({
    selector: '[utility-panel]'
})
export class CcxUtilityPanelTemplateMarkerDirective {
    @Input() sectionName: string = 'default';
    @Input() title: string = 'title';

    constructor(public templateRef: TemplateRef<unknown>) {
    }
}

// TODO: get rid of magic strings
@Component({
    selector: 'ccx-utility-panel',
    styleUrls: ['./ccx-utility-panel.component.scss'],
    templateUrl: './ccx-utility-panel.component.html'
})
export class CcxUtilityPanelComponent implements OnInit {
    @ContentChildren(CcxUtilityPanelTemplateMarkerDirective) directives?: QueryList<CcxUtilityPanelTemplateMarkerDirective>
    @Input() selectedSection = '';
    @Output() onClosed = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {

    }

    onClose(): void {
        this.onClosed.emit();
    }

}
