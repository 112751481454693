import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
    selector: 'ccx-button',
    styleUrls: ['./ccx-button.component.scss'],
    template: `
        <button
            [class]="this.className"
            [style]="'width: ' + this.width + '; height: ' + this.height"
            role="button"
            [disabled]="this.disabled"
            (click)="onButtonCllick($event)"
        >
    <span class="label">
    <ng-content></ng-content>
    </span>
        </button>
    `
})
export class CcxButtonComponent implements OnInit {

    @Input() disabled: boolean = false;
    @Input() showCaret: boolean = false;
    @Input() iconOnly: boolean = false;
    @Input() buttonStyle: 'borderless' | 'fill' | 'outline' = 'fill';
    @Input() size: 'small' | 'medium' | 'large'| 'none' = 'medium';
    @Input() width: string = '';
    @Input() height: string = '';

    @Input() color: 'primary' | 'secondary' | 'tertiary' | 'danger' = 'primary';
    @Output() buttonClick = new EventEmitter<CustomEvent<any>>();
    className: string = ''

    classByButtonStyle: Map<string, string> = new Map([
        ['borderless', 'style-borderless'],
        ['fill', 'style-fill'],
        ['outline', 'style-outline'],
    ]);

    classByColor: Map<string, string> = new Map([
        ['danger', 'color-danger'],
        ['primary', 'color-primary'],
        ['secondary', 'color-secondary'],
        ['tertiary', 'color-tertiary'],
        ['dark', 'color-dark'],
    ]);

    classBySize: Map<string, string> = new Map([
        ['small', 'size-small'],
        ['medium', 'size-medium'],
        ['large', 'size-large'],
    ]);

    ngOnInit(): void {
        this.className = `${this.classBySize.get(this.size)}  ${this.classByColor.get(this.color)}   ${this.classByButtonStyle.get(
            this.buttonStyle
        )} ${this.iconOnly ? 'icon-only' : ''} ${this.showCaret ? 'has-caret' : ''}`;
    }

    onButtonCllick($event: any) {
        this.buttonClick.emit($event);
    }
}
