import {
    ChangeDetectorRef,
    Component,
    EventEmitter, Host,
    Input,
    OnChanges,
    OnDestroy,
    OnInit, Optional,
    Output,
    SimpleChanges, ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { debounceTime, Observable, Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataSource } from '../data-source';
import { TranslocoService } from '@jsverse/transloco';
import { BinaryFilter } from '../../generated/operations-core-graphql';
import { ModusDataTableCellLink } from '../../../modules/protocols/models';
import {
    ModusDataTableCellBadge,
    ModusDataTableDisplayOptions,
    ModusDataTableSort
} from '@trimble-oss/modus-web-components';
import {
    ColDef,
    FirstDataRenderedEvent,
    GridOptions,
    GridReadyEvent, IRowNode,
    ModelUpdatedEvent,
    RowClassRules,
    SelectionChangedEvent,
    SortChangedEvent
} from 'ag-grid-community';
import {CdkScrollable} from '@angular/cdk/overlay';

@Component({
    selector: 'ccx-grid',
    styleUrls: ['./ccx-grid.component.scss'],
    templateUrl: './ccx-grid.component.html',
    // encapsulation: ViewEncapsulation.ShadowDom
})
export class CcxGridComponent implements OnInit, OnChanges, OnDestroy {


    @Input() dataSource!: DataSource<any>;
    @Input() rowData!: any[] | [];
    @Input() pinnedTopRowData: any[] | undefined;
    @Input() getRowStyle: ((params: any) => any | undefined) | undefined;
    @Input() rowClassRules: RowClassRules<any> | undefined;
    @Input() suppressMovableColumns: boolean = false;
    @Input() suppressCellFocus: boolean = false;
    @Input() headerHeight: number | undefined;
    @Input() gridOptions: GridOptions<any> | undefined;
    @Input() overlayLoadingTemplate!: string;
    @Input() overlayNoRowsTemplate!: string;

    currentPage: number;
    maxPage: number;
    defaultColDef: ColDef = {
        cellDataType: false,
        wrapHeaderText: true,
        sortable: true,
        flex: 2,
        suppressSizeToFit: true,
    };


    private onDestroy$ = new Subject<void>();
    public context: any;

    @Output() onLoad = new EventEmitter<any>();
    @Output() onRefresh = new EventEmitter<PageStateInput>();

    @Output() onCellClick = new EventEmitter<IRowNode<any>>();
    @Output() selectionChanged = new EventEmitter<SelectionChangedEvent<any>>();
    @Output() onGridReady = new EventEmitter<GridReadyEvent>();
    @Output() modelUpdated = new EventEmitter<ModelUpdatedEvent<any>>();
    @Output() firstDataRendered = new EventEmitter<FirstDataRenderedEvent<any>>();


    constructor(private fb: FormBuilder, private trlocoServ: TranslocoService,
                @Optional() @Host() scrollContainer: CdkScrollable) {
        this.currentPage = 0;
        this.maxPage = 0;
    }

    gridReady($event: GridReadyEvent<GridReadyEvent>) {

        this.onGridReady.emit($event);
    }

    onCellClicked(e: IRowNode<any>) {
        // e.stopPropagation();
        this.onCellClick.emit(e);
    }

    @Input() options!: CcxGridOptions;

    @Input() rowSelection!: 'single' | 'multiple';

    recalculatePages(rowCount: number) {
        if (this.dataSource && this.dataSource.itemsPerPage) {

            const pagesFraction = rowCount / this.dataSource.itemsPerPage;
            const isItemsPerPageExactOrNoRows = pagesFraction % 1 === 0 && rowCount > 0;


            this.maxPage = isItemsPerPageExactOrNoRows
                ? pagesFraction - 1
                : Math.floor(pagesFraction) || 0;

            this.currentPage = this.maxPage < this.currentPage
                ? this.maxPage
                : this.currentPage;
        }
    }

    filtersFormGroup!: FormGroup;

    ngOnInit(): void {

        const defaultGridOptions: GridOptions<any> = {
            animateRows: false
        };

        this.gridOptions = {
            ...defaultGridOptions,
            ...this.gridOptions
        };

        this.onLoad.emit({});

        this.dataSource.dataResponse$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(e =>
            this.recalculatePages(e.count));

    }


    onGridSort(e: SortChangedEvent) {
        const colDef = e.columnApi.getColumnState().find(col => Boolean(col.sort))
        let colSort = { columnId: colDef?.colId || '', direction: colDef?.sort ?? "asc" };
        this.dataSource.onSortData(colSort.columnId, colSort.direction);

    }

    onPageChange($event: any) {
        const page: number = $event.detail;
        this.currentPage = page;
        this.dataSource.onPageChange(page);
    }

    onCurrentPageChange($event: number) {
        const page: number = $event;
        this.currentPage = page;
        this.dataSource.onPageChange(page);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const dataSourceChanges = changes['dataSource'];
        if (dataSourceChanges && dataSourceChanges.currentValue?.gridColumnConfigurations) {
            const columnChanges = dataSourceChanges.currentValue.gridColumnConfigurations;
            if (dataSourceChanges?.firstChange) {
                const columns: GridColumnConfiguration[] = columnChanges;
                const group: any = {};
                columns.forEach(c => {
                    const fc = new FormControl(c.defaultValue);
                    group[c.columnSourceId] = fc;
                });
                this.filtersFormGroup = this.fb.group(group);
            }
        }
    }

    onFirstDataRendered(event: FirstDataRenderedEvent<any>) {
        this.firstDataRendered.emit(event);
    }



    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

}

export interface ITableColumn {
    align?: 'left' | 'right';
    display: string;
    id?: string;
    readonly?: boolean;
    width?: string;
}

type TCell =
    | number
    | string
    | boolean
    | ModusDataTableCellBadge
    | ModusDataTableCellLink;
type TRow = {
    [key: string]: TCell;
} & {
    _id?: string;
    _selected?: boolean;
};

export interface PageStateInput {
    sortColumnId?: string;
    sortDirection?: string;
    currentPage?: number;
    panelFilters?: BinaryFilter;
}

export type GridColumnConfiguration = ITableColumn & {
    columnSourceId: string;
    filterType: 'none' | 'text' | 'select' | 'check' | 'number' | 'decimal' | 'Guid' | 'dateTime';
    defaultValue?: any | undefined;
    Type: 'string' | 'datetime';
}

export type GridColumnConfigurationAg = ColDef & {
    id: string;
    columnSourceId: string;
    filterType: 'none' | 'text' | 'select' | 'check' | 'number' | 'decimal' | 'Guid' | 'dateTime';
    defaultValue?: any | undefined;
    typeValue: 'none' | 'string' | 'datetime' | 'fulldatetime';
    cellSelected?: true | false;
    placeHolder?: string;
}

export type CcxGridOptions = {
    display: ModusDataTableDisplayOptions;
}

export type GridDataModel<TData> = {
    data: TData[],
    count: number;
}
