import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-spinner',
    styleUrls: ['./wait-spinner.component.scss'],
    templateUrl: './wait-spinner.component.html'
})
export class WaitSpinnerComponent {
    @Input() isWorking: boolean | null = false;

    constructor() {
    }
}
