import {Component, OnInit, Input} from '@angular/core';
import {dummyCounter} from 'src/app/modules/dashboard/dummyCounter';
import {Router} from '@angular/router';

@Component({
    selector: 'app-project-status',
    templateUrl: './project-status.component.html',
    styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent implements OnInit {

    projectsData = [
        {
            id: 0,
            status: 'Just added',
            projectName: 'Project 1',
            type: 'primary'
        },
        {
            id: 1,
            status: 'Data Entry',
            projectName: 'Project 2',
            type: 'success'
        },
        {
            id: 2,
            status: 'Review requested',
            projectName: 'Project 3',
            type: 'secondary'
        },
        {
            id: 3,
            status: 'Overdue',
            projectName: 'Project 4',
            type: 'danger'
        },
        {
            id: 4,
            status: '2 days to deadline',
            projectName: 'Project 5',
            type: 'warning'
        }

    ]

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

}
