import {Component, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {ContentService} from 'src/app/@core/services/content.service';
import {
    AccessRequestByDate,
    AddOrganizationtoUserInput,
    DataAccessRequestByDate,
    DeniedUser
} from '../../@core/generated/operations-core-graphql';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {State} from './state/accessRequest.reducer';
import {Store, select} from '@ngrx/store';
import {accessRequestActions, accessRequestSelectors} from './state';
import {TranslocoService} from '@jsverse/transloco';

@Component({
    selector: 'app-access-request',
    templateUrl: './access-request.component.html',
    styleUrls: ['./access-request.component.scss']
})
export class AccessRequestComponent implements OnInit {

    organizationForm: FormGroup;
    selected = true;
    token: any = null;
    request_email!: string;
    showModal = false;
    organization!: string;
    organizationlist$!: Observable<{ display: string }[] | null>;
    headerTextModal!: string;
    subHeaderTextModal!: string;
    usersRequestingByDate$!: Observable<AccessRequestByDate[] | null>;

    constructor(
        private toastr: ToastrService,
        private contSrvc: ContentService,
        private fb: FormBuilder,
        private store: Store<State>,
        private trlocoServ: TranslocoService
    ) {

        this.organizationForm = fb.group({
            organization: ['', Validators.required]
        });
    }

    isWorking$!: Observable<boolean>;


    ngOnInit(): void {


        this.store.dispatch(accessRequestActions.orgListLoad({
            variables: null
        }));

        this.organizationlist$ = this.store.pipe(
            select(accessRequestSelectors.selectOrgList)
        );

        this.isWorking$ = this.store.pipe(
            select(accessRequestSelectors.selectIsworkinig)
        )

        this.usersRequestingByDate$ = this.store.pipe(
            select(accessRequestSelectors.selecAccessRequestList)
        );


    }

    @Input() datainfo!: AccessRequestByDate[];

    acceptButtonAction(data: DataAccessRequestByDate): void {
        this.showModal = true;
        this.headerTextModal = this.trlocoServ.translate('Assign Organization') + ' : ' + data.userEmail;
        this.request_email = data.userEmail;

    }


    accessRequestCancel(): void {

    }

    valueChange(e: any): void {
        this.organization = e.id;
    }

    rejectButtonAction(userEmail: string): void {

        const input: DeniedUser = {
            request_email: userEmail
        }
        this.store.dispatch(accessRequestActions.AccessRequestRejectOrgUserLoad({input}));
    }

    onCloseModal() {
        this.showModal = false;
        this.organizationForm.reset();
    }

    onAsignOrganizationtoUser() {
        this.showModal = false;
        this.organizationForm.reset();

        const input: AddOrganizationtoUserInput = {
            email_requested: [this.request_email],
            organizationId: this.organization
        }
        this.store.dispatch(accessRequestActions.AccessRequestAddOrgUserLoad({input}));

        this.store.dispatch(accessRequestActions.AccessRequestByDateLoad({input: null}));


    }
}
