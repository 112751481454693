import {Component, EventEmitter, Input, Output} from "@angular/core";
import {
    ModusDataTableDisplayOptions,
    ModusDataTableRowAction, ModusDataTableSortEvent, TColumn,
    TRow
} from '@trimble-oss/modus-web-components';


interface actionModalInfo {
    headerText: string,
    description: string,
    primaryButton: actionModalButtons
    secondaryButton: actionModalButtons
}

interface actionModalButtons {
    text: string,
    onActionClick: (input: TRow) => any
}


@Component({
    selector: 'ccx-data-table',
    template: `
        <modus-data-table
            [displayOptions]="displayOptions"
            [data]="data"
            [columns]="columns"
            (sort)="sort"
            
            (selection)="onSelectionChange($event)"
            (rowActionClick)="rowActionClick($event)"
            (cellLinkClick)="cellLinkClick($event)"
        >
        </modus-data-table>
    `
})
export class CcxDataTable {

     selectedAction!: any;
    SelectedRow!: TRow;

    @Input() displayOptions!: ModusDataTableDisplayOptions;
    @Input() data!: TRow[];
    @Input() columns!: TColumn[] | string[];
    @Input() sort!: CustomEvent<ModusDataTableSortEvent>;
     @Input() rowActions!: any[];

     @Output() rowActionClicked = new EventEmitter<{ action: any, rowSelected: TRow }>();
    @Output() onCellLinkClick = new EventEmitter<CustomEvent>();
    @Output() onSelectionChanged = new EventEmitter<CustomEvent<string[]>>();

    rowActionClick(e: Event): void {

        this.SelectedRow = this.data.find(r => r._id == (e as CustomEvent).detail.rowId) ?? {}

         this.selectedAction = this.rowActions.find(a => a._id == (e as CustomEvent).detail.actionId)
             ?? {
                 _id: "",
                 display: {text: "",},
                 actionInfo: {}
             }

         this.rowActionClicked.emit({action: this.selectedAction, rowSelected: this.SelectedRow});
    }

    cellLinkClick(e: Event): void {
        this.onCellLinkClick.emit(e as CustomEvent);
    }

    onSelectionChange($event: Event) {
        this.onSelectionChanged.emit($event as CustomEvent<string[]>);
    }
}
