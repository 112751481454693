import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CcxTokenResponse, TIdTokenResult} from './tIdTokenResult';

@Injectable()
export class TIdTokenService {
    private token = new BehaviorSubject<TIdTokenResult | null>(null);
    public token$ = this.token.asObservable();

    constructor() {
        this.token.next(this.getCcxToken());
    }

    storeToken(tokenResponse: CcxTokenResponse): Observable<TIdTokenResult> {

        let tokenResult = new TIdTokenResult(true, tokenResponse, []);
        localStorage.setItem('ccxToken', JSON.stringify(tokenResult));
        this.token.next(tokenResult);
        return of(tokenResult);
    }

    removeToken() {
        localStorage.removeItem('ccxToken');
        localStorage.removeItem("powerBiEmbedToken");
        this.token.next(null);
    }

    refreshToken() {
        localStorage.removeItem('ccxToken');
        localStorage.removeItem("powerBiEmbedToken");
    }

    getCcxToken(): TIdTokenResult | null {
        const tokenJson = localStorage.getItem('ccxToken') ?? '';
        try {
            const token = JSON.parse(tokenJson);
            return new TIdTokenResult(token.success, token.tokens, token.errors);
        } catch (e) {
        }
        return null;
    }
}
