import {TIdTokenResult} from '../../@auth/services/tIdTokenResult';
import {EMPTY, Observable, ObservableInput, of, switchMap} from 'rxjs';
import {TIdService} from '../../@auth/services/tId.service';

export function IsAuthenticatedOperator(o: (value: TIdTokenResult | null, index: number) => ObservableInput<any>,
                                        tidService: TIdService, ifNotAuthenticated: AuthenticationOperation): Observable<any> {
    return tidService.isAuthenticated().pipe(
        switchMap(authenticated => {
            if (authenticated) {
                return tidService.tokenStream().pipe(
                    switchMap(o)
                )
            } else {
                switch (ifNotAuthenticated) {
                    case AuthenticationOperation.ReturnEmptyObject:
                        return of({});
                    case AuthenticationOperation.AuthenticateIfAnnonymous:
                        tidService.authenticate(null);
                        return EMPTY;
                    case AuthenticationOperation.CancelRequest:
                        return EMPTY;
                }

            }
        })
    )
}

export enum AuthenticationOperation {
    AuthenticateIfAnnonymous,
    CancelRequest,
    ReturnEmptyObject
}
