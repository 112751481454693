import jwt_decode from "jwt-decode";

export class TIdTokenResult {

    public readonly idToken: any;
    public readonly roles: string[] | string = [];
    public readonly ccxAccess: string | string = "";

    constructor(
        readonly success: boolean,
        readonly tokens: CcxTokenResponse | null = null,
        readonly errors: string[]) {

        this.errors = [...errors];
        this.idToken = this.parseJwtTokens(tokens?.token ?? '');
        this.roles = this.idToken.roles;
        this.ccxAccess = this.idToken.ccxAccess;
    }

    isSuccess(): boolean {
        return this.success;
    }

    parseJwtTokens(token: string): any {
        const decodedHeader = jwt_decode(token);
        return decodedHeader;
    }

    getIdPayload(): any {
        return this.idToken;
    }

    isActive(): boolean {
        return this.idToken && this.idToken.ccxAccess && this.idToken.ccxAccess === "active";
    }

    isValid(): boolean {
        if (!this.idToken || !this.idToken.hasOwnProperty('exp')) {
            return false;
        }
        const expiredElapsedSeconds: number = Number(this.idToken.exp) - (Date.now() / 1000);
        return expiredElapsedSeconds > 0;
    }
}

export interface CcxTokenResponse {
    token: string;
    tid_TokenId: string;
    refresh_Token: string;
}
