<div class="ccx-grid-filters" *ngIf="filtersFormGroup">
    <form [formGroup]="filtersFormGroup" (keydown.enter)="applyFilter()" autocomplete="off">
        <div class="form-controls">

            <div class="filter-grp" *ngFor="let c of dataSource.gridColumnConfigurations; index as i">
                <ng-container *ngIf="c.headerName">
                    <div class="filter-item" *ngIf="c.filterType === 'text'">
                        <label [for]="c.columnSourceId">{{ c.headerName }}</label>
                        <input class="form-control" type="text" [name]="c.headerName"
                            [formControlName]="c.columnSourceId" />
                    </div>
                    <div class="filter-item" *ngIf="c.filterType === 'Guid'">
                        <label [for]="c.columnSourceId">{{ c.headerName }}</label>
                        <input class="form-control" type="text" [name]="c.headerName"
                            [formControlName]="c.columnSourceId" />
                        <div *ngIf="filtersFormGroup.get(c.id)?.errors as fc" class="error-message">
                            <div *ngIf="fc['pattern']">
                                {{ 'Invalid Guid' | transloco }}
                            </div>
                        </div>
                    </div>
                    <div class="filter-item" *ngIf="c.filterType === 'check'">
                        <label [for]="c.columnSourceId">{{ c.headerName }}</label>
                        <input class="form-control" type="checkbox" [name]="c.headerName" (change)="applyFilter()"
                            [formControlName]="c.columnSourceId" />
                    </div>
                    <div class="filter-item" *ngIf="c.filterType === 'number'">
                        <label [for]="c.columnSourceId">{{ c.headerName }}</label>
                        <input class="form-control" type="text" [name]="c.headerName"
                            [formControlName]="c.columnSourceId" />
                        <div *ngIf="filtersFormGroup.get(c.id)?.errors as fc" class="error-message">
                            <div *ngIf="fc['pattern']">
                                {{ 'Invalid number' | transloco }}
                            </div>
                        </div>
                    </div>
                    <div class="filter-item" *ngIf="c.filterType === 'decimal'">
                        <label [for]="c.columnSourceId">{{ c.headerName }}</label>
                        <input class="form-control" type="text" [name]="c.headerName"
                            [formControlName]="c.columnSourceId" />
                        <div *ngIf="filtersFormGroup.get(c.id)?.errors as fc" class="error-message">
                            <div *ngIf="fc['pattern']">
                                {{ 'Invalid number' | transloco }}
                            </div>
                        </div>

                    </div>
                    <div class="filter-item" *ngIf="c.filterType === 'select'">
                        <ccx-multiselect-dropdown (onSelect)="handleSelectionChanged(c.columnSourceId, $event)"
                                                  [formControlName]="c.columnSourceId"
                            (onDeSelect)="handleSelectionChanged(c.columnSourceId, $event)" class="select-input"
                            label="{{c.headerName | transloco}}"
                            [settings]="getDropDownSettingsFromColumnConfiguration(c)" [placeholder]="c.placeHolder!"
                            [data]="selectedItems?.get(c.columnSourceId) ?? []">
                        </ccx-multiselect-dropdown>
                    </div>
                    <div class="filter-item" *ngIf="c.filterType === 'dateTime' ">
                        <label [for]="c.columnSourceId">{{ c.headerName }}</label>
                        <app-date-time-picker [form]="filtersFormGroup" [controlName]="c.columnSourceId"
                            (valueChange)="applyFilter()">
                        </app-date-time-picker>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>
<div class="ccx-grid-actions">
    <ccx-button style="width: -webkit-fill-available;" height="3rem" width="inherit"  buttonStyle="outline" color="secondary" size="large" (click)="resetFilter()">
        {{ 'Reset Filters'| transloco }}
    </ccx-button>
</div>
