import {
    GridColumnConfiguration,
    GridColumnConfigurationAg,
    GridDataModel,
    PageStateInput
} from './ccx-grid/ccx-grid.component';
import {
    BinaryFilter,
    InputMaybe,
    Scalars
} from '../generated/operations-core-graphql';
import {Observable, Subject} from 'rxjs';
import {TranslocoService} from '@jsverse/transloco';

export class DataSource<TData> {

    data: GridDataModel<TData> | undefined;


    pageState: PageStateInput | undefined;
    _prevState: PageStateInput | undefined;
    panelFilters: BinaryFilter | undefined;
    searchByFilters: BinaryFilter | undefined;
    itemsPerPage: number;
    gridColumnConfigurations: GridColumnConfigurationAg[];

    private onDataRequest = new Subject<any>();
    public onDataRequest$ = this.onDataRequest.asObservable();
    dataResponse$!: Observable<GridDataModel<TData>>;

    constructor(conf: {
        itemsPerPage?: number | undefined,
        gridColumnConfig: GridColumnConfigurationAg[]
    }, langSvc: TranslocoService) {
        this.itemsPerPage = conf.itemsPerPage || 12;
        this.gridColumnConfigurations = conf.gridColumnConfig;
        this._prevState = {};
        const columnSort = this.gridColumnConfigurations
            .find(e => e.sort);
        if (columnSort) {
            this._prevState = {
                sortColumnId: columnSort?.id,
                sortDirection: columnSort?.sort || 'asc',
                currentPage: 0
            };
        }
        this.gridColumnConfigurations = this.gridColumnConfigurations.map(c => {
            return {
                ...c,
                headerName: langSvc.translate(c.headerName || '')
            }
        });
    }

    gridSearchBy(filters: BinaryFilter) {
        this.searchByFilters = filters;
        const pageStatus: PageStateInput = {};
        this.pageState = pageStatus;
        this.gridRefresh(pageStatus)
    }

    gridRefresh(pageState: PageStateInput) {
        this.panelFilters = pageState.panelFilters || this.panelFilters;
        const searchBoxFilters = this.searchByFilters;
        let filters: BinaryFilter = {exprGrp: []};
        if (this.panelFilters) {
            filters.exprGrp = [...filters.exprGrp || [], ...this.panelFilters.exprGrp || []];
        }
        if (searchBoxFilters) {
            filters.exprGrp = [...filters.exprGrp || [], ...searchBoxFilters.exprGrp || []];
        }
        this.pageState = {
            ...pageState,
        };
        let input: {
            where?: InputMaybe<BinaryFilter>, sortBy?: InputMaybe<Scalars['String']>,
            offset?: InputMaybe<Scalars['Int']>, limit?: InputMaybe<Scalars['Int']>
        } = {};
        if (this.pageState.sortColumnId) {
            const sortByColumnName = this.gridColumnConfigurations
                    .find(e => e.id === pageState?.sortColumnId)?.columnSourceId
                || pageState.sortColumnId;
            input.sortBy = `${sortByColumnName} ${pageState.sortDirection}`;
        }
        input.limit = this.itemsPerPage;
        input.offset = ((this.pageState.currentPage || 0)) * this.itemsPerPage;
        input.where = filters;

        this.onDataRequest.next(input);
    }

    onSortData(sortColumn: string, sortDirection: string) {
        const pState: PageStateInput = {
            ...this._prevState,
            sortColumnId: sortColumn,
            sortDirection: sortDirection
        };
        this._prevState = pState;
        this.gridRefresh(pState);
    }

    onPageChange(page: number) {
        const pState: PageStateInput = {
            ...this._prevState,
            currentPage: page
        };
        this._prevState = pState;
        this.gridRefresh(pState);
    }

    onFiltersReset() {
        const pState: PageStateInput = {
            ...this._prevState,
            currentPage: 0,
            panelFilters: {}
        };
        this.searchByFilters = undefined;
        this._prevState = pState;
        this.gridRefresh(pState);
    }

    onApplyFilter(binaryFilter: BinaryFilter) {
        const pState: PageStateInput = {
            ...this._prevState,
            currentPage: 0,
            panelFilters: binaryFilter
        };
        this._prevState = pState;
        this.gridRefresh(pState);
    }
}
